<template lang="pug">
.history-exch-wrapper

  .history-exchange-table
    table
     thead
       tr
         th(v-for="item in compoundExchHistoryHead" :key="item") {{ item }}

     tbody
       tr(v-for="item in bids")
         td {{ item.id }}
         td.date {{ timeFormatter(item.created_ts, lang) }}
         td.type {{ type(item) }}
         td.amount {{ displayCoins(item.amount, currencies[item.currency_code]) }}
         td.rate {{ displayCoins(item.rate, currencies[item.tools_currency_code]) }}
         td.status {{ $t(`exchange.history.${item.status}Status`) }}
         td {{ gave(item) }}
         td {{ received(item) }}
         td {{ displayCoins(item.rest_amount, currencies[item.currency_code]) }}

     .empty(v-if="false") {{ $t('exchange.history.emptyBodyTitle') }}

  .btn-wrapper(@click.stop="loadData")
      button {{ $t('exchange.history.loadDataTitle') }}
</template>

<script>
import History from './History.vue';
const TabSW = () => import('@/components/serviceComponents/TabSW');

export default {
  extends: History,
  components: {
    TabSW,
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/exchange/history/history_sw.scss";
</style>