<template lang="pug">
  .toggle-switcher
    .toggle-wrapper
        input.toggle(type="checkbox" :id="name" v-model="value" @change="handleToggle")
    label {{ title }}
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    name: {
      type: String,
      required: true,
      default: ''
    },
  },
  data: () =>({
    isClicked: false,
    value: false,
  }),
  methods: {
    handleToggle(e) {
      this.value = e.target.checked;
      this.$emit('emitToggle', {'name': this.name, 'value': this.value});
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/serviceComponents/toggle_sw.scss';
</style>
