<template lang="pug">
  svg#fb(width='48' height='47' viewBox='0 0 48 47' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFillFirst' d='M42.5137 0H5.51367C2.75225 0 0.513672 2.23858 0.513672 5V42C0.513672 44.7614 2.75225 47 5.51367 47H42.5137C45.2751 47 47.5137 44.7614 47.5137 42V5C47.5137 2.23858 45.2751 0 42.5137 0Z')
    path(:fill='changeFillSecond' d='M35.7193 10.34H12.3078C11.5049 10.34 10.8535 10.9909 10.8535 11.7943V35.2057C10.8535 36.0086 11.5049 36.6594 12.3078 36.6594H19.9093C22.6707 36.6594 24.9093 34.4208 24.9093 31.6594V28.1828C24.9093 27.2362 24.1418 26.4687 23.1952 26.4687C22.2485 26.4687 21.481 25.7013 21.481 24.7546V24.2078C21.481 23.2611 22.2485 22.4936 23.1952 22.4936C24.1418 22.4936 24.9093 21.7262 24.9093 20.7795V19.5686C24.9093 16.168 26.9883 14.3157 30.0185 14.3157C30.4812 14.3149 30.9438 14.325 31.4058 14.3459C32.3628 14.3893 33.0873 15.1988 33.0873 16.1569C33.0873 17.1891 32.2505 18.0259 31.2182 18.0259H30.9806C29.3217 18.0259 29.0121 18.8111 29.0121 19.9612V20.652C29.0121 21.6691 29.8366 22.4936 30.8538 22.4936C31.9647 22.4936 32.8228 23.4698 32.6802 24.5716L32.6402 24.8808C32.5227 25.789 31.7494 26.4687 30.8337 26.4687C29.8277 26.4687 29.0121 27.2843 29.0121 28.2903V31.6594C29.0121 34.4208 31.2507 36.6594 34.0121 36.6594H35.7193C36.5221 36.6594 37.1735 36.0086 37.1735 35.2057V11.7943C37.1735 10.9914 36.5221 10.34 35.7193 10.34Z')
</template>

<script>
export default {
  name: 'Facebook',
  props: {
    isClicked: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    changeFillFirst() {
      if (this.isClicked) {
        return '#ffffff';
      } else {
        return '#4A9eba';
      }
    },
    changeFillSecond() {
      if (this.isClicked) {
        return '#0c93dd';
      } else {
        return '#ffffff';
      }
    },
  },
};
</script>