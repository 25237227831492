<template lang="pug">
  svg#topMiniGames.icon(width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M11.0959 5.76442C10.9932 2.74918 12.9452 0.706604 12.9452 0.706604C6.78082 0.706604 5.13699 4.30543 4.82877 5.18082H4.72603C2.15753 5.18082 0 7.2234 0 9.65504C0 12.0867 2.15753 14.1293 4.72603 14.1293C4.93151 14.1293 5.23973 14.1293 5.44521 14.032C4.62329 13.0593 4.10959 11.8922 4.10959 10.5304C4.10959 8.48785 5.34247 6.63981 7.19178 5.76442C6.78082 5.56988 6.36986 5.37535 5.85616 5.27809C7.29452 1.67926 11.0959 1.67926 11.0959 1.67926C10.1712 3.13824 10.0685 4.88903 10.0685 5.66715C9.96575 5.66715 9.86301 5.66715 9.76027 5.66715C6.88356 5.56988 4.52055 7.80699 4.52055 10.5304C4.52055 13.2539 6.88356 15.491 9.76027 15.491C12.637 15.491 15 13.2539 15 10.5304C15 8.29332 13.3562 6.34801 11.0959 5.76442ZM1.74658 8.39059C1.74658 8.77965 1.84932 9.55778 1.33562 9.46051C0.616438 9.26598 0.924658 8.29332 0.924658 8.29332C1.13014 7.61246 1.64384 7.32067 1.64384 7.32067C2.26027 7.02887 1.84932 8.00153 1.74658 8.39059ZM6.57534 9.75231C6.4726 10.3359 6.67808 11.5031 5.9589 11.3086C4.93151 11.0168 5.44521 9.55778 5.44521 9.55778C5.75342 8.48785 6.4726 8.19606 6.4726 8.19606C7.29452 7.90426 6.67808 9.26598 6.57534 9.75231Z')
    path(:fill='changeFill' d='M5.03402 3.52712C5.65046 2.16541 7.49977 1.19275 7.49977 1.19275C3.39018 -0.752563 0.616211 3.0408 0.616211 3.0408C2.77374 4.30525 5.03402 3.52712 5.03402 3.52712Z')
</template>

<script>
export default {
  name: 'TopMiniGamesSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#94b8af',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>