<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'Search',
  data: () => ({
    searchString: '',
    isClickedCross: false,
    timerId: null,
  }),
  computed: {
    ...mapGetters({
      isAuthUser: 'auth/isAuthUser',
      fastSearchResults: 'games/getFastSearchResults',
      languageCode: 'user/getLanguageCode',

    }),
    getTitleForMoney() {
      return this.isAuthUser ? this.$t('games.realButton') : this.$t('games.demoButton');
    },
  },
  methods: {
    ...mapActions({
      fetchGameListFast: 'games/fetchGameListFast',
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_FAST_SEARCH_RESULTS: 'games/SET_FAST_SEARCH_RESULTS',
    }),
    /**
     * обработчик onInput с эмуляцией задержки debounce в 500мс
     */
    keypressSearch() {
      if (this.timerId != null) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }

      this.timerId = setTimeout(() => {
          this.clickListener();
      }, 500);
    },
    async clickListener() {
      if (this.searchString !== null && this.searchString !== '') {
        await this.fetchGameListFast(this.searchString.toLowerCase());
      }
    },
    async clearSearchStr() {
      this.isClickedCross = true;
      this.searchString = '';
      this.SET_FAST_SEARCH_RESULTS({});
      clearTimeout(this.timerId);
      this.timerId = null;
      setTimeout(() => {
        this.isClickedCross = false;
      }, 500);
    },
    /**
     * слушатель клика по обертке поиска для сужения-расширения
     */
    toggleSearchField() {
      let searchNode = document.querySelector('.search-wrapper__field');

      if (searchNode != null) {
        searchNode.classList.add('extended');
      }
    },
    /**
     * обработчик кнопки в выпадающем меню
     */
    async handleSearchBtn(data) {
      this.SET_OPEN_PAGE('personalGame');
      this.searchString = '';
      this.SET_FAST_SEARCH_RESULTS({});
      await this.$router.push(`/game?id=${data.id}`);
      window.scrollTo(0, 0);
    },

    getTitle(result) {
      let s = 'title_' + this.languageCode;
      return (typeof result[s] === 'string' && result[s] !== '' ? result[s] : result['title_en']);
    },

    getIconUrl(game) {
      let out = 'game-icons/no_cover.svg';
      if (game.image_file) {
        try {
          out = 'game-icons/' + game.image_file;
        } catch (e) {
          console.log('Cover not find: ', game.image_file);
        }
      } else if (game.aggregator && game.aggregator === 'softswiss') {
        return game.aggregator_params.game_icon.url + game.provider + '/' + (game.provider_id.split(':')[1]) + '.' + game.aggregator_params.game_icon.format;
      }
      return out;
    },
  },
  watch: {
    searchString(newSearchString) {
      if (newSearchString === '') {
        this.clearSearchStr();
      }
    },
    fastSearchResults: {
      deep: true,
      handler(newResults) {
        if (Object.keys(newResults).length === 0) {
          this.searchString = '';
        }
      }
    },
  },
};
</script>

<style lang="scss">
</style>