<template lang="pug">
  .gl
    .gl-head(v-if="showFilters" :class="{'open-filters': isFiltersShow}")
      .gl-row-1
        .gl-col-2
          input.gl-search-input(:ref="'gl-search-input'"
            v-model="localSearchString" :placeholder="$t('games.searchPlaceholder')"
            @keypress="keypressSearch")
          .gl-btn-search(@click="searchListener")
          .head__close(v-show="localSearchString != null && localSearchString !== ''" @click="clearSearchStr")
            CrossShadowOff(:isClickedCross="isClickedCross" :width="16" :height="16")

        .gl-col-1
          span.gl-btn-favorites(:class="{'gl-btn_selected': isFavoritesFilter}" @click="favListener()") {{ $t('games.favoritesButton') }}
            img(:src="require('@/assets/images/games/sunduk-white/icon_favorites.svg')")
          span.gl-btn-new(:class="{'gl-btn_selected': isNewFilter}" @click="newListener()") {{ $t('games.newButton') }}
            img(:src="require('@/assets/images/games/sunduk-white/icon_new.svg')")

    .gl-row-2(v-if="showFilters")
      .providers
        .gl-filters-wrap
          div(v-for='(provider,index) in getProviders' :key="provider.id + (isFilter(provider)?'on':'off')" @click="selectProvider(provider)" class='filter-btn')
            label(v-show="isAllProvidersShow || index < 10" :class="{'gl-label_selected': isFilter(provider)}") {{ getTitleProvider(provider) }}

        .gl-btns-wrap
          .buttons
            label.gl-btn-reset(@click="allProviderListener()") {{ $t('games.providersButton') }}
              .chevron

    .gl-wrap.icons(:ref="'games_wrap'")
      .gl-card(v-for='(icon,index) in list' :key='icon.id' @click.stop="selectGame(icon.id, false)"
              :class="{'gl-card_selected': (icon.id === gameSelectId)}"  )
        .gl-btn-demo( @click.stop="selectGame(icon.id, true)") {{ $t('games.demoButton') }}
        span.gl-btn-new.gl-btn-favorites(v-b-tooltip.hover.top="$t('games.newGameLabel')")
        span.gl-btn-favorites( :class="{'gl-btn_selected': icon.is_favorite}" @click.stop="setFavorite(icon.id, !icon.is_favorite)" v-b-tooltip.hover :title="addToFavoritesText(icon.is_favorite)")
        .gl-card_image(:data-titleIcon="icon.title" @mouseover="on_over" @mouseout="on_out")
          object.icon(:data-game_id="'game_'+icon.id" :ref="'game_'+icon.id" :data="url_icon(icon)" @load="on_svg_load" )
        .gl-card_title {{ getTitle(icon)}}
    .gl-wrap(v-show='isBtnShow')
      .gl-btn-more.d-flex( @click="on_click_view_more" ) {{ page === 'Main' ? $t('games.allButton') : $t('games.moreButton') }}
</template>

<script>

const CrossShadowOff = () => import('@/components/serviceComponents/svg/CrossShadowOff');

import GamesList from './GamesList';

export default {
  extends: GamesList,
  components: {
    CrossShadowOff,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/games/games-list_sw.scss";
</style>