<template lang="pug">
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LanguageSelector',
  props: {
    dafaultSpan: {
      type: String,
      default: ''
    },
    viewSelector: {
      type: String,
      default: 'security'
    },
    isProfile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOtherLangActive: false
  }),
  computed: {
    ...mapGetters({
      isAuthUser: 'auth/isAuthUser',
      currentLanguage: 'user/getLanguage',
      userProfile: 'user/getProfile',
    }),
    localeCurrent() {
      return this.getLangArray.find(lang => lang.iso == this.$i18n.locale).iso;
    },
    classMergerLS() {
      return {
        'show': this.isOtherLangActive,
        'top-m': this.viewSelector == 'topMenu',
        'mobile-menu': this.viewSelector == 'mobileMenu',
        'profile': this.isProfile
      };
    },
    toggleFlagImg() {
      return this.viewSelector === 'topMenu' || this.viewSelector === 'mobileMenu';
    },
    localeTitle() {
      if (this.viewSelector == 'security') {
        return this.$t('languages.' + this.localeCurrent);
      }
      else if (this.viewSelector == 'topMenu' || this.viewSelector == 'mobileMenu') {
        return this.localeCurrent;
      }

      return null;
    },
    getUserId() {
      return this.userProfile != null && this.userProfile.hasOwnProperty('id') ? this.userProfile.id : null;
    },
    /**
     * данные для отображения в языковой плашке
     */
    getLangArray() {
      return [
        {
          iso: 'ru',
          i18: 'ru-RU',
          title1: 'ru',
          title: 'Русский',
          logo: require('@/assets/images/languages/ru.svg'),
          active: true,
        },
        {
          iso: 'en',
          i18: 'en-EN',
          title1: 'en',
          title: 'English',
          logo: require('@/assets/images/languages/en.svg'),
          active: true,
        },
        // {
        //   iso: 'fr',
        //   i18: 'fr-FR',
        //   title1: 'fr',
        //   title: 'Français',
        //   logo: require('@/assets/images/languages/fr.svg'),
        //   active: false,
        // },
        // {
        //   iso: 'es',
        //   i18: 'es-ES',
        //   title1: 'es',
        //   title: 'Español',
        //   logo: require('@/assets/images/languages/sp.svg'),
        //   active: false,
        // },
        // {
        //   iso: 'de',
        //   i18: 'de-DE',
        //   title1: 'de',
        //   title: 'Deutsche',
        //   logo: require('@/assets/images/languages/ge.svg'),
        //   active: false,
        // },
      ];
    },
  },
  methods: {
    ...mapActions({
      selectLang: 'personal/doSelectLanguage',
    }),
    ...mapMutations({
      SET_USER_LANGUAGE: 'user/SET_LANGUAGE',
    }),
    changeLocale(locale, callSelectLang='true') {
      this.$i18n.locale = locale;
      this.isOtherLangActive = false;
      const newLang = this.transformLang(locale);
      this.SET_USER_LANGUAGE(newLang);

      if (this.isAuthUser) {
        if (callSelectLang) {
          this.selectLang(newLang).catch(error => console.log(error));
        }
      }
    },
    toggleOtherLang() {
      this.isOtherLangActive = !this.isOtherLangActive;
    },
    /**
     * метод для приведения локали к виду loc-LOC
     */
    transformLang(lang) {
      const prettieredLang = lang.replace(/\"/g, '');
      const tranfLang = `${prettieredLang}-${prettieredLang.toUpperCase()}`;
      return tranfLang;
    },
    outsideClick() {
      if (this.isOtherLangActive) {
        this.isOtherLangActive = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>