import {clientApi} from '@/utils/clientApi';

export const doSaveSettings = async ({ commit, dispatch }, payload) => {
    let response;

    try {
        response = await clientApi('post', 'profile/save-settings', payload);
        const { error, message } = response.data;

        if (error == 0) {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'info',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        }
        else {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        }
        dispatch('user/fetchBalance', null, {root: true});

    } catch (error) {
        console.log(error);
    }

    //clear
    response = null;
};
