export const SET_OPEN_PAGE = (state, headerLink) => {
    const {pages} = state;

    for (const key in pages) {
        if (key === headerLink) {
            pages[key] = true;
        } else {
            pages[key] = false;
        }
    }
};
