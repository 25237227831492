const convertCurrencyToSymbol = (currency, safely = true) => {
    const convertedObj = {
        'RUB': String.fromCharCode(0x20BD),
        'EUR': String.fromCharCode(0x20AC),
        'USD': String.fromCharCode(0x0024)
    };
    const currency_upper = (currency===null?'':currency).toString().toUpperCase();
    return (
        typeof convertedObj[currency_upper] === 'string' ? 
            convertedObj[currency_upper] : 
            (safely===true?currency:null)
    );
};

export default convertCurrencyToSymbol;