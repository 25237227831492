<template lang="pug">
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'Payments',
  computed: {
    ...mapGetters({
      paymentsType: 'personal/getPaymentsType',
    }),
    isDepositActive() {
      return this.paymentsType === 'deposit';
    },
  },
  methods: {
    ...mapActions({
      fetchPayments: 'personal/fetchPayments',
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_PAYMENTS_TYPE: 'personal/SET_PAYMENTS_TYPE',
    }),

    /**
     * метод-слушатль для событий от компонента Button
     * @param data
     */
    async handleButton(data) {
      if (data) {
        switch (data) {
          case 'fetchDeposit': {
            this.SET_PAYMENTS_TYPE('deposit');
            await this.fetchPayments();
            return;
          }
          case 'fetchWithdrawal': {
            this.SET_PAYMENTS_TYPE('withdrawal');
            await this.fetchPayments();
            return;
          }
        }
      }
    },
    /**
     * метод-обработчик на кресте закрытия формы для @1024
     */
    edCrossHandler() {
      this.$router.push('/').catch({});
      window.scrollTo(0, 0);
      this.SET_OPEN_PAGE('root');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>