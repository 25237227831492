<template lang="pug">
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'app',
  computed: {
    ...mapGetters({
      userLanguage: 'user/getLanguage',
      defaultLanguage: 'user/getDefaultLanguage',
      isSigninVisible: 'auth/isSigninVisible',
      isSignupVisible: 'auth/isSignupVisible',
      forgotToken: 'auth/getForgotToken',
      modalMessage: 'main/getModalMessage',
      pages: 'main/getPages',
      isAuthUser: 'auth/isAuthUser',
      favicon: 'main/getFavicon',
      brand: 'main/getBrand',
      openSection: 'personal/getOpenSection',
      getNotifications: 'personal/getNotifications',
      list: 'games/getList',
      userProfile: 'user/getProfile'
    }),
    isBanner1Visible() {
      //@TODO thinking of using one variable to switch Banner1
      // return !this.pages['personal'] && !this.pages['games']
      //     && !this.pages['personalGame'] && !this.pages['termsOfUse']
      //     && !this.pages['privacyPolicy'] && !this.pages['bonusPolicy']
      //     && !this.pages['paymentMethods'] ;
      return this.brand.code == 'sunduk_w' && this.pages.root;
    },
    getNotifyMessages() {
      return Object.values(this.getNotifications);
    },
    showNotifications() {
      return window.innerWidth > 1279 && this.getNotifyMessages.length > 0;
    },
    getUserId() {
      return this.userProfile != null && this.userProfile.hasOwnProperty('id') ? this.userProfile.id : null;
    },
  },
  sockets: {
    connect() {
      if (this.getUserId) {
        this.$socket.client.emit('setUser', {id: this.getUserId});
      }

      // this.$socket.client.emit('getMyBids', {offset:0,limit:10});
      // this.$socket.client.on('loadMyBids', async (data) => {
      //   const bidById = data.reduce((map, bid) => {
      //     map[bid.id] = bid;
      //     return map;
      //   }, {});
      //   console.log('loadMyBids',bidById);
      //   //TODO this.ADD_MY_BIDS(bidById);
      // });

      this.$socket.client.on('updateAccounts', async (data) => {
        for (let account of data) {
          this.SET_CURRENCY(account.currency);
          this.UPDATE_FUNDS_ACCOUNTS(account);
        }
      });

      this.$socket.client.on('loadBids', async (data) => {
        const bidById = data.reduce((map, bid) => {
          map[bid.id] = bid;
          return map;
        }, {});
        this.SET_OPEN_BIDS(bidById);
      });
      this.$socket.client.on('updateBid', async (data) => {
        this.UPDATE_BIDS(data);
      });
      this.$socket.client.on('deleteBid', async (data) => {
        this.DELETE_BIDS(data);
      });

      this.$socket.client.on('loadPaymentMethods', async (data) => {
        const methodById = data.reduce((map, bid) => {
          map[bid.id] = bid;
          return map;
        }, {});
        this.SET_PAYMENT_METHODS(methodById);
      });
      this.$socket.client.on('updatePaymentMethod', async (data) => {
        this.UPDATE_PAYMENT_METHOD(data);
      });
      this.$socket.client.on('deletePaymentMethod', async (data) => {
        this.DELETE_PAYMENT_METHOD(data);
      });

      this.$socket.client.on('loadMyBids', async (data) => {
        data.forEach((bid) => {
          this.UPDATE_MY_BIDS(bid);
        });
      });

      this.$socket.client.on('updateMyBid', async (data) => {
        this.UPDATE_MY_BIDS(data);
      });

    },
    notify(data) {
      this.ADD_SOCKET_NOTIFICATION(data);
      data.status = 'received';
      this.$socket.client.emit('updateNoticeStatus', data);
    },
  },
  methods: {
    ...mapActions({
      fetchUserProfile: 'user/fetchProfile',
      fetchInitSetting: 'main/fetchInitSetting',
      doLogout: 'auth/doLogout',
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_LANGUAGE: 'user/SET_LANGUAGE',
      SET_OPEN_SECTION: 'personal/SET_OPEN_SECTION',
      ADD_SOCKET_NOTIFICATION: 'personal/ADD_SOCKET_NOTIFICATION',
      SET_FAST_SEARCH_RESULTS: 'games/SET_FAST_SEARCH_RESULTS',
      UPDATE_FUNDS_ACCOUNTS: 'fund/UPDATE_FUNDS_ACCOUNTS',
      SET_CURRENCY: 'fund/SET_CURRENCY',
      SET_OPEN_BIDS: 'fund/SET_OPEN_BIDS',
      SET_MY_BIDS: 'fund/SET_MY_BIDS',
      DELETE_BIDS: 'fund/DELETE_BIDS',
      UPDATE_BIDS: 'fund/UPDATE_BIDS',
      UPDATE_MY_BIDS: 'fund/UPDATE_MY_BIDS',
      SET_PAYMENT_METHODS: 'fund/SET_PAYMENT_METHODS',
      DELETE_PAYMENT_METHOD: 'fund/DELETE_PAYMENT_METHOD',
      UPDATE_PAYMENT_METHOD: 'fund/UPDATE_PAYMENT_METHOD',
      SET_FUNDS_ACCOUNTS: 'fund/SET_FUNDS_ACCOUNTS',
      SET_FORGOT_TOKEN: 'auth/SET_FORGOT_TOKEN',
      SET_MODAL_MESSAGE: 'main/SET_MODAL_MESSAGE',
    }),
  },
  async created() {
    window.onload = function () {
      document.getElementById('spinner').classList.add('loaded');
    };

    this.fetchUserProfile();

    let fetchUserProfile = async () => {
      if (this.isAuthUser) {
        await this.fetchUserProfile();
      }
      setTimeout(fetchUserProfile, 30000);
    };
    fetchUserProfile();

    this.SET_OPEN_PAGE('root');

    if (!this.brand.is_change_lang) {
      this.SET_LANGUAGE(this.defaultLanguage);
    }
    const language = this.userLanguage;
    this.$i18n.locale = language.split('-')[0];

    document.querySelector('title').innerText = this.brand.name;
    document.querySelector('link[rel="icon"]').removeAttribute('href');

    let alert = /alert=([^&]+)/.exec(document.location.href);
    if (alert) {
      alert = alert[1];
      alert = alert ? alert : null;
      if (alert &&
          (
              alert.indexOf('deposit_success') === 0
              || alert.indexOf('deposit_fail') === 0
              || alert.indexOf('email_confirmed') === 0
          )
      ) {
        let msg;
        if (alert.indexOf('deposit_success') === 0) {
          msg = 'profile.payments.successDepositMessage';
        } else if (alert.indexOf('deposit_fail') === 0) {
          msg = 'profile.payments.failDepositMessage';
        } else if (alert.indexOf('email_confirmed') === 0) {
          msg = 'auth.emailConfirmed';
        }
        this.SET_MODAL_MESSAGE({
          level: 'info',
          icon: 'i',
          buttons: 'ok',
          text: this.$t(msg),
          accept_action_func: async () => {
            let url = document.location.href;
            url = url.replace(/alert=([^&]+)/, '');
            if (url[url.length - 1] === '?') {
              url = url.substring(0, url.length - 2);
            }
            window.location.href = url;
          },
        });
      }
    }
  },
  watch: {
    userLanguage(newVal) {
      if (newVal != null) {
        this.$i18n.locale = newVal.split('-')[0];
      }
    },
    async isAuthUser(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.$socket.client.emit('setUser', {id: this.getUserId});
        this.$socket.client.emit('getMyBids', {offset: 0, limit: 20});
      }
      if (!newValue && oldValue) {
        this.$socket.client.emit('unsetUser');
        await this.doLogout();
        this.SET_FUNDS_ACCOUNTS({});
      }
    },
    '$route': {
      deep: true,
      async handler(to, from) {
        if (from.name === 'personal' && to.name !== 'personal' && this.openSection !== '') {
          this.SET_OPEN_SECTION('');
        }
        /**
         * если роутинг меняется при клике на стрелку назад в браузере,
         * автоматически корректируем запси в объекте pages
         */
        else if (from.name === 'root' && to.name !== 'root') {
          this.SET_OPEN_PAGE(to.name);
        } else if (from.name == null && to.name != null) {
          this.SET_OPEN_PAGE(to.name);
        }
      }
    },
    isSigninVisible(newVal) {
      if (newVal) {
        const body = document.querySelector('body');

        if (body != null) {
          body.classList.add('no-scroll');
        }
      }
      if (newVal === false) {
        const body = document.querySelector('body');

        if (body != null) {
          if (body.classList.contains('no-scroll') && !this.isSignupVisible) {
            body.classList.remove('no-scroll');
          }
        }
      }
    },
    isSignupVisible(newVal) {
      if (newVal) {
        const body = document.querySelector('body');

        if (body != null) {
          body.classList.add('no-scroll');
        }
      }
      if (newVal === false) {
        const body = document.querySelector('body');

        if (body != null) {
          if (body.classList.contains('no-scroll') && !this.isSigninVisible) {
            body.classList.remove('no-scroll');
          }
        }
      }
    },
    favicon(newVal, oldValue) {
      if (newVal != null && newVal !== oldValue) {
        document.querySelector('link[rel="icon"]').setAttribute('href', `../favicons/${this.favicon}`);
      }
    },
  },
  mounted() {
    const app = document.querySelector('#app');

    if (app) {
      app.addEventListener('click', (e) => {
        const targetClass = e.target.className;
        const searchBlock = document.querySelector('.search-wrapper__field');

        if (searchBlock && searchBlock.classList.contains('extended')) {
          if (targetClass !== 'search__field' && targetClass !== 'btn-search' && targetClass !== 'result-content-wrapper'
              && targetClass !== 'result__title' && targetClass !== 'result-image') {
            searchBlock.classList.remove('extended');
            this.SET_FAST_SEARCH_RESULTS({});
          }
        }
      });
    }

    const forgotToken = new URL(location.href).searchParams.get('forgotToken');
    if (forgotToken) {
      this.SET_FORGOT_TOKEN(forgotToken);
      this.$router.push('/');
    }
  },
  async beforeMount() {
    let fetchInit = async () => {
      await this.fetchInitSetting();
      setTimeout(fetchInit, 900000); //15min
    };
    await fetchInit();
  }
};
</script>

<style lang="scss" scoped>
</style>