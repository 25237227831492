<template lang="pug">
  svg#menuCloseSVG(width='44', height='44', viewBox='0 0 44 44', fill='none', xmlns='http://www.w3.org/2000/svg')
    rect(x='31.5808', y='9.58066', width='4.01455', height='31.1128', rx='2.00728', transform='rotate(45 31.5808 9.58066)', fill='#004973')
    rect(x='34.4194', y='31.5809', width='4.01455', height='31.1128', rx='2.00728', transform='rotate(135 34.4194 31.5809)', fill='#004973')
</template>

<script>
export default {
  name: 'MenuCloseSvg'
};
</script>