<template lang="pug">
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {currencyFormat} from '@/utils/moneyUtils';

export default {
  name: 'LeaderboardBlock',
  props: {
    pageSizeTop: {
      type: Number,
      default: 3,
      required: false,
    },
    pageSizeWin: {
      type: Number,
      default: 6,
      required: false,
    }
  },
  data: () => ({
    moneyCurency: convertCurrencyToSymbol,
    moneyFormat: currencyFormat,
    //Флаг отображения списка
    showList: false,
    isMobile: false,
  }),

  computed: {
    ...mapGetters({
      userLanguageCode: 'user/getLanguageCode',
      topMonthWins: 'games/getTopMonthWins',
      topTodayWins: 'games/getTopTodayWins',
      topMonthGames: 'games/getTopMonthGames',
      userBalance: 'user/getBalance',
      isAuthUser: 'auth/isAuthUser',
      languageCode: 'user/getLanguageCode',
    })
  },

  methods: {
    ...mapActions(
        {
          fetchTopMonthWins: 'games/fetchTopMonthWins',
          fetchTopTodayWins: 'games/fetchTopTodayWins',
          fetchTopMonthGames: 'games/fetchTopMonthGames',
          fetchGameUrl: 'games/fetchGameUrl',
        }
    ),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE'
    }),

    async fetch() {
      const params = {
        limit: 10
      };
      if (this.isAuthUser) {
        if (this.userBalance.currency) {
          params.currency = this.userBalance.currency;
        }
      }
      params.limit = this.pageSizeWin;
      await this.fetchTopMonthWins(params);
      await this.fetchTopTodayWins(params);
      params.limit = this.pageSizeTop;
      await this.fetchTopMonthGames(params);
    },

    async doRunGame(gameId) {
      if (!this.isAuthUser) {
        this.SET_SIGNIN_VISIBLE(true);
        return;
      }

      if (!this.isMobile) {
        this.doOpenGamePage(gameId);
      } else {
        const url = await this.fetchGameUrl({gameId, demo: false, homeButton: true, mobile: this.isMobile});

        if (url.length > 0) {
          window.location.href = url;
          if (this.$metrika) {
            this.$metrika.reachGoal('gameRun');
          }
        }
      }
    },

    async doOpenGamePage(gameId) {
      if (!this.isMobile) {
        this.SET_OPEN_PAGE('personalGame');
        await this.$router.push(`/game?id=${gameId}`);
        window.scrollTo(0, 0);
      }
    },

    url_icon(game) {
      let out = 'game-icons/no_cover.svg';
      if (game.image_file) {
        try {
          out = 'game-icons/' + game.image_file;
        } catch (e) {
          console.log('Cover not find: ', game.image_file);
        }
      } else if (game.aggregator && game.aggregator === 'softswiss') {
        return game.aggregator_params.game_icon.url + game.provider + '/' + (game.provider_id.split(':')[1]) + '.' + game.aggregator_params.game_icon.format;
      }
      return out;
    },

    async on_svg_load(event) {
      const svg_event = event.target.contentDocument.documentElement;
      if (
          svg_event &&
          svg_event.pauseAnimations &&
          svg_event.animationsPaused() === false
      )
        svg_event.pauseAnimations();
    },

    getTitle(top) {
      let s = 'game_' + this.languageCode;
      return (typeof top[s] === 'string' && top[s] !== '' ? top[s] : top['game_en']);
    },

    resize() {
      if (window.innerWidth >= 1280) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    }
  },

  async mounted() {
    await this.fetch();
    this.resize();
    window.addEventListener('orientationchange', this.resize, false);
    window.addEventListener('resize', this.resize, false);
  }
};
</script>

<style lang="scss">
</style>