<script>
import TextInput from './TextInput';

export default {
  extends: TextInput,
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/serviceComponents/text-input_exw.scss";
</style>