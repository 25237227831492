<template lang="pug">
  div.gamesPage
    GamesList(page="Games")
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

const GamesList = () => import('@/components/games/GamesListSW');

export default {
  name: 'Games',
  components: {
    GamesList
  },
  computed: {
    ...mapGetters({
    })
  },
  methods: {
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    })
  },
  mounted() {
    this.SET_OPEN_PAGE('games');
  }
};
</script>
<style lang="scss">
  .gamesPage { 
    width: 100%;
  }
</style>