import * as actions from './actions/';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    brand: {
        name: '',
        is_change_lang: true,
        code: ''
    },
    favicon: '',
    logoId: 1,
    pages: {
        games: false,
        personal: false,
        root: false,
        personalGame: false,
        termsOfUse: false,
        privacyPolicy: false,
        bonusPolicy: false,
        paymentMethods: false,
        paymentPolicy: false,
        responsibleGaming: false,
        contactUs: false,
        kycPolicy: false,
        funds: false,
        history: false
    },
    /**
     * text - текст для отображения, string | [];
     * title - текст для отображения заголовка, string;
     * level - info/warning/error определяет цвет шапки зеленая/синяя/красная, string;
     * icon - отображение иконки i для info/! для warning/x для error, string;
     * buttons - ok/yes_no/ok_cancel определяет, какие кнопки отображать в модалке;
     * accept_button_text - если указан, меняет стандартный текст кнопки ok или yes;
     * reject_button_text - если указан, меняет стандартный текст кнопки no или cancel;
     * accept_action_func - ссылка на функцию при нажатии кнопки ok или yes;
     * reject_action_func - ссылка на функцию при нажатии кнопки no или cancel;
     * input_fields - массив объектов с полями ввода, [{}];
     * input_fields_title - заголовок формы в модальном окне, string;
     */
    modalMessage: null,
    contentPageType: null,
};

export const main = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
