<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {isValidPhoneNumber} from 'libphonenumber-js';

import checkEmail from '@/utils/checkEmail';

const Facebook = () => import('@/components/serviceComponents/svg/social/Facebook');
const Instagram = () => import('@/components/serviceComponents/svg/social/Instagram');
const Twitter = () => import('@/components/serviceComponents/svg/social/Twitter');
const VK = () => import('@/components/serviceComponents/svg/social/VK');
const Whatsup = () => import('@/components/serviceComponents/svg/social/Whatsup');
const Youtube = () => import('@/components/serviceComponents/svg/social/Youtube');
const Google = () => import('@/components/serviceComponents/svg/social/Google');

import timezoneArr from '@/utils/timezoneHelper';

export default {
  name: 'Signin',

  components: {
    Facebook,
    Google,
    Instagram,
    Twitter,
    VK,
    Whatsup,
    Youtube,
  },

  data: () => ({
    userData: {
      phone: '',
      password: '',
      rptpassword: '',
      repeatPassword: '',
      confirmToS: false,
      confirmOffers: false,
      timezone: ''
    },
    passwordError: '',
    repeatPasswordError: '',
    userPhoneNumber: '',
    phoneIsValid: true,
    passwordIsCorrect: true,
    phoneError: false,
    isClicked: false,
    isClickedCross: false,
    isSignupFormClicked: false,
    isChevronClicked: false,
    isRemindPassword: false,
    tabSwitcher: {
      isPhoneActive: false,
      isEmailActive: true,
    },
    checkEmail: true,
    socialsClick: {google: false},
    isToSValid: true,
    siteKey: process.env.VUE_APP_RECAPCHA_KEY,
    phonePlaceholder: '+ X (XXX) XXX XX XX',
    isEyeOpen: false,
    isEyeOpenRpt: false,
    isRemindClicked: false,
    isSigninABClicked: false,
    isSignupABClicked: false,

    clearFields: false,
    isSignPressed: false,
    doLoginLock: false,
    expandTimezone: false,
    selectedTZValue: null,
    defaultTZLabel: null,
    timezoneArr: [],
  }),
  computed: {
    ...mapGetters({
      isAuthUser: 'auth/isAuthUser',
      currentLanguage: 'user/getLanguage',
      isSigninVisible: 'auth/isSigninVisible',
      isSignupVisible: 'auth/isSignupVisible',
      forgotToken: 'auth/getForgotToken',
      getSocials: 'auth/getSocials',
      brand: 'main/getBrand',
      getTwoFA: 'auth/getTwoFA',
      userProfile: 'user/getProfile',
      forgotPasswordWait: 'auth/getForgotPasswordWait',
      isForgotInMinutes: 'auth/isForgotInMinutes',
    }),
    isExBrand() {
      return this.brand.code == 'ex_w';
    },
    checkPhone() {
      return this.phoneIsValid;
    },
    getUserId() {
      return this.userProfile != null && this.userProfile.hasOwnProperty('id') ? this.userProfile.id : null;
    },
    isForgot() {
      return this.forgotToken != null;
    },
    getWrongRepPassswordMessage() {
      return this.$t('auth.authRepPasswordError');
    },
    switchForgotTitle() {
      return Boolean(this.isForgotInMinutes) === false ? this.$t('auth.forgotPasswordSecLabel') : this.$t('funds.fundSmsMinTitle');
    },
    isSelectedTZValue() {
      return this.selectedTZValue !== this.$t('profile.settings.settingsTimezoneLabel');
    }
  },
  methods: {
    ...mapActions({
      doLogin: 'auth/doLogin',
      doSignup: 'auth/doSignup',
      doForgotPassword: 'auth/doForgotPassword',
      fetchSocials: 'auth/fetchSocials',
    }),
    ...mapMutations({
      SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE',
      SET_SIGNUP_VISIBLE: 'auth/SET_SIGNUP_VISIBLE',
      SET_FORGOT_TOKEN: 'auth/SET_FORGOT_TOKEN',
      SET_TWOFA: 'auth/SET_TWOFA',
      SET_SHOW_PIN: 'auth/SET_SHOW_PIN',
      SET_MODAL_MESSAGE: 'main/SET_MODAL_MESSAGE',
    }),
    /**
     * Метод-обработчик для выбора даты.
     */
    listenDD(data) {
      this.selectedTZValue = data;
      this.userData.timezone = data;
    },
    getImgForSocial(index) {
      switch (index) {
        case 'google':
          return Google;
        case 'instagram':
          return Instagram;
        case 'twitter':
          return Twitter;
        case 'vkontakte':
          return VK;
        case 'whatsapp':
          return Whatsup;
        case 'youtube':
          return Youtube;
      }
      return null;
    },
    /**
     * обработчик нажатия клавиши в поле ввода номера телефона
     */
    keypressPhone() {
      if (this.userPhoneNumber && this.userPhoneNumber.length > 0 && this.userPhoneNumber.indexOf('+') === -1) {
        this.userPhoneNumber = '+' + this.userPhoneNumber;
      }
    },
    keypressPassword(e) {
      if (e.key === 'Enter') {
        if (this.isSigninVisible) {
          this.callSigninCheck();
        } else {
          this.callSignupCheck();
        }
      }
    },
    keypressRptPassword(e) {
      if (e.key === 'Enter') {
        if (this.isSigninVisible) {
          this.callSigninCheck();
        } else {
          this.callSignupCheck();
        }
      }
    },
    keypressForm(e) {
      if (e.keyCode === 27) {
        this.closeForm();
      }
    },
    /**
     * обработчик ввода в поле ввода номера телефона
     */
    inputPhone(e) {
      if (e.target.value !== '' && e.target.value.indexOf('+') === -1) {
        this.userPhoneNumber = '+' + this.userPhoneNumber;
      }
    },
    /**
     * проверка пароля пользователя
     */
    checkUserPassword() {
      if (this.userData.password.length < 7) {
        this.passwordError = this.$t('auth.passwordLessMessage');
        return false;
      }
      this.passwordError = null;

      if (!this.isSigninVisible) {
        if (this.isSignPressed) {
          if (this.userData.rptpassword == '' || this.userData.rptpassword.length < 7 || this.userData.rptpassword != this.userData.password) {
            this.repeatPasswordError = this.$t('modal.passwordWrongLabel');
            return false;
          }
        }
        this.repeatPasswordError = null;
      }
      return true;
    },
    /**
     * проверка email пользователя
     */
    checkUserEmail() {
      if (checkEmail(this.userData.email)) {
        this.checkEmail = true;

        return true;
      } else {
        this.checkEmail = false;

        return false;
      }
    },
    /**
     * проверка телефона пользователя,
     * isValidPhoneNumber метод из бибилиотеки libphonenumber-js
     */
    checkUserPhone() {
      if (isValidPhoneNumber(this.userPhoneNumber, this.currentLanguage.split('-')[1])) {
        this.phoneIsValid = true;
        this.phoneError = false;

        return true;
      } else {
        this.phoneIsValid = false;
        this.phoneError = true;

        return false;
      }
    },

    /**
     * проверка чекбокса правил
     */
    checkUserToS() {
      if (this.userData.confirmToS) {
        this.isToSValid = true;

        return true;
      } else {
        this.isToSValid = false;

        return false;
      }
    },
    closeForm() {
      this.isClickedCross = true;
      this.SET_SIGNIN_VISIBLE(false);
      this.SET_SIGNUP_VISIBLE(false);
      this.SET_FORGOT_TOKEN(null);
    },
    showForgotData() {
      this.phoneIsValid = true;
      this.phoneError = false;
      this.checkEmail = true;
      this.isRemindPassword = true;
      this.onCaptchaExpired();
      this.$nextTick(() => {
        setTimeout(() => {
          this.callSendPasswordCheck();
        }, 750);
      });
    },
    /**
     * при нажатии на логин проверяем поля, и только в случае успеха вызываем исполнение
     * гугл рекапчи
     */
    async callSigninCheck() {
      this.isSignPressed = true;

      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;
      const passwordBool = this.checkUserPassword();

      if (phoneBool && emailBool && passwordBool) {
        if (this.$refs.recaptchaLog == undefined) {
          // если капча не работает, отображаем окно ошибки  errorCode G-500,
          this.SET_MODAL_MESSAGE({
            level: 'error',
            icon: 'x',
            buttons: 'ok',
            text: this.$t('auth.errorChecked') + ' ' + this.$t('auth.reloadPage') +
                ' ' + this.$t('auth.problemRepeats') + ', ' + this.$t('auth.contactSupport') +
                ' ' + this.$t('auth.errorCode') + ' G-500',
            accept_action_func: 'close',
          });
        } else {
          await this.$refs.recaptchaLog.execute();
        }

      }
    },
    async nhSignin(recaptchaToken) {
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;
      const passwordBool = this.checkUserPassword();

      if (phoneBool && emailBool && passwordBool && !this.doLoginLock) {
        this.doLoginLock = true;
        this.isSigninABClicked = true;

        let loginPayload = {
          password: this.userData.password,
          token: recaptchaToken
        };

        if (this.tabSwitcher.isPhoneActive) {
          loginPayload.phone = this.userPhoneNumber;
        } else {
          loginPayload.email = this.userData.email.toLowerCase();
        }
        if (this.getTwoFA !== null) {
          loginPayload['2f_code'] = this.getTwoFA;
          this.SET_TWOFA(null);
        }

        let result = await this.doLogin(loginPayload);

        if (this.$metrika) {
          this.$metrika.reachGoal('loginClick');
        }

        if (!result) {
          this.onCaptchaExpired();
          this.doLoginLock = false;
        }

        if (this.isAuthUser) {
          if (this.getUserId != null) {
            if (this.$metrika) {
              this.$metrika.setUserID(this.getUserId);
            }
          }

          if (this.$metrika) {
            this.$metrika.reachGoal('loginSuccess');
          }
        }

        setTimeout(() => {
          this.isSigninABClicked = false;
        }, 500);
      }
    },
    callSignupCheck() {
      this.isSignPressed = true;

      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;
      const tosBool = this.checkUserToS();
      const passwordBool = this.checkUserPassword();

      if (phoneBool && emailBool && tosBool && passwordBool) {
        this.$refs.recaptchaReg.execute();
      }
    },
    async nhSignup(recaptchaToken) {
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;
      const tosBool = this.checkUserToS();
      const passwordBool = this.checkUserPassword();

      if (phoneBool && emailBool && tosBool && passwordBool) {
        this.isSignupABClicked = true;

        const birthDayData = this.isExBrand || this.userData.year == null || this.userData.month == null ?
            null : `${this.userData.year}-${this.userData.month}-${this.userData.day}`;
        const address = !this.isExBrand && this.userData.address != null ?
            {country: this.userData.address.country} : null;

        let signupPayload = {
          name: this.userData.name,
          surname: this.userData.surname,
          phone: this.tabSwitcher.isPhoneActive ? this.userPhoneNumber : null,
          email: this.tabSwitcher.isPhoneActive ? null : this.userData.email.toLowerCase(),
          token: recaptchaToken,
          birthday: birthDayData,
          promo_notify: this.userData.confirmOffers,
          address: address,
          password: this.userData.password,
          timezone: this.selectedTZValue
        };
        let result = await this.doSignup(signupPayload);

        if (this.$metrika) {
          this.$metrika.reachGoal('registerClick');
        }

        if (!result) {
          this.onCaptchaExpired();
        } else {
          if (this.$metrika) {
            this.$metrika.reachGoal('registerSuccess');
          }
        }

        setTimeout(() => {
          this.isSignupABClicked = false;
        }, 500);
      }
    },
    callChangePassCheck() {
      this.isSignPressed = true;
      const passwordBool = this.checkUserPassword();

      if (this.isForgot && passwordBool) {
        this.$refs.recaptchaForgot.execute();
      }
    },
    async changePassword(recaptchaToken) {
      this.isClicked = true;
      const passwordBool = this.checkUserPassword();

      if (this.forgotToken && passwordBool) {
        let payload = {
          forgotToken: this.forgotToken,
          password: this.userData.password,
          token: recaptchaToken,
        };
        if (await this.doForgotPassword(payload)) {
          this.closeForm();
        }
      }
    },
    /**
     * при нажатии на логин проверяем поля, и толкьо в случае успеха вызываем исполнение
     * гугл рекапчи
     */
    callSendPasswordCheck() {
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;

      if (phoneBool && emailBool) {
        this.$refs.recaptchaPass.execute();
      }
    },
    async sendPassword(recaptchaToken) {
      this.isClicked = true;
      const phoneBool = this.tabSwitcher.isPhoneActive ? this.checkUserPhone() : true;
      const emailBool = this.tabSwitcher.isEmailActive ? this.checkUserEmail() : true;

      if (phoneBool && emailBool) {
        let result;
        if (this.tabSwitcher.isPhoneActive) {
          result = await this.doForgotPassword({
            phone: this.userPhoneNumber,
            token: recaptchaToken,
          });
        } else {
          result = await this.doForgotPassword({
            email: this.userData.email.toLowerCase(),
            token: recaptchaToken,
          });
        }
        if (!result) {
          this.onCaptchaExpired();
        }

        this.isRemindPassword = false;
        this.userData.phone = '';
      }
    },
    /**
     * method for switching between phone and email input fields
     */
    tabSwitcherHandler(title) {
      switch (title) {
        case 'phone': {
          this.tabSwitcher.isPhoneActive = true;
          this.tabSwitcher.isEmailActive = false;
          this.checkEmail = true;
          this.phoneIsValid = true;
          this.passwordIsCorrect = true;
          this.phoneError = false;
          this.isToSValid = true;
          return;
        }
        case 'email': {
          this.tabSwitcher.isPhoneActive = false;
          this.tabSwitcher.isEmailActive = true;
          this.userData.phone = '';
          this.checkEmail = true;
          this.passwordIsCorrect = true;
          this.phoneError = false;
          this.isToSValid = true;
          return;
        }
      }
    },
    /**
     * метод открытия ссылки на социальную сеть в новой вкладке браузера
     */
    callSocial(index) {
      this.socialsClick[index] = true;

      setTimeout(() => {
        window.location = this.getSocials[index].url;
        this.socialsClick[index] = false;
      }, 50);
    },
    /**
     * метод по отображению/скрытию пароля
     */
    switchPasswordType(field = null) {
      let pass;
      if (field === 'rpt') {
        pass = this.$refs['rpt-password'];
      } else {
        pass = this.$refs.password;
      }

      if (pass.type === 'password') {
        pass.type = 'text';
        if (field === 'rpt') {
          this.isEyeOpenRpt = true;

        } else {
          this.isEyeOpen = true;
        }
      } else if (pass.type === 'text') {
        pass.type = 'password';
        if (field === 'rpt') {
          this.isEyeOpenRpt = false;

        } else {
          this.isEyeOpen = false;
        }
      }
    },
    onCaptchaExpired() {
      if (this.$refs.recaptchaLog != null) {
        this.$refs.recaptchaLog.reset();
      }

      if (this.$refs.recaptchaReg != null) {
        this.$refs.recaptchaReg.reset();
      }

      if (this.$refs.recaptchaPass != null) {
        this.$refs.recaptchaPass.reset();
      }

      if (this.$refs.recaptchaForgot != null) {
        this.$refs.recaptchaForgot.reset();
      }
    },
    switchAuthForm(arg) {
      this.userData.password = '';
      this.clearInputs();

      if (arg === 'login') {
        this.SET_SIGNIN_VISIBLE(true);
        this.SET_SIGNUP_VISIBLE(false);
      }

      if (arg === 'signup') {
        this.SET_SIGNUP_VISIBLE(true);
        this.SET_SIGNIN_VISIBLE(false);
      }
    },
    /**
     * метод-слушатель событий из компонента Кнопка
     */
    listenBtnEvent(data) {
      if (data) {
        switch (data) {
          case 'applyCallSigninCheck': {
            this.callSigninCheck();
            return;
          }
          case 'applyCallSignupCheck': {
            this.callSignupCheck();
            return;
          }
          case 'applyCallChangePassCheck': {
            this.callChangePassCheck();
            return;
          }
        }
      }
    },
    /**
     * обработчик потери фокуса на поле номера телефона
     */
    blurPhone() {
      if (this.userPhoneNumber !== '') {
        this.checkUserPhone();
      }
    },
    /**
     * обработчик потери фокуса на поле email
     */
    blurEmail() {
      if (this.userData.email !== '') {
        this.checkUserEmail();
      }
    },
    /**
     * метод-слушатель событий из компонента Checkbox
     */
    listenCheckbox(data) {
      if (data != null) {

        switch (data.name) {
          case 'offersCheckbox': {
            this.userData.confirmOffers = data.value;
            return;
          }
          case 'tosCheckbox': {
            this.userData.confirmToS = data.value;
            return;
          }
        }
      }
    },
    /**
     * очистка полей ввода при переключении кнопок Логин-Регистрация
     */
    clearInputs() {
      this.phoneIsValid = true;
      this.phoneError = false;
      this.checkEmail = true;
      this.passwordError = false;
      this.repeatPasswordError = false;
      this.isToSValid = true;
    },
  },
  watch: {
    selectedTZValue(newVal) {
      const node = this.$refs['tzFlSpan'];

      if (newVal != null) {
        if (node != null) {
          node.classList.add('up');
        }
      }
      else {
        if (node != null) {
          node.classList.remove('up');
        }
      }
    },
    isSigninVisible(newValue) {
      if (newValue === true) {
        this.SET_SIGNUP_VISIBLE(false);
      }
    },
    isSignupVisible(newValue) {
      if (newValue === true) {
        this.SET_SIGNIN_VISIBLE(false);
      }
    },
    userData: {
      deep: true,
      handler(newUserData) {
        if (newUserData.password && newUserData.password.length >= 3) {
          this.passwordIsCorrect = true;
        }

        if (newUserData.phone === '+') {
          this.userData.phone = '';
        }

        if ((/\(|\)/).test(newUserData.phone)) {
          this.userData.phone = this.userData.phone !== '' ? this.userData.phone.replace(/\(|\)/g, '') : '';
        }

        if ((/\-/).test(newUserData.phone)) {
          this.userData.phone = this.userData.phone !== '' ? this.userData.phone.replace(/\-/g, ' ') : '';
        }

        /**
         * watch for email field
         */
        if (newUserData.email !== '') {
          this.userData.email = newUserData.email.trim();

          if (checkEmail(newUserData.email)) {
            this.checkEmail = true;
          }
        }

        if (newUserData.password !== '' || newUserData.rptpassword !== '') {
          this.checkUserPassword();
        }

        if (this.isSignPressed) {
          if (newUserData.confirmToS) {
            this.isToSValid = true;
          } else {
            this.isToSValid = false;
          }
        }
      }
    },
    userPhoneNumber(newUserPhoneNumber) {
      if (newUserPhoneNumber !== '') {
        if (checkEmail(newUserPhoneNumber)) {
          this.tabSwitcher.isPhoneActive = false;
          this.tabSwitcher.isEmailActive = true;
          this.userData.email = newUserPhoneNumber;
          this.userPhoneNumber = '';
        } else {
          if (isValidPhoneNumber(this.userPhoneNumber, this.currentLanguage.split('-')[1])) {
            this.phoneIsValid = true;
            this.phoneError = false;
          }
        }
      } else {
        this.phoneIsValid = true;
        this.phoneError = false;
      }
    },
    isSignupFormClicked(newSignupValue) {
      if (newSignupValue) {
        setTimeout(() => {
          this.isSignupFormClicked = false;
        }, 250);
      }
    },
    isClicked(newIsClicked) {
      if (newIsClicked) {
        setTimeout(() => {
          this.isClicked = false;
        }, 300);
      }
    },
    isRemindPassword(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.$nextTick(() => {
          this.$refs.recaptchaLog.reset();
        });
      }
    },
    getTwoFA(newVal, oldVal) {
      if (newVal != oldVal) {
        this.onCaptchaExpired();
        this.callSigninCheck();
      }
    },
    forgotPasswordWait(newVal, oldVal) {
      if (newVal === 0 && oldVal != null) {
        this.isRemindPassword = false;
      }
    },
  },
  async mounted() {
    await this.fetchSocials();
    if (this.isSigninVisible && !this.isRemindPassword) {
      if (this.$metrika) {
        this.$metrika.reachGoal('loginOpen');
      }
    } else if (this.isSignupVisible && !this.isRemindPassword) {
      if (this.$metrika) {
        this.$metrika.reachGoal('registerOpen');
      }
    }

    window.addEventListener('keydown', this.keypressForm);
    if (document.querySelector('div[title]') != null) {
      document.querySelector('div[title]').style.zIndex = '-10';
    }

    const _email = document.getElementById('email');

    if (_email != null) {
      _email.focus();
    }

    this.selectedTZValue = this.$t('profile.settings.settingsTimezoneLabel');
    this.defaultTZLabel = this.$t('profile.settings.settingsTimezoneLabel');

    this.$nextTick(() => {
      this.timezoneArr = timezoneArr;
    });
  },
  beforeDestroy() {
    this.SET_TWOFA(null);
    this.clearFields = true;
  }
};
</script>

<style lang="scss">
</style>