<template lang="pug">
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {displayCoins, displayCoinsToInt, roundFloat} from '@/utils/moneyUtils';


export default {
  name: 'FundWithdrawal',
  components: {},
  data: () => ({
    withdrawalData: {
      network: null,
      cryptoAddress: null,
      amount: null,
      fee: null,
      totalAmount: null,
      displayTotalAmount: null,
      smsCode: null,
      isButtonDisable: true,
      buttonText: null,
      buttonAction: null,
      codeDisabled: true, // Блокировка ввода кода
      remainingInputTime: 0, // время блокировки
      codePlaceholder: '', // placeholder ввода кода минут:секунд
      buttonLockTime: 0, // время блокировки кнопки
      rawAmountData: null // текущие данные поля суммы
    },
    displayCoins,
    displayCoinsToInt,
    roundFloat
  }),
  computed: {
    ...mapGetters({
      activeTab: 'fund/getActiveTab',
      activeFundItem: 'fund/getActiveFundItem',
      fundAccounts: 'fund/getFundAccount',
      paymentMethods: 'fund/getPaymentMethods',
    }),
    showWithdrawalTitle() {
      return this.$t('funds.withdrawal') + ' ' + this.currency.code;
    },
    currency() {
      return this.fundAccounts[this.activeFundItem].currency;
    },
    cryptoWallets() {
      return this.fundAccounts[this.activeFundItem].crypto_wallets != null ? this.fundAccounts[this.activeFundItem].crypto_wallets : '';
    },
    paymentMethod() {
      if (!this.paymentMethods) {
        return null;
      }
      let p = Object.values(this.paymentMethods).filter(p => p.currency_code === this.currency.code);
      if (p && p.length > 0) {
        return p[0];
      }
      return null;
    },

    min() {
      if (this.paymentMethod.withdrawal_min == null || this.paymentMethod.withdrawal_min == 0) {
        return this.$t('profile.payments.noLimitPaymentLabel');
      }
      return this.displayCoins(this.paymentMethod.withdrawal_min, this.currency);
    },
    max() {
      if (this.paymentMethod.withdrawal_max == null) {
        return this.$t('profile.payments.noLimitPaymentLabel');
      }
      if (this.paymentMethod.withdrawal_max == 0) {
        return this.$t('profile.payments.notAvailablePaymentLabel');
      }
      return this.displayCoins(this.paymentMethod.withdrawal_max, this.currency);
    },
    fee() {
      if ((this.paymentMethod.withdrawal_fee_percent == null || this.paymentMethod.withdrawal_fee_percent == 0) &&
          (this.paymentMethod.withdrawal_fee_min == null || this.paymentMethod.withdrawal_fee_min == 0)) {
        return this.$t('profile.payments.noFeePaymentLabel');
      }
      if (this.paymentMethod.withdrawal_fee_percent > 0 && this.paymentMethod.withdrawal_fee_min > 0) {
        return this.paymentMethod.withdrawal_fee_percent + '%, ' + this.$t('profile.payments.minFeePaymentLabel') + ' '
            + this.displayCoins(this.paymentMethod.withdrawal_fee_min, this.currency, true, false);
      }
      if (this.paymentMethod.withdrawal_fee_percent > 0 && this.paymentMethod.withdrawal_fee_min == 0) {
        return this.paymentMethod.withdrawal_fee_percent + '%';
      }
      if (this.paymentMethod.withdrawal_fee_percent == 0 && this.paymentMethod.withdrawal_fee_min > 0) {
        return this.displayCoins(this.paymentMethod.withdrawal_fee_min, this.currency);
      }
      return '';
    },

    description() {
      return this.currency.is_crypto ? this.$t('profile.payments.cryptoWithdrawalLabel') : this.$t('profile.payments.cashWithdrawalLabel');
    },
    isSubmitDisable() {
      return this.withdrawalData.isButtonDisable
          || (this.cryptoWallets && this.cryptoWallets.length > 1 && !this.withdrawalData.network)
          || (this.currency.is_crypto && !this.withdrawalData.cryptoAddress); //TODO validate address
    }
  },
  methods: {
    ...mapActions({
      doSendSmsCode: 'fund/doSendSmsCode',
      doPay: 'pay/doPay',
    }),
    listenTextInput(data) {
      if (data) {
        switch (data.name) {
          case 'totalAmount': {
            this.resetForm(false);
            this.withdrawalData.rawAmountData = data;
            let newVal = parseFloat(parseFloat(data.value).toFixed(this.currency.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, ''));
            if (newVal > 0) {
              this.withdrawalData.totalAmount = this.displayCoinsToInt(newVal, this.currency);
              this.withdrawalData.displayTotalAmount = newVal;

              let min = this.paymentMethod.withdrawal_min;
              if (min > 0 && this.withdrawalData.totalAmount < min) {
                //TODO message
                return;
              }
              let max = this.paymentMethod.withdrawal_max;
              if (max != null && this.withdrawalData.totalAmount > max) {
                //TODO message
                return;
              }

              let fee = 0;
              if (this.paymentMethod.withdrawal_fee_percent > 0) {
                fee = Math.round(this.withdrawalData.totalAmount * this.paymentMethod.withdrawal_fee_percent / 100.00);
              }
              if (this.paymentMethod.withdrawal_fee_min > 0 && fee < this.paymentMethod.withdrawal_fee_min) {
                fee = parseInt(this.paymentMethod.withdrawal_fee_min);
              }
              this.withdrawalData.displayFee = displayCoins(fee, this.currency, false);
              this.withdrawalData.fee = fee;
              this.withdrawalData.amount = this.withdrawalData.totalAmount - this.withdrawalData.fee;
              this.withdrawalData.displayAmount = displayCoins(this.withdrawalData.amount, this.currency, false);

              if (this.withdrawalData.totalAmount > this.fundAccounts[this.activeFundItem].value) {
                //TODO message
                return;
              }

              if(this.withdrawalData.buttonLockTime > 0 ){
                this.withdrawalData.isButtonDisable = true;
                return;
              }

              if (!this.smsCode) {
                this.withdrawalData.buttonText = this.$t('funds.sendSmsTitle');
                this.withdrawalData.buttonAction = 'sendSms';
              } else {
                this.withdrawalData.buttonText = this.$t('profile.payments.withdrawalButton');
                this.withdrawalData.buttonAction = 'doPay';
              }
              this.withdrawalData.isButtonDisable = false;

            }
            return;
          }
          case 'smsCode': {
            this.withdrawalData.smsCode = data.value;
            if (this.withdrawalData.smsCode && this.withdrawalData.amount) {
              this.withdrawalData.buttonText = this.$t('profile.payments.withdrawalButton');
              this.withdrawalData.buttonAction = 'doPay';
            } else if (!this.withdrawalData.smsCode) {
              this.withdrawalData.buttonAction = 'sendSms';
              this.withdrawalData.buttonText = this.$t('funds.sendSmsTitle');
              if (this.withdrawalData.buttonLockTime > 0) {
                this.withdrawalData.isButtonDisable = true;
                return;
              }
            }
            this.withdrawalData.isButtonDisable = false;

            return;
          }
          case 'cryptoAddress': {
            this.withdrawalData.cryptoAddress = data.value;
            if (this.withdrawalData.remainingInputTime ) {
              if(this.withdrawalData.buttonLockTime > 0){
                this.initTimer(this.withdrawalData.buttonLockTime);
              } else {
                this.withdrawalData.remainingInputTime = 0;
                this.withdrawalData.codeDisabled = true;
                this.withdrawalData.codePlaceholder = '';
              }
            }
            return;
          }
        }
      }
    },

    createPlaceholderTime(data){
      let t = ' ' + this.$t('funds.fundAvailableTitle') + ' ';
      if(data > 0 && !this.withdrawalData.codeDisabled){
        let second = data%60;
        let minute = (data - second) /60;
        if(minute == 10){
          // избегаем 2 цифры минут, чтобы не скакала разметка
          minute = 9;
          second = 59;
        }
        second = (second < 10) ? '0' + String(second) : second;
        this.withdrawalData.codePlaceholder = t
            + String(minute) + this.$t('funds.fundSmsMinTitle')
            + ' ' + String(second) + this.$t('funds.fundSmsSecTitle');
      } else {
        this.withdrawalData.codePlaceholder = t
            + '0' + this.$t('funds.fundSmsMinTitle')
            + ' 00' + this.$t('funds.fundSmsSecTitle');
      }
    },

    initTimer(newButtonLockTime = 0) {

      if(newButtonLockTime){
        this.withdrawalData.remainingInputTime = 0;
        this.withdrawalData.buttonLockTime = newButtonLockTime;
        this.withdrawalData.codeDisabled = true;
        this.withdrawalData.codePlaceholder = '';
      } else {
        this.withdrawalData.remainingInputTime = 600;
        ++this.withdrawalData.remainingInputTime;
        this.withdrawalData.buttonLockTime = 30;
        this.withdrawalData.codeDisabled = false;
      }
      ++this.withdrawalData.buttonLockTime;

      this.timerId = Date.now();

      (function iterate(t, id) {
        let nextStep = false;
        if (t.withdrawalData.remainingInputTime > 0) {
          t.createPlaceholderTime(--t.withdrawalData.remainingInputTime);
          nextStep = true;
        }

        if (t.withdrawalData.buttonLockTime > 0) {
          if(t.withdrawalData.buttonAction !== 'doPay'){
            t.withdrawalData.isButtonDisable = true;
          }
          if(nextStep && t.withdrawalData.buttonLockTime <= 1) {
            t.withdrawalData.isButtonDisable = false;
          }
          --t.withdrawalData.buttonLockTime;
          nextStep = true;
        } else {
          if(!nextStep){
            if(t.withdrawalData.rawAmountData){
              t.listenTextInput(t.withdrawalData.rawAmountData);
            } else {
              t.resetForm(false);
            }
          }
        }

        if(nextStep){
          setTimeout(function () {
            if (t.timerId === id) {
              iterate(t, id);
            }
          }, 1000);
        }
      })(this, this.timerId);
    },


    async doWithdrawal() {
      if (!this.withdrawalData.isButtonDisable) {
        if (this.withdrawalData.buttonAction == 'sendSms') {
          let result = await this.doSendSmsCode();
          if (result.success) {
            this.initTimer();
          } else {
            if (result.sec > 0){
              this.initTimer(result.sec);
            } else {
              this.withdrawalData.isButtonDisable = false;
            }
          }
        } else if (this.withdrawalData.buttonAction == 'doPay') {
          this.withdrawalData.isButtonDisable = true;
          let response = await this.doPay({
            type: 'withdrawal',
            source: this.currency.is_crypto ? 'crypto' : 'cash',
            address: this.withdrawalData.cryptoAddress,
            network: this.withdrawalData.network,
            amount: this.withdrawalData.totalAmount,
            fee: this.withdrawalData.fee,
            code: this.withdrawalData.smsCode,
            currency: this.currency.code,
          });
          if (response && response.hasOwnProperty('error') && response.error == 0) {
            this.resetForm();
          } else {
            if (response && response.hasOwnProperty('error') && response.error == 505) {
              this.withdrawalData.smsCode = null;
            }
            this.withdrawalData.isButtonDisable = false;
          }
        }
      }
    },
    resetForm(full = true) {
      this.withdrawalData = {
        network: full ? null : this.withdrawalData.network,
        cryptoAddress: full ? null : this.withdrawalData.cryptoAddress,
        totalAmount: full ? null : this.withdrawalData.totalAmount,
        fee: null,
        amount: null,
        smsCode: null,
        isButtonDisable: true,
        buttonText: this.$t('funds.sendSmsTitle'),
        buttonAction: 'sendSms',
        codeDisabled: true,
        codePlaceholder: '',
        buttonLockTime: this.withdrawalData.buttonLockTime,
        rawAmountData: null
      };
    }
  },
  watch: {
    activeFundItem() {
      this.resetForm();
    },
    activeTab() {
      this.resetForm();
    }
  },
  beforeMount() {
    this.resetForm();
  }
};
</script>

<style lang="scss" scoped>
</style>