import {clientApi} from '@/utils/clientApi';

export const doCheckActive = async ({commit, dispatch}, payload) => {
    try {
        let response = await clientApi('post', 'payment/check-active', payload);

        const {data, status} = response;

        if (status === 200) {
            if (data.hasOwnProperty('error') && data.error > 0) {
                if (data.error !== 401) {
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: data.message,
                        accept_action_func: 'close',
                    }, {root: true});
                }
            } else {
                if (data.data.exist) {
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'info',
                        icon: 'i',
                        buttons: 'yes_no',
                        text: data.message,
                        accept_action_func: async () => {
                            await dispatch('fetchPayStatus', data.data.trx_id);
                        },
                    }, {root: true});

                }
            }
        } else {
            if (data.hasOwnProperty('message')) {
                if (data.error !== 401) {
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: data.message,
                        accept_action_func: 'close',
                    }, {root: true});
                }
            }
        }
        return data;
    } catch (error) {
        console.log(error);
    }
};
