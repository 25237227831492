<template lang="pug">
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {displayCoins, roundFloat} from '@/utils/moneyUtils';


export default {
  name: 'Fund',
  components: {
  },
  data: () => ({
    fundSwitcher: {
      isFundDepositActive: true,
      isFundWithActive: false,
    },
    displayCoins,
    roundFloat
  }),
  computed: {
      ...mapGetters({
        fundAccount: 'fund/getFundAccount',
        activeFundItem: 'fund/getActiveFundItem',
        activeTab: 'fund/getActiveTab',
        currencies: 'fund/getCurrencies',
      }),
      compoundTabsArray() {
        return [
          {
            id: 'history',
            title: this.$t('funds.historyTitle')
          },
          {
            id: 'deposit',
            title: this.$t('funds.deposit')
          },
          {
            id: 'withdrawal',
            title: this.$t('funds.withdrawal')
          },
        ];
      },
      getActiveTab() {
        return this.activeTab;
      },
      getActiveTabDeposit() {
        return this.getActiveTab != null && this.getActiveTab.hasOwnProperty('id') && this.getActiveTab.id === 'deposit';
      },
      getActiveTabWithdrawal() {
        return this.getActiveTab != null && this.getActiveTab.hasOwnProperty('id') && this.getActiveTab.id === 'withdrawal';
      },
      getActiveTabHistory() {
        return this.getActiveTab != null && this.getActiveTab.hasOwnProperty('id') && this.getActiveTab.id === 'history';
      },
  },
  methods: {
      ...mapActions({
        fetchFundsAccounts: 'fund/fetchFundsAccounts',
      }),
      ...mapMutations({
        SET_ACTIVE_FUND_ITEM: 'fund/SET_ACTIVE_FUND_ITEM',
        SET_ACTIVE_TAB: 'fund/SET_ACTIVE_TAB',
      }),
      selectFund(data) {
          this.SET_ACTIVE_FUND_ITEM(data);

      },
  },
  async beforeMount() {
    await this.fetchFundsAccounts();
    this.SET_ACTIVE_FUND_ITEM(Object.values(this.fundAccount)[0].id);
    this.SET_ACTIVE_TAB({
      id: 'history',
      title: this.$t('funds.historyTitle')
    });
  },
};
</script>

<style lang="scss">
</style>