<template lang="pug">
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {displayCoins, roundFloat} from '@/utils/moneyUtils';

export default {
  name: 'Bid',
  props: {
    type: {
      type: String
    },
    bestRate: {
      type: Number,
    }
  },
  data: () => ({
    displayCurrency: convertCurrencyToSymbol,
    displayMoney: displayCoins,
    roundFloat,
    bidsErrors: {buy: {amountError: false}},
    recaltTimeout: null
  }),

  computed: {
    ...mapGetters({
      activeTab: 'fund/getActiveTab',
      activeTab2: 'fund/getActiveTab2',
      fundAccount: 'fund/getFundAccount',
      toolsCurrency: 'fund/getExchangeToolsCurrency',
      bids: 'fund/getExchangeBids',
      profile: 'user/getProfile',
      pages: 'main/getPages',
      isAuthUser: 'auth/isAuthUser',
    }),
    title() {
      return this.$t(`exchange.${this.type}`);
    },
    fee() {
      if (this.bids[this.type].feePercent && this.bids[this.type].fee) {
        let currency = this.activeTab.currency;
        let f = roundFloat(this.bids[this.type].displayFee, currency.minor_unit).toFixed(currency.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, '');

        return `${this.$tc('menu.fee', 2)}: ${this.bids[this.type].feePercent}% ${f}` +
            (this.type == 'buy' ? currency.code : this.toolsCurrency.code);
      } else {
        return `${this.$tc('menu.fee', 2)}:`;
      }
    },
    rate() {
      if (this.activeTab2 && this.activeTab2.id == 'limit') {
        return null;
      }
      return this.displayMoney(this.bestRate, this.toolsCurrency, false);
    },

    changeBtnTitle() {
      return this.type === 'buy' ? this.$t('exchange.buy') : this.$t('exchange.sell');
    },
  },
  methods: {
    ...mapActions({
      doBid: 'fund/doBid',
    }),
    ...mapMutations({
      RECALC_BID: 'fund/RECALC_BID',
      SET_EXCHANGE_BID: 'fund/SET_EXCHANGE_BID',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_MODAL_MESSAGE: 'main/SET_MODAL_MESSAGE',
      SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE',
    }),

    listenTextInput(data) {
      if (data) {
        let bid = this.bids[this.type];
        bid.fee = null;
        switch (data.name) {
          case 'amount': {
            let newAmount = parseFloat(parseFloat(data.value).toFixed(this.activeTab.currency.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, ''));
            if (newAmount > 0.00) {
              bid.amount = newAmount;
              if (bid.rate && bid.deviation !== null) {
                bid.totalPrice = null;
                this.RECALC_BID(bid);
              } else {
                this.SET_EXCHANGE_BID(bid);
              }
            }
            return;
          }
          case 'rate': {
            bid.rate = parseFloat(parseFloat(data.value).toFixed(this.toolsCurrency.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, ''));
            this.RECALC_BID(bid);
            return;
          }
          case 'totalPrice': {
            if (this.recaltTimeout){
              clearTimeout(this.recaltTimeout);
            }
            this.recaltTimeout = setTimeout(()=>{
              let newPrice = parseFloat(parseFloat(data.value).toFixed(this.toolsCurrency.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, ''));
              if(newPrice > 0.00)
            {
              bid.totalPrice = newPrice;
              if (bid.rate && bid.deviation !== null) {
                bid.amount = null;
                this.RECALC_BID(bid);
              } else {
                this.SET_EXCHANGE_BID(bid);
              }
            }
          },1000);
            return;
          }
        }
      }
    },

    async doOperation() {
      if (!this.profile.phone || this.profile.phone_status !== 'accept') {
        if (!this.pages.personal && this.isAuthUser) {
          this.$router.push('/personal');
          this.SET_OPEN_PAGE('personal');
          window.scrollTo(0, 0);
        }

        if (!this.isAuthUser) {
          this.SET_SIGNIN_VISIBLE(true);
          return;
        }

        this.SET_MODAL_MESSAGE({
          level: 'warning',
          icon: '!',
          buttons: 'ok',
          text: this.$t('auth.doVerifyPhoneMessage'),
          accept_action_func: 'close'
        });
        return;
      }

      let bid = this.bids[this.type];
      if (bid.amount && bid.rate && bid.deviation !== null) {
        bid.toolsCurrency = this.toolsCurrency.code;
        bid.currency = this.activeTab.currency.code;
        bid.method = this.activeTab2.id;

        let res = await this.doBid(bid);
        if (res) {
          bid = {
            type: this.type,
            amount: null,
            deviation: 0,
            rate: null,
            feePercent: 0.25,
            fee: null,
            totalPrice: null
          };
          this.SET_EXCHANGE_BID(bid);
        }
      }
    }
  },
  watch: {
    toolsCurrency() {
      this.listenTextInput({value: this.rate, name: 'rate'});
    },
    activeTab() {
      this.listenTextInput({value: this.rate, name: 'rate'});
    },
    activeTab2() {
      this.listenTextInput({value: this.rate, name: 'rate'});
    },
    bestRate(newVal, oldVal) {
      if (this.activeTab2 && this.activeTab2.id == 'market') {
        if (!this.bids[this.type].rate || this.bids[this.type].rate == displayCoins(oldVal, this.toolsCurrency, false)) {
          this.listenTextInput({value: this.rate, name: 'rate'});
        }
      }
    },
  },
  async mounted() {
  },
};
</script>

<style lang="scss">
</style>