<template lang="pug">
  svg#ytb(width='47' height='47' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFillFirst' d='M42 0H5C2.23858 0 0 2.23858 0 5V42C0 44.7614 2.23858 47 5 47H42C44.7614 47 47 44.7614 47 42V5C47 2.23858 44.7614 0 42 0Z')
    path(:fill='changeFillSecond' d='M39 29.6203C38.9931 32.0359 37.0784 33.9936 34.7143 34H13.2857C10.9216 33.9936 9.00693 32.0359 9 29.6203V17.381C9.00693 14.9641 10.9216 13.0077 13.2857 13H34.7143C37.0784 13.0077 38.9931 14.9641 39 17.381V29.6203ZM20.8992 27.2484L29.0105 22.9589L20.8992 18.6687V27.2484Z')
</template>

<script>
export default {
  name: 'Youtube',
  props: {
    isClicked: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    changeFillFirst() {
      if (this.isClicked) {
        return '#ffffff';
      } else {
        return '#4A9eba';
      }
    },
    changeFillSecond() {
      if (this.isClicked) {
        return '#cd1e1e';
      } else {
        return '#ffffff';
      }
    },
  },
};
</script>