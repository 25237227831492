import {clientApi} from '@/utils/clientApi';
import cookie from 'vue-cookies';
import i18n from '@/i18n';

export const doLogin = async ({ commit, rootGetters, dispatch, state }, payload) => {
  let response;
  let currentLang = rootGetters['user/getLanguage'];

  try {
    response = await clientApi('post', `user/login?lang=${currentLang}`, {data: payload});
  } 
  catch (error) {
    console.log('error = ', error);
  }

  const { error, message, action, token, id } = response.data;

  if (error === 0) {
    if(action == 'code2fa'){
      const twoFAInputFields= [
        {
          placeholder: i18n.t('auth.twoFAPlaceholder'),
          name: 'twoFACode',
          it_pass: false,
          regexp: new RegExp(/[0-9|a-z]{4}/),
          equal_name: '',
          typeString: 'text',
          errorText: '',
          value: null,
        }
      ];

      commit('main/SET_MODAL_MESSAGE', {
        buttons: 'ok',
        text: message,
        level: 'warning',
        icon: '!',
        input_fields: twoFAInputFields,
        accept_button_text: i18n.t('auth.sendPasswordButton'),
        accept_action_func: '2FA',
      }, {root: true});

      return false;
    }

    cookie.set('accessToken', token);
    cookie.set('playerId', id);
    commit('SET_SIGNIN_VISIBLE', false);

    await dispatch('user/fetchBalance', null, { root: true });
    await dispatch('user/fetchProfile', null, { root: true });

    // Меняем статус только по завершению обновления данных баланса и аккаунта!
    state.authUser = true;
    return true;
  }
  else {
    commit('main/SET_MODAL_MESSAGE', {
      level: 'error',
      icon: 'x',
      buttons: 'ok',
      text: message,
      accept_action_func: 'close',
    }, {root: true});

    return false;
  }
};
