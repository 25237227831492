<template lang="pug">
  .personal-wrapper
    Breadcrumb

    .right-section
      .section-wrapper
        .section-wrapper__title(v-show=`openSection != 'editProfile' && openSection != 'security' &&
              openSection != 'paymentsHistory' && openSection != 'settings'`)
          h3.header {{ setPersonalAreaTitle }}

        .section-content
          keep-alive
            component(:is="currentLink")
</template>

<script>

const Breadcrumb = () => import('@/components/personal/sundukWhite/BreadcrumbSW');
const EditProfile = () => import('@/components/personal/sundukWhite/EditProfileSW');
const Security = () => import('@/components/personal/sundukWhite/SecuritySW');
const Settings = () => import('@/components/personal/sundukWhite/SettingsSW');
const Deposit = () => import('@/components/personal/sundukWhite/DepositSW');
const Withdrawal = () => import('@/components/personal/sundukWhite/WithdrawalSW');
const Payments = () => import('@/components/personal/sundukWhite/PaymentsSW');

import Personal from '../Personal.vue';

export default {
  extends: Personal,
  components: {
    Breadcrumb,
    EditProfile,
    Security,
    Settings,
    Deposit,
    Withdrawal,
    Payments
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/personal/personal_sw.scss";
</style>
