import {clientApi} from '@/utils/clientApi';

export const doPay = async ({commit, dispatch}, payload) => {
    try {
        payload.location = document.location.origin;
        let response = await clientApi('post', 'payment/index', payload);

        const {data, status} = response;

        if (status === 200) {
            if (data.hasOwnProperty('error') && data.error > 0) {
                if (data.error !== 401) {
                    let action = 'close';
                    if (data.error === 997) {
                        action = () => {
                            commit('main/SET_MODAL_MESSAGE', null, {root: true});
                            commit('personal/SET_OPEN_SECTION', 'editProfile', {root: true});
                        };
                    }
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: data.message,
                        accept_action_func: action,
                    }, {root: true});
                }
            } else {
                commit('main/SET_MODAL_MESSAGE', {
                    level: 'info',
                    icon: 'i',
                    buttons: 'ok',
                    text: data.message,
                    accept_action_func: 'close',
                }, {root: true});
                await dispatch('fetchPayStatus', data.data.trx_id);
            }
        } else {
            if (data.hasOwnProperty('message')) {
                if (data.error !== 401) {
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: data.message,
                        accept_action_func: 'close',
                    }, {root: true});
                }
            }
        }
        return data;
    } catch (error) {
        console.log(error);
    }
};
