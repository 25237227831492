<template lang="pug">
.page
  HistorySW
</template>

<script>
import { mapMutations } from 'vuex';

const HistorySW = () => import('@/components/exchange/history/HistorySW');

export default {
  name: 'HistoryExchPage',
  components: {
    HistorySW,
  },
  methods: {
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
  },
  mounted() {
    this.SET_OPEN_PAGE('history');
  },
};
</script>

<style lang="scss">
.page {
  height: 100%;
  width: 100%;
}
</style>