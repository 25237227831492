<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {displayCoins} from '@/utils/moneyUtils';


export default {
  name: 'Header',
  data: () => ({
    clickLinkTimeout: null,
    isTopMenuClicked: false,
    isSigninClicked: false,
    isSignupClicked: false,
    isUserLogoClicked: false,
    moneyCurency: convertCurrencyToSymbol,
    moneyFormat: displayCoins,
  }),
  computed: {
    ...mapGetters({
      isSigninVisible: 'auth/isSigninVisible',
      isSignupVisible: 'auth/isSignupVisible',
      userProfile: 'user/getProfile',
      brand: 'main/getBrand',
    }),
    /**
     * проверяем, что brand имеет свойство logo, и если имеет, возвращаем лого
     */
    getBrandLogo() {
      return this.brand.hasOwnProperty('logo') ? require(`@/assets/images/top-menu/sunduk-white/${this.brand.logo}`) : null;
    },
    userBalance() {
      return this.isAuthUser? this.$store.getters['user/getBalance'] : null;
    },
    isAuthUser() {
      return this.$store.getters['auth/isAuthUser'] && this.brand.code === 'sunduk_w';
    },
    isBalance() {
      return this.isAuthUser && typeof this.userBalance !== 'undefined' && this.userBalance !== null;
    },
    formatBalance() {
      return this.isBalance
          ? this.moneyFormat(this.userBalance.value, this.userBalance, false, false)
          : '';
    }
  },
  methods: {
    ...mapActions({
      fetchBalance: 'user/fetchBalance',
      doTestPay: 'pay/doTestPay',
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE',
      SET_SIGNUP_VISIBLE: 'auth/SET_SIGNUP_VISIBLE',
    }),
    menuHandler(arg) {
      if (document.querySelector('.main__info.clicked') != null) {
        document.querySelector('.main__info.clicked').classList.remove('clicked');
      }

      this.isTopMenuClicked = true;
      switch (arg) {
        case 'promo': {
          if (window.location.href.indexOf('promo') > 0) {
            return;
          }
          break;
        }
      }

      if (this.clickLinkTimeout) {
        clearTimeout(this.clickLinkTimeout);
      }

      this.clickLinkTimeout = setTimeout(async () => {

        if (arg === 'personal') {
          if (this.isAuthUser) {
            this.SET_OPEN_PAGE(arg);
          }
        }
        else {
          this.SET_OPEN_PAGE(arg);
        }


        switch (arg) {

          case 'support': {
            this.isUserLogoClicked = false;
            break;
          }

          case 'promo': {
            this.isUserLogoClicked = false;
            break;
          }

          case 'personal': {
            if (this.isAuthUser) {
              this.isUserLogoClicked = true;
              //eslint-disable-next-line
              this.$router.push('/personal').catch(e => {});
              window.scrollTo(0, 0);
              break;
            }
          }
        }

        if (arg === 'personal') {
          if (this.isAuthUser) {
            if (window.location.pathname !== `/${arg}`) {
              window.history.replaceState(null, null, `${window.location.origin}/${arg}`);
            }
          }
        }

        this.isTopMenuClicked = false;
      }, 10);
    },
    logoHandler() {
      //eslint-disable-next-line
      this.$router.push('/').catch(e => {});
      window.scrollTo(0, 0);
      this.SET_OPEN_PAGE('root');
    },
    async balanceHandler() {
      await this.doTestPay();
      this.fetchBalance();
    },
    ddmHandler() {
      window.postMessage('LeftMenuMobile');
    },
    signinHandler() {
      this.isSigninClicked = true;
      this.SET_SIGNIN_VISIBLE(true);
      setTimeout(() => {
        this.isSigninClicked = false;
      }, 1000);
    },
    signupHandler() {
      this.isSignupClicked = true;
      this.SET_SIGNUP_VISIBLE(true);
      setTimeout(() => {
        this.isSignupClicked = false;
      }, 1000);
    },
  },
  watch: {
    isAuthUser(newValue) {
      if (newValue) {
        this.isClickedExit = false;
        if (this.brand.code === 'sunduk_w') {
          this.fetchBalance();
        }
      }
      else {
        if (this.isSigninClicked) {
          this.isSigninClicked = false;
        }
      }
    },
    brand: {
      deep: true,
      async handler(newBrand) {
        if (newBrand.hasOwnProperty('logo')) {
          await this.$nextTick(() => {
            const logo = document.querySelector('.logo');

            if (logo) {
              logo.src = this.getBrandLogo;
            }
          });
        }
      }
    },
  },
  async created() {
      let fetchBalance = async () => {
        if (this.isAuthUser) {
          await this.fetchBalance();
        }
        setTimeout(fetchBalance, 30000);
      };
      fetchBalance();
  },
  mounted() {
    const logo = document.querySelector('.logo');

    if (logo && !logo.getAttribute('src')) {
      logo.src = this.getBrandLogo;
    }
  },
};
</script>

<style lang="scss">
</style>
