<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'Modal',
  data: () => ({
    ok: 'OK',
    isOKClicked: false,
    showIcons: false,
    inputFieldsData: {},
    isClickedCross: false,
  }),
  computed: {
    ...mapGetters({
      modalMessage: 'main/getModalMessage',
      forgotPasswordWait: 'auth/getForgotPasswordWait',
      isForgotInMinutes: 'auth/isForgotInMinutes'
    }),
    /**
     * @return поле text из modalMessage
     */
    getText() {
      return this.modalMessage != null ? this.modalMessage.text : null;
    },
    getTranslatedText() {
      if (this.modalMessage != null && this.modalMessage.level === 'error') {
        return this.$t('modal.modalErrorTitle');
      }
      else if (this.modalMessage != null && this.modalMessage.level === 'info') {
        return this.$t('modal.modalInfoTitle');
      }
      else if (this.modalMessage != null && this.modalMessage.level === 'warning') {
        return this.$t('modal.modalWarningTitle');
      }
      else {
        return null;
      }
    },
    getLevel() {
      return this.modalMessage != null ? this.modalMessage.level : null;
    },
    getIcon() {
      return this.modalMessage != null ? this.modalMessage.icon : null;
    },
    pathToSvg() {
      if (this.getIcon === 'x') {
        return require('@/assets/images/service-components/modal/error_icon.svg');
      } else if (this.getIcon === '!') {
        return require('@/assets/images/service-components/modal/warning_icon.svg');
      } else if (this.getIcon === 'i') {
        return require('@/assets/images/service-components/modal/info_icon.svg');
      } else if (this.getIcon === '?') {
        return require('@/assets/images/service-components/modal/question_icon.svg');
      }

      return null;
    },
    buttonsType() {
      return this.modalMessage != null ? this.modalMessage.buttons : null;
    },
    dbsOkTitle() {
      if (this.buttonsType != null) {
        if (this.buttonsType.split('_')[0] === 'ok' || this.buttonsType ==='ok') {
          return this.modalMessage.hasOwnProperty('accept_button_text') &&
          this.modalMessage.accept_button_text !== '' ? this.modalMessage.accept_button_text : 'Ok';
        } else if (this.buttonsType.split('_')[0] === 'yes') {
          return this.modalMessage.hasOwnProperty('accept_button_text') &&
          this.modalMessage.accept_button_text !== '' ? this.modalMessage.accept_button_text : 'Yes';
        }
      }

      return null;
    },
    dbsTitle() {
      if (this.buttonsType != null) {
        if (this.buttonsType.split('_')[1] === 'cancel') {
          return this.modalMessage.hasOwnProperty('reject_button_text') &&
          this.modalMessage.reject_button_text !== '' ? this.modalMessage.reject_button_text : this.$t('modal.modalCloseButton');
        } else if (this.buttonsType.split('_')[1] === 'no') {
          return this.modalMessage.hasOwnProperty('reject_button_text') &&
          this.modalMessage.reject_button_text !== '' ? this.modalMessage.reject_button_text : 'No';
        }
      }

      return null;
    },
    getInputFieldsBool() {
      return this.modalMessage && this.modalMessage.hasOwnProperty('input_fields') ? true : false;
    },
    getFormTitle() {
      return this.modalMessage != null && this.modalMessage.hasOwnProperty('input_fields') ?
          this.modalMessage['input_fields_title'] : null;
    },
    getFormAcceptButtonLabel() {
      return this.modalMessage != null && this.modalMessage.hasOwnProperty('accept_button_text') ?
          this.modalMessage['accept_button_text'] : 'Ok';
    },
    modalWrapperTopClasses() {
      return {
        'error': this.getLevel === 'error',
        'warning': this.getLevel === 'warning',
        'info': this.getLevel === 'info',
        'question': this.getLevel === 'question',
        'inputs': this.getFormTitle,
        'inputs chg-pass': this.getAcceptFunc === 'chgPass',
      };
    },
    inputFieldsArr() {
      return this.modalMessage != null && this.modalMessage.hasOwnProperty('input_fields') ?
          this.modalMessage['input_fields'] : [];
    },
    inputCodeFieldsArr() {
      return this.modalMessage != null && this.modalMessage.hasOwnProperty('input_code_fields') ?
          this.modalMessage['input_code_fields'] : [];
    },
    getAcceptFunc() {
      return this.modalMessage != null && this.modalMessage.hasOwnProperty('accept_action_func') ?
          this.modalMessage['accept_action_func'] : null;
    },
    getRejectFunc() {
      return this.modalMessage != null && this.modalMessage.hasOwnProperty('reject_action_func') ?
          this.modalMessage['reject_action_func'] : 'close';
    },
    showDoubleButtons() {
      return this.buttonsType === 'ok_cancel' || this.buttonsType === 'yes_no';
    },
    getTitle() {
      return this.modalMessage != null && this.modalMessage.hasOwnProperty('title') ?
          this.modalMessage['title'] : null;
    },
    setClassesFlLabel() {
      return this.getAcceptFunc === 'chgPass' || this.getAcceptFunc === '2FA' || this.getAcceptFunc === 'verifyPhone' || this.getAcceptFunc === 'verifyEmail';
    },
    getShowCodeForm() {
      return this.showCodeForm;
    },
    switchForgotTitle() {
      return Boolean(this.isForgotInMinutes) === false ? this.$t('auth.forgotPasswordSecLabel') : this.$t('funds.fundSmsMinTitle');
    },
  },
  methods: {
    ...mapActions({
      changePassword: 'personal/doChangePassword',
      phoneActivateRequest: 'personal/doPhoneActivateRequest',
      emailActivateRequest: 'personal/doEmailActivateRequest',
      phoneActivateConfirm: 'personal/doPhoneActivateConfirm',
      emailActivateConfirm: 'personal/doEmailActivateConfirm',
      fetchEditProfile: 'personal/fetchEditProfile',
    }),
    ...mapMutations({
      SET_MODAL_MESSAGE: 'main/SET_MODAL_MESSAGE',
      SET_TWOFA: 'auth/SET_TWOFA',
    }),
    closeModal() {
      this.isClickedCross = true;
      this.handleCloseModal();
    },
    logKey(event) {
      if (event.keyCode === 27) {
        this.closeModal();
      }
    },
    handleCloseModal() {
      this.SET_MODAL_MESSAGE(null);
    },
    handleCancelButton() {
      this.handleCloseModal();
    },
    handleDbsOk() {
      if (this.modalMessage.accept_action_func === 'close') {
        this.handleCloseModal();
      }
    },
    handleDbs() {
      if (this.modalMessage.reject_action_func === 'close') {
        this.handleCloseModal();
      }
    },
    handleInput(e, title) {
      this.inputFieldsData[title] = e.target.value;
    },
    /**
     *
     * @param data
     * метод-слушатель для событий компонента Button
    */
    async listenEmitButton(data) {
      if (data) {
        if (typeof data === 'function'){
          await data();
          this.handleCloseModal();
          return;
        }
        switch (data) {
          case 'close': {
            this.handleCloseModal();
            return;
          }

          case 'chgPass': {
            let isOkFlag = true;

            // запускаем валидацию
            let copy = this.inputFieldsArr.slice();

            let oldPass, newPass, rptPass;
            for (const field of copy) {
              let value = this.inputFieldsData[field.name];
              const regexp = field.regexp;

              if (field.name === 'old-password' && value === '') {
                value = null;
              }

              if (field.name === 'old-password') {
                oldPass = value;
              } else if (field.name === 'new-password') {
                newPass = value;
              } else if (field.name === 'repeat-new-password') {
                rptPass = value;
              }

              if (!regexp.test(value) && value != null) {
                if (field.name === 'old-password') {
                  field.errorText = this.$t('profile.changePassword.currPasswordLessMessage');
                } else if (field.name === 'new-password') {
                  field.errorText = this.$t('profile.changePassword.newPasswordLessMessage');
                } else if (field.name === 'repeat-new-password') {
                  field.errorText = this.$t('profile.changePassword.repeatPasswordLessMessage');
                }

                isOkFlag = false;
              } else if (value == null && field.name !== 'old-password') {
                if (field.name === 'new-password') {
                  field.errorText = this.$t('profile.changePassword.newPassEmptyMessage');
                } else if (field.name === 'repeat-new-password') {
                  field.errorText = this.$t('profile.changePassword.repeatPassEmptyMessage');
                }

                isOkFlag = false;
              } else {
                field.errorText = '';
              }
            }

            if (isOkFlag) {
              if (newPass !== rptPass) {
                copy[2].errorText = this.$t('profile.changePassword.repeatPasswordMismatchLabel');
              } else {
                copy[2].errorText = '';

                if (oldPass !== newPass) {
                  await this.changePassword({
                    data: {
                      'password': this.inputFieldsData['old-password'],
                      'new-password': this.inputFieldsData['new-password']
                    }
                  });
                } else {
                  copy[1].errorText = this.$t('profile.changePassword.oldNewPasswordError');

                  this.SET_MODAL_MESSAGE({
                    input_fields_title: this.$t('profile.changePassword.changePasswordLabel'),
                    buttons: 'ok_cancel',
                    title: this.$t('profile.changePassword.changePasswordLabel'),
                    input_fields: copy,
                    accept_button_text: this.$t('profile.changePassword.saveNewPasswordLabel'),
                    accept_action_func: 'chgPass',
                  });
                }
              }
            } else {
              this.SET_MODAL_MESSAGE({
                input_fields_title: this.$t('profile.changePassword.changePasswordLabel'),
                buttons: 'ok_cancel',
                title: this.$t('profile.changePassword.changePasswordLabel'),
                input_fields: copy,
                accept_button_text: this.$t('profile.changePassword.saveNewPasswordLabel'),
                accept_action_func: 'chgPass',
              });
            }
          }

            return;

          case '2FA': {
            let isOkFlag = true;

            // запускаем валидацию
            let copy2FA = this.inputFieldsArr.slice();

            for (const field of copy2FA) {
              let value = this.inputFieldsData[field.name];
              const regexp = field.regexp;

              if (value == '' || value == null) {
                value = null;
                field.errorText = this.$t('auth.twoFAEmptyLabel');
                isOkFlag = false;
              } else if (!regexp.test(value) && value != null) {
                field.errorText = this.$t('auth.twoFALessLabel');
                isOkFlag = false;
              } else {
                field.errorText = '';
              }
            }

            if (this.inputFieldsData.twoFACode == null || this.inputFieldsData.twoFACode.length < 4) {
              isOkFlag = false;
            } else if (this.inputFieldsData.twoFACode.length == 4) {
              isOkFlag = true;
            }

            if (isOkFlag) {
              this.SET_TWOFA(this.inputFieldsData.twoFACode);
              this.closeModal();
            }
            return;
          }

          case 'verifyPhone': {
            let isOkFlag = true;

            // запускаем валидацию
            let copyVerPhone = this.inputFieldsArr.slice();

            for (const field of copyVerPhone) {
              let value = this.inputFieldsData[field.name];
              const regexp = field.regexp;

              if (value == '' || value == null) {
                value = null;
                field.errorText = this.$t('auth.twoFAEmptyLabel');
                isOkFlag = false;
              } else if (!regexp.test(value) && value != null) {
                field.errorText = this.$t('auth.twoFALessLabel');
                isOkFlag = false;
              } else {
                field.errorText = '';
              }
            }

            if (this.inputFieldsData.verifyPhone == null || this.inputFieldsData.verifyPhone.length < 4) {
              isOkFlag = false;
            } else if (this.inputFieldsData.verifyPhone.length == 4) {
              isOkFlag = true;
            }

            if (isOkFlag) {
              await this.phoneActivateConfirm({
                phone: this.modalMessage.params.new_phone,
                code: this.inputFieldsData.verifyPhone
              });
            } else {
              this.SET_MODAL_MESSAGE({
                input_fields_title: this.getTitle,
                buttons: 'ok',
                title: this.getTitle,
                text: this.getTitle == '2FA' ? this.$t('auth.twoFAText') : this.getText,
                input_fields: copyVerPhone,
                accept_button_text: this.getFormAcceptButtonLabel,
                accept_action_func: this.getAcceptFunc,
              });
            }

            return;
          }

          case 'closeVerifyPhone': {
            this.handleCloseModal();
            await this.fetchEditProfile().catch(e => console.error(e));
            return;
          }

          case 'verifyEmail': {
            let isOkFlag = true;

            // запускаем валидацию
            let copyVerEmail = this.inputFieldsArr.slice();

            for (const field of copyVerEmail) {
              let value = this.inputFieldsData[field.name];
              const regexp = field.regexp;

              if (value == '' || value == null) {
                value = null;
                field.errorText = this.$t('auth.twoFAEmptyLabel');
                isOkFlag = false;
              } else if (!regexp.test(value) && value != null) {
                field.errorText = this.$t('auth.twoFALessLabel');
                isOkFlag = false;
              } else {
                field.errorText = '';
              }
            }

            if (this.inputFieldsData.verifyEmail == null || this.inputFieldsData.verifyEmail.length < 4) {
              isOkFlag = false;
            } else if (this.inputFieldsData.verifyEmail.length == 4) {
              isOkFlag = true;
            }

            if (isOkFlag) {
              await this.emailActivateConfirm({
                email: this.modalMessage.params.new_email,
                code: this.inputFieldsData.verifyEmail
              });
            } else {
              this.SET_MODAL_MESSAGE({
                input_fields_title: this.getTitle,
                buttons: 'ok',
                title: this.getTitle,
                text: this.getTitle == '2FA' ? this.$t('auth.twoFAText') : this.getText,
                input_fields: copyVerEmail,
                accept_button_text: this.getFormAcceptButtonLabel,
                accept_action_func: this.getAcceptFunc,
              });
            }

            return;
          }

          case 'closeVerifyEmail': {
            this.handleCloseModal();
            await this.fetchEditProfile().catch(e => console.error(e));
            return;
          }

          case 'changePhone': {
            let isOkFlag = true;

            // запускаем валидацию
            const changePhone = this.inputFieldsArr.slice();

            for (const field of changePhone) {
              let value = this.inputFieldsData[field.name];
              const regexp = field.regexp;

              if (value == '' || value == null) {
                value = null;
                field.errorText = this.$t('auth.invalidPhoneMessage');
                isOkFlag = false;
              } else if (!regexp.test(value)) {
                field.errorText = this.$t('auth.invalidPhoneMessage');
                isOkFlag = false;
              } else {
                field.errorText = '';
              }
            }

            if (isOkFlag) {
              await this.phoneActivateRequest(this.inputFieldsData['change-phone']);
            }
            return;
          }

          case 'confirmPhoneCode':
          case 'confirmEmailCode': {
            let isOkFlag = true;

            // запускаем валидацию
            const confirmPhoneArr = this.inputCodeFieldsArr.slice();

            for (const field of confirmPhoneArr) {
              let value = this.inputFieldsData[field.name];
              const regexp = field.regexp;

              if (value == '' || value == null) {
                value = null;
                field.errorText = this.$t('auth.invalidConfirmCodeMessage');
                isOkFlag = false;
              } else if (!regexp.test(value)) {
                field.errorText = this.$t('auth.invalidConfirmCodeMessage');
                isOkFlag = false;
              } else {
                field.errorText = '';
              }
            }

            if (isOkFlag) {
              if (data=='confirmPhoneCode') {
                await this.phoneActivateConfirm({
                  phone: this.inputFieldsData['change-phone'],
                  code: this.inputFieldsData['confirm-code']
                });
              } else {
                await this.emailActivateConfirm({
                  email: this.inputFieldsData['change-email'],
                  code: this.inputFieldsData['confirm-code']
                });
              }
            }

            return;
          }

          case 'changeEmail': {
            let isOkFlag = true;

            // запускаем валидацию
            const changeEmail = this.inputFieldsArr.slice();

            for (const field of changeEmail) {
              let value = this.inputFieldsData[field.name] != '' ? this.inputFieldsData[field.name].trim() : this.inputFieldsData[field.name];
              const regexp = field.regexp;

              if (value == '' || value == null) {
                value = null;
                field.errorText = this.$t('auth.invalidEmailMessage');
                isOkFlag = false;
              } else if (!regexp.test(value)) {
                field.errorText = this.$t('auth.invalidEmailMessage');
                isOkFlag = false;
              } else {
                field.errorText = '';
              }
            }
            if (isOkFlag) {
              await this.emailActivateRequest(this.inputFieldsData['change-email']);
            }
            return;
          }
        }
      }
    },
    /**
     *
     * метод-обработчик для скрытия/показа пароля
     */
    switchPasswordType(element) {
      let copy = this.inputFieldsArr.slice();

      for (let i = 0; i < copy.length; i++) {
        if (copy[i].name === element) {
          if (copy[i].typeString === 'text') {
            copy[i].it_pass = true;
            copy[i].typeString = 'password';
            copy[i].isOpenIcon = false;
            break;
          } else {
            copy[i].it_pass = false;
            copy[i].typeString = 'text';
            copy[i].isOpenIcon = true;
            break;
          }
        }
      }

      this.SET_MODAL_MESSAGE({
        input_fields_title: this.$t('profile.changePassword.changePasswordLabel'),
        buttons: 'ok',
        title: this.$t('profile.changePassword.changePasswordLabel'),
        input_fields: copy,
        accept_button_text: this.$t('profile.changePassword.saveNewPasswordLabel'),
        accept_action_func: 'chgPass',
      });
    },
    /**
     *
     * @param event метод-обработчик для события фокуса
     */
    // eslint-disable-next-line no-unused-vars
    focusHandler(event, name) {
      let spanNode = this.$refs[`${name}|fls`];
      if (spanNode != null) {
        spanNode[0].classList.add('focused');
      }
    },
    /**
     *
     * @param event метод-обработчик для события снятия фокуса
     */
    // eslint-disable-next-line no-unused-vars
    blurHandler(event, name) {
      let spanNode = this.$refs[`${name}|fls`];

      if (spanNode != null && this.inputFieldsData[name] == null) {
        spanNode[0].classList.remove('focused');
      }
    },
    press(evt) {
      if (evt.key === 'Enter') {
        evt.preventDefault();
        this.listenEmitButton(this.getAcceptFunc);
      }
    },
  },
  watch: {
    showModal(newShowModal, oldShowModal) {
      if (!newShowModal && oldShowModal) {
        this.isOKClicked = false;
      }
    },
  },
  created() {
    window.addEventListener('keydown', this.logKey);
    if (this.modalMessage.hasOwnProperty('input_fields')) {
      for (let field of this.modalMessage.input_fields) {
        if (field.value) {
          this.inputFieldsData[field.name] = field.value;
        }
      }
    }
  },
  beforeDestroy() {
    this.isOKClicked = false;
    this.isWaitClicked = false;
    this.inputFieldsData = null;
    this.SET_MODAL_MESSAGE(null);
    this.handleCloseModal();
    window.removeEventListener('keydown', this.logKey);
  },
};
</script>

<style lang="scss" scoped>
</style>
