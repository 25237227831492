import * as actions from './actions/';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  signinVisible: false,
  signupVisible: false,
  forgotToken: null,
  socials: {},
  twoFA: null,
  authUser: null,
  forgotPasswordWait: 0,
  forgotInMinutes: false
};

export const auth = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
