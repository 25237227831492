import {clientApi} from '@/utils/clientApi';

export const doRedirect = async ({commit}, payload) => {
    try {
        let response = await clientApi('post', 'payment/redirect?trx_id=' + payload.trxId, payload);

        const {data, status} = response;

        if (status === 200) {
            if (data.hasOwnProperty('error') && data.error > 0) {
                if (data.error !== 401) {
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: data.message,
                        accept_action_func: 'close',
                    }, {root: true});
                }
            }
        } else {
            if (data.hasOwnProperty('message')) {
                if (data.error !== 401) {
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: data.message,
                        accept_action_func: 'close',
                    }, {root: true});
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
};
