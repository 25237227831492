<template lang="pug">
    .operations-deposit
        .title(v-if="!hideDepositMethods") {{ $t('profile.payments.paymentMethodLabel') }}
        .title(v-if="isCardsActive") {{ $t('profile.payments.depositCardsLabel') }}
        .title(v-if="isCryptoActive") {{ $t('profile.payments.depositCryptoLabel') }}

        .deposit-methods(v-if="!hideDepositMethods")
            .cards(@click="handleMethod('cards')"
                  :class="{ 'disabled': cardDisabled }")
              .up-head
                span {{ $t('profile.payments.depositCardsLabel') }}

              .pmethods
                .card-icons(v-for="(method, idx) in d_cards" :key="method.id")
                    img.pmethod-image(:src="method.image" alt="No image")

            .crypto(@click="handleMethod('crypto')"
                    :class="{ 'disabled': cryptoDisabled }")
              .up-head
                span {{ $t('profile.payments.depositCryptoLabel') }}

              .pmethods
                .crypto-icons(v-for="method in d_crypto" :key="method.id"
                            :class="{ 'disabled': method.disabled, 'active': method.showMode }")
                  img.pmethod-image(:src="method.image" alt="No image")

            .companies(@click="handleMethod('companies')"
                      :class="{ 'disabled': true }")
              .up-head
                span {{ $t('profile.payments.depositCompanyLabel') }}

              .pmethods.ew
                .ew(:class="{ 'disabled': false, 'active': false }" v-for="(method, idx) in d_e_wallets" :key="method.id")
                  img.ew-image(:src="method.image" alt="No image")

        .deposit-payment(v-if="hideDepositMethods")
            ValidationObserver(ref="depositPayment" v-slot="{ passes }" tag="form")
              form.payment(@submit.prevent="passes(handleDeposit)")
                  .body
                      .body-top
                        .input-wrapper(:class="{ 'cc': isCardsActive }")
                            TextInput(name="summ" :renderedTextLabel="amountPlaceholder"
                                    :rules="getAmountRules" :floatingLabel="true" :renderedTextEwp="getWrongAmountMessage"
                                    @textInputEmit="listenTextInput" :validDiv="false" :invalidDiv="false"
                                    :isOperations="true" refText="summInput" step="0.01")

                      .body-bottom
                        p.fees(v-if="getFee > 0") {{ $t('profile.payments.feePaymentLabel') + ' ' + getFee + getCurrency }}

                        p.agree {{ $t('profile.payments.agree1Label') }}
                        p.agree-link(@click="callPaymentPolicy()") {{ $t('footer.footerPaymentPolicyLabel') }}
                        p.forb {{ isCardsActive ? $t('profile.payments.agree3Label') : "" }}

                        .deposit-btn-wrapper(v-if="!payLink")
                            button.deposit-btn(:class="{ 'disabled': false, 'clicked': isDepositClicked }"
                                        type="submit") {{ $t('profile.payments.depositButton') }}

                        .deposit-pay-link(v-if="payLink")
                            a.link(:href="payLink.url" @click="clickPayLink()") {{  $t('profile.payments.depositPayLink1') + ' ' + $t('profile.payments.depositPayLink2') }}
</template>

<script>
const TextInput = () => import('@/components/serviceComponents/textInput/TextInputSW');

import Deposit from '../Deposit.vue';

export default {
  extends: Deposit,
  components: {
    TextInput,
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/personal/deposit_sw.scss";
</style>