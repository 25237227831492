<template lang="pug">
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'PGFilters',
    data: () => ({
      gameId: null,
      iconsColor: '#ffffff',
    }),
    computed: {
      ...mapGetters({
        list: 'games/getList',
      }),
    },
    methods: {
      ...mapActions({
        doChangeFavorites: 'games/doChangeFavorites',
      }),
      ...mapMutations({
        SET_ADDITIONAL_FILTERS: 'games/SET_ADDITIONAL_FILTERS',
        SET_FAVORITES_FILTER: 'games/SET_FAVORITES_FILTER',
        SET_NEW_FILTER: 'games/SET_NEW_FILTER',
        SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      }),
      /**
       * метод добавления игры в избранное
       */
      async setFavorite() {

      },
      /**
       *
       */
      setFilters(title) {
        switch(title) {
          case 'favorite': {
            this.SET_FAVORITES_FILTER(true);
            this.SET_NEW_FILTER(false);
            this.SET_ADDITIONAL_FILTERS('');
            break;
          }
          case 'new': {
            this.SET_NEW_FILTER(true);
            this.SET_FAVORITES_FILTER(false);
            this.SET_ADDITIONAL_FILTERS('');
            break;
          }
          default: {
            this.SET_FAVORITES_FILTER(false);
            this.SET_NEW_FILTER(false);
            this.SET_ADDITIONAL_FILTERS(title);
            break;
          }
        }
        this.SET_OPEN_PAGE('games');
        this.$router.push('/games');
        window.scrollTo(0, 0);
      },
    },
    mounted() {
      this.gameId = this.$router.history.current.params.string;
    },
};
</script>

<style lang="scss">
</style>