import {clientApi} from '@/utils/clientApi';

export const doSelectLanguage = async ({ rootGetters }, langData) => {
  if (rootGetters['auth/isAuthUser']) {
    try {
      await clientApi('get', `user/select-lang?lang=${langData}`);
    } 
    catch (error) {
      console.log(error);
    }
  }
};
