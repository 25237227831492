<template lang="pug">
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import {timeFormatter} from '@/utils/eventWorkers/timeFormatter';
import {displayCoins} from '@/utils/moneyUtils';


export default {
  name: 'FundOperationsHistory',
  data: () => ({
    limit: 10,
    payments: [],
    displayCoins
  }),
  computed: {
    ...mapGetters({
      activeTab: 'fund/getActiveTab',
      activeFundItem: 'fund/getActiveFundItem',
      fundAccounts: 'fund/getFundAccount',
      accountsHistory: 'fund/getAccountsHistory',
      lang: 'user/getLanguageCode',

    }),
    isCrypto() {
      return this.fundAccounts && this.activeFundItem && this.fundAccounts.hasOwnProperty(this.activeFundItem) && this.fundAccounts[this.activeFundItem].is_crypto;
    },
    getCurrency() {
      return this.activeFundItem && this.fundAccounts.hasOwnProperty(this.activeFundItem) ? this.fundAccounts[this.activeFundItem].currency_code : null;
    },
    account() {
      return this.activeFundItem && this.fundAccounts.hasOwnProperty(this.activeFundItem) ? this.fundAccounts[this.activeFundItem] : null;
    },
  },
  methods: {
    ...mapActions({
      fetchAccountsHistory: 'fund/fetchAccountsHistory',
    }),
    ...mapMutations({
      UPDATE_ACCOUNTS_HISTORY: 'fund/UPDATE_ACCOUNTS_HISTORY'
    }),
    getSortedPayments() {
      if (this.getCurrency && this.accountsHistory.hasOwnProperty(this.getCurrency)) {
        return Object.values(this.accountsHistory[this.getCurrency]).sort((a, b) => b.created_ts - a.created_ts);
      } else {
        return {};
      }
    },
    async loadHistory() {
      await this.load();
    },
    formatDate(createdTs) {
      return timeFormatter(createdTs, this.lang);
    },

    getType(type) {
      return type === 'deposit' ? '+' : '-';
    },
    getLink(value, currency, network, source) {
      if (currency == 'BTC') {
        if (source == 'tx') {
          return `https://explorer.btc.com/btc/transaction/${value}`;
        } else {
          return `https://explorer.btc.com/btc/address/${value}`;
        }
      } else if (currency == 'XMR') {
        if (source == 'tx') {
          return `https://blockchair.com/ru/monero/transaction/${value}`;
        }
      } else if (currency == 'ETH' || (network == 'ethereum' && currency == 'USDT')) {
        if (source == 'tx') {
          return `https://etherscan.io/tx/${value}`;
        } else {
          return `https://etherscan.io/address/${value}`;
        }
      } else if (network == 'tron' && currency == 'USDT') {
        if (source == 'tx') {
          return `https://tronscan.org/#/transaction/${value}`;
        } else {
          return `https://tronscan.org/#/address/${value}`;

        }
      } else {
        return null;
      }
    },
    renderLinkItem(value) {
      return value.substring(0, 10) + '...' + value.substring(value.length - 10, value.length);
    },
    getNetwork(item) {
      return item.hasOwnProperty('params') && item.params && item.params.hasOwnProperty('network') ? item.params.network : '';
    },
    async load() {
      const currency = this.fundAccounts[this.activeFundItem].currency_code;
      let offset = 0;
      if (this.accountsHistory.hasOwnProperty(this.getCurrency)) {
        offset = Object.values(this.accountsHistory[this.getCurrency]).length;
      } else {
        offset = 0;
      }
      await this.fetchAccountsHistory({currency, offset: offset, limit: this.limit});
      this.payments = this.getSortedPayments();
    }
  },
  watch: {
    async activeFundItem(newValue) {
      if (newValue) {
        await this.load();
      }
    }
  },
  async beforeMount() {
    this.$socket.client.on('update-payment', async (data) => {
      this.UPDATE_ACCOUNTS_HISTORY(data);
    });
    await this.load();
  },
};
</script>

<style lang="scss" scoped>
</style>