<template lang="pug">
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import {displayCoins} from '@/utils/moneyUtils';

export default {
  data: () => ({displayCoins}),
  name: 'Exchange',
  computed: {
    ...mapGetters({
      activeTab: 'fund/getActiveTab',
      activeTab2: 'fund/getActiveTab2',
      fundAccount: 'fund/getFundAccount',
      toolsCurrency: 'fund/getExchangeToolsCurrency',
      openBids: 'fund/getOpenBids',
    }),
    currencies() {
      return [
        {code: 'RUB', is_crypto: false, minor_unit: 2},
        {code: 'USD', is_crypto: false, minor_unit: 2},
        {code: 'EUR', is_crypto: false, minor_unit: 2},
        {code: 'USDT', is_crypto: true, minor_unit: 2, crypto_coins_unit: 6},
        {code: 'BTC', is_crypto: true, minor_unit: 8, crypto_coins_unit: 8},
        {code: 'ETH', is_crypto: true, minor_unit: 6, crypto_coins_unit: 18},
        {code: 'XMR', is_crypto: true, minor_unit: 8, crypto_coins_unit: 12}
      ];
    },
    toolsCurrencies() {
      return this.currencies.filter(c => !c.is_crypto);
    },
    buyBids() {
      return this.getBids('buy');
    },
    bestBuy() {
      const buys = this.buyBids;
      let keys = Object.keys(buys);
      return keys.length > 0 ? buys[keys[0]].rate : null;
    },
    sellBids() {
      return this.getBids('sell');
    },
    bestSell() {
      const sells = this.sellBids;
      let keys = Object.keys(sells);
      return keys.length > 0 ? sells[keys[0]].rate : null;
    },
    currencyPairs() {
      let pairs = [];

      for (let currency of this.currencies) {
        if (currency.code != this.toolsCurrency.code && (this.toolsCurrency.is_crypto || currency.is_crypto)) {
          pairs.push({
            id: this.toolsCurrency.code + currency.code,
            title: currency.code + '/' + this.toolsCurrency.code,
            currency
          });
        }
      }

      this.SET_ACTIVE_TAB(pairs[0]);
      return pairs;
    },
    bidMethod() {
      return [
        {id: 'market', title: this.$t('exchange.market')},
        {id: 'limit', title: this.$t('exchange.limit')}
      ];
    }
  },
  methods: {
    ...mapActions({
      fetchFundsAccounts: 'fund/fetchFundsAccounts',
    }),
    ...mapMutations({
      SET_ACTIVE_TAB: 'fund/SET_ACTIVE_TAB',
      SET_ACTIVE_TAB2: 'fund/SET_ACTIVE_TAB2',
      SET_EXCHANGE_TOOLS_CURRENCY: 'fund/SET_EXCHANGE_TOOLS_CURRENCY',
      RECALC_BID: 'fund/RECALC_BID',
    }),
    currencyHandler(currency) {
      this.SET_EXCHANGE_TOOLS_CURRENCY(currency);
    },
    getBids(type) {
      let result;
      if (Object.values(this.openBids) == 0) {
        return {};
      }
      result = Object.values(this.openBids).filter(b => b.type == type
          && b.tools_currency_code == this.toolsCurrency.code
          && b.currency_code == this.activeTab.currency.code).reduce((map, bid) => {
        if (!map.hasOwnProperty(bid.rate)) {
          map[bid.rate] = {rate: parseFloat(bid.rate), amount: 0, bids: []};
        }

        map[bid.rate].amount += parseInt(bid.rest_amount);
        map[bid.rate].price =
            (map[bid.rate].amount / Math.pow(10, this.activeTab.currency.crypto_coins_unit))
            * (bid.rate / Math.pow(10, this.toolsCurrency.minor_unit))
            * Math.pow(10, this.toolsCurrency.minor_unit);
        map[bid.rate].bids.push(bid);
        return map;
      }, {});

      return type === 'sell' ? Object.values(result).sort((a, b) => Number(a.rate) - Number(b.rate))
          : Object.values(result).sort((a, b) => Number(b.rate) - Number(a.rate));
    },
    selectSell(bid) {
      if (this.activeTab2.id !== 'market') {
        this.SET_ACTIVE_TAB2({id: 'market', title: this.$t('exchange.market')});
      }
      this.RECALC_BID({
        type: 'buy',
        amount: parseFloat(this.displayCoins(bid.amount, this.activeTab.currency, false)),
        deviation: 0,
        rate: parseFloat(this.displayCoins(bid.rate, this.toolsCurrency, false)),
        feePercent: 0.25,
        fee: null,
        totalPrice: null,
        bids: bid.bids
      });

    },
    selectBuy(bid) {
      if (this.activeTab2.id !== 'market') {
        this.SET_ACTIVE_TAB2({id: 'market', title: this.$t('exchange.market')});
      }
      this.RECALC_BID({
        type: 'sell',
        amount: parseFloat(this.displayCoins(bid.amount, this.activeTab.currency, false)),
        deviation: 0,
        rate: parseFloat(this.displayCoins(bid.rate, this.toolsCurrency, false)),
        feePercent: 0.25,
        fee: null,
        totalPrice: null,
        bids: bid.bids
      });
    }
  },
  async beforeMount() {
    await this.fetchFundsAccounts();
    this.SET_EXCHANGE_TOOLS_CURRENCY({code: 'RUB', is_crypto: false, minor_unit: 2});
    this.SET_ACTIVE_TAB2({id: 'market', title: this.$t('exchange.market')});
  },
};
</script>

<style lang="scss">
</style>