let pinTimerId = null;

export const SET_FORGOT_PASSWORD_WAIT = (state, payload) => {
    let { forgotPasswordWait } = state;
    let minutes = null;
    forgotPasswordWait = payload;

    if (!pinTimerId){
        clearInterval(pinTimerId);
    }

    if (forgotPasswordWait > 60) {
        if (Boolean(state.forgotInMinutes) === false) {
            state.forgotInMinutes = true;
        }

        minutes = Math.floor(forgotPasswordWait / 60);
        state.forgotPasswordWait = minutes;

        pinTimerId = setInterval(() => {
            if (minutes !== 0) {
                minutes -= 1;
                state.forgotPasswordWait = minutes;
            }
        }, 60000);

        if (forgotPasswordWait === 0) {
            clearInterval(pinTimerId);
            state.forgotPasswordWait = 0;
            state.forgotInMinutes = false;
        }
    } else {
        state.forgotPasswordWait = forgotPasswordWait;

        pinTimerId = setInterval(() => {
            if (forgotPasswordWait !== 0) {
                forgotPasswordWait -= 1;
                state.forgotPasswordWait = forgotPasswordWait;
            }
        }, 1000);

        if (forgotPasswordWait === 0) {
            clearInterval(pinTimerId);
            state.forgotPasswordWait = 0;
        }
    }

    payload = null;
};
