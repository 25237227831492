<template lang="pug">
.funds-container
    .funds-container-middle
        section.funds-info
            .funds-item(v-for="fund in fundAccount" :key="fund.currency_code" :class="{ 'active': fund.id === activeFundItem }" @click="selectFund(fund.id)")
                .funds-item-top
                    .fund-left-wrapper
                        .funds-item-logo-wrapper
                            .fund-item-logo
                                img(v-if="fund.currency_code === 'USDT' || fund.currency_code === 'USDTRX'" :src="require('@/assets/images/fund/usdt.png')")
                                img(v-if="fund.currency_code === 'EUR'" :src="require('@/assets/images/fund/eur.svg')")
                                img(v-if="fund.currency_code === 'RUB'" :src="require('@/assets/images/fund/rub.svg')")
                                img(v-if="fund.currency_code === 'USD'" :src="require('@/assets/images/fund/usd.svg')")
                                img(v-if="fund.currency_code === 'XMR'" :src="require('@/assets/images/fund/monero.svg')")
                                img(v-if="fund.currency_code === 'ETH'" :src="require('@/assets/images/fund/eth.svg')")
                                img(v-if="fund.currency_code === 'BTC'" :src="require('@/assets/images/fund/btc.svg')")
                                img(v-if="fund.currency_code === 'DAI'" :src="require('@/assets/images/fund/dai.svg')")
                        .funds-item-balance-wrapper
                            .funds-item-balance {{ displayCoins(fund.value, fund.currency) }}
                .funds-item-bottom
                  .usd-sum(v-show="fund.usd_sum>0") {{ '~$' + roundFloat(fund.usd_sum, currencies['USD'].minor_unit) }}

        section.funds-operations-container
            .funds-tab-wrapper
              TabSW(tabType='funds' :tabsArray='compoundTabsArray')

            .operations-container.deposit(v-if="getActiveTabDeposit")
                FundDepositSW

            .operations-container.withdrawal(v-if="getActiveTabWithdrawal")
                FundWithdrawalSW

            .history-container(v-if="getActiveTabHistory")
                FundOperationsHistorySW
</template>

<script>
import Fund from './Fund.vue';
const FundOperationsHistorySW = () => import('@/components/fund/fund_components/FundOperationsHistorySW');
const FundOperationsSW = () => import('@/components/fund/fund_components/FundOperationsSW');
const FundDepositSW = () => import('@/components/fund/fund_components/FundDepositSW');
const FundWithdrawalSW = () => import('@/components/fund/fund_components/FundWithdrawalSW');
const TabSW = () => import('@/components/serviceComponents/TabSW');

export default {
    name: 'FundSW',
    extends: Fund,
    components: {
        FundDepositSW,
        FundOperationsHistorySW,
        FundOperationsSW,
        FundWithdrawalSW,
        TabSW,
    },
};
</script>

<style lang="scss">
@import "@/assets/styles/fund/fund_sw.scss";
</style>