import { clientApi } from '@/utils/clientApi';

export const fetchFundsAccounts = async ({commit}) => {
    let response = null;

    try {
        response = await clientApi('get', 'user/get-accounts?fullObjects=1');
    } catch (error) {
        console.log(error);
        throw error;
    }

    if (response != null) {
        const {error, data} = response.data;
        if (error === 0) {
            const accountById = data.reduce(function (map, obj) {
                map[obj.id] = obj;
                return map;
            }, {});

            commit('SET_FUNDS_ACCOUNTS', accountById);
        }

        response = null;
    }
};