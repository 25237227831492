import {clientApi} from '@/utils/clientApi';

export const fetchPayments = async ({rootGetters, state}, limit) => {
    let response;
    let currentLang = rootGetters['user/getLanguage'];
    if (!limit) {
        limit = 20;
    }

    try {
        response = await clientApi('get',
            `payment/history?lang=${currentLang}&limit=${limit}&type=${state.paymentsType}`);
        const {count, data} = response.data;
        const mobileData = response.data['data-mobile'];
        state.payments = data;
        state.paymentsMobile = mobileData;
        state.paymentsCount = count;
    } catch (error) {
        console.log(error);
    }
};
