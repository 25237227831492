import {clientApi} from '@/utils/clientApi';

export const doSendSmsCode = async ({commit}) => {
    let response = null;

    try {
        response = await clientApi('get', 'payment/send-sms-code');
    } catch (error) {
        console.log(error);
        throw error;
    }

    if (response != null) {
        const {error, message} = response.data;
        if (error === 0) {
            if (message) {
                commit('main/SET_MODAL_MESSAGE', {
                    level: 'info',
                    icon: 'i',
                    buttons: 'ok',
                    text: message,
                    accept_action_func: 'close',
                }, {root: true});
            }
            return {success: true};
        } else {
            if (message) {
                if (error !== 401) {
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: message,
                        accept_action_func: 'close',
                    }, {root: true});
                }
            }
            let sec = 0; //
            if(error === 1 && response.data.data.sec !== undefined){
                sec = response.data.data.sec;
            }
            return {success: false, sec: sec};
        }
    }
};