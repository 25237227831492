<template lang="pug">
  .filters-container
    .top-item(@click="setFilters('favorite')")
      TopFavoritesSVG(:color="iconsColor")
      span.fav {{ $t('games.favoritesButton') }}
    .top-item(@click="setFilters('new')")
      TopNewSVG(:color="iconsColor")
      span.newg {{ $t('games.newGamesLabel') }}
    .top-item(@click="setFilters('Slots')")
      TopSlotsSVG(:color="iconsColor")
      span.slt {{ $t('games.slotsLabel') }}
    .top-item(@click="setFilters('Blackjack')")
      TopPokersSVG(:color="iconsColor")
      span.pok {{ $t('games.blackjackLabel') }}
    .top-item(@click="setFilters('Bingo')")
      TopJackpotsSVG(:color="iconsColor")
      span.jp {{ $t('games.bingoLabel') }}
    .top-item(@click="setFilters('Roulette')")
      TopRoulettesSVG(:color="iconsColor")
      span.roul {{ $t('games.rouletteLabel') }}
    .top-item(@click="setFilters('Mini games')")
      TopMiniGamesSVG(:color="iconsColor")
      span.migames {{ $t('games.miniGamesLabel') }}
</template>

<script>
const TopFavoritesSVG = () => import('@/components/serviceComponents/svg/personalGame/TopFavorites.vue');
const TopNewSVG = () => import('@/components/serviceComponents/svg/personalGame/TopNew.vue');
const TopSlotsSVG = () => import('@/components/serviceComponents/svg/personalGame/TopSlots.vue');
const TopPokersSVG = () => import('@/components/serviceComponents/svg/personalGame/TopPokers.vue');
const TopMiniGamesSVG = () => import('@/components/serviceComponents/svg/personalGame/TopMiniGames.vue');
const TopRoulettesSVG = () => import('@/components/serviceComponents/svg/personalGame/TopRoulettes.vue');
const TopJackpotsSVG = () => import('@/components/serviceComponents/svg/personalGame/TopJackpots.vue');

import PGFilters from './PGFilters';

export default {
    extends: PGFilters,
    components: {
      TopFavoritesSVG,
      TopJackpotsSVG,
      TopMiniGamesSVG,
      TopNewSVG,
      TopPokersSVG,
      TopRoulettesSVG,
      TopSlotsSVG,
    }
};
</script>

<style lang="scss">
@import '@/assets/styles/games/personal/pgfilters_sw.scss';
</style>