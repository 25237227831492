<template lang="pug">
  .modal-wrapper
    .modal-wrapper-top(:class="modalWrapperTopClasses")
      img.logo-top(v-show="getIcon" :src="pathToSvg")
      .modcsoff-wrapper(@click="closeModal" :class="{ 'modal-verify': getAcceptFunc == 'verifyPhone' }")
        CrossShadowOff(:isClickedCross="isClickedCross" :width="16" :height="16")

    .modal-wrapper-content(v-show="!getInputFieldsBool" :class="{ 'restricted': getLevel == 'error' || getLevel == 'warning' }")
      .nm-title {{ getTranslatedText }}
      span.contarr(v-if="Array.isArray(getText)")
        span(v-for="(msg, index) in getText" :key="index") {{ msg }}
      span(v-if="!Array.isArray(getText)") {{ getText }}

    .modal-wrapper-form(v-show="getInputFieldsBool")
      .nm-title {{ getTitle }}

      span(v-if="getAcceptFunc == '2FA' || 'verifyPhone'") {{ getText }}
      form.form-wrapper
        .field-wrapper(v-for="(item, idx) in inputFieldsArr" :key="idx")
          span.error(v-show="item.errorText") {{ item.errorText }}
          input(:type="item.typeString" :ref="item.name" :name="item.name"
            autocomplete="off" :id="item.name" v-model="inputFieldsData[item.name]"
            :class="{ 'hd-pass': item.it_pass }"
            @keypress="press($event)"
            @input="handleInput($event, item.name)"
            @focus="focusHandler($event, item.name)"
            @blur="blurHandler($event, item.name)" :readonly="modalMessage.isShowCode")
          span.floating-label(:ref="`${item.name}|fls`"
                              :class="{ 'chg-pass': setClassesFlLabel, 'focused':inputFieldsData[item.name] && inputFieldsData[item.name].length>0 }") {{ item.placeholder }}
          .opcl-container(v-if="item.hasOwnProperty('isOpenIcon')")
            img.eye(v-if="item.isOpenIcon" :src="require('@/assets/images/auth/eye.svg')"
              @click="switchPasswordType(item.name)")
            img.cl-eye(v-else :src="require('@/assets/images/auth/closed_eye.svg')"
              @click="switchPasswordType(item.name)")

    .modal-wrapper-button(v-show="buttonsType !== ''" :class="{ 'inputs': getInputFieldsBool }")
      Button.ok(:title="dbsOkTitle" :funcToBeCalled="getAcceptFunc" @emitButton="listenEmitButton")
      Button.dbs(v-if="showDoubleButtons" :title="dbsTitle" :funcToBeCalled="getRejectFunc" @emitButton="listenEmitButton")

    .modal-wrapper-form.code(v-if="modalMessage.isShowCode")
      span {{ modalMessage.notifyText }}
      form.form-wrapper
        .field-wrapper(v-for="(item, idx) in inputCodeFieldsArr" :key="idx")
          span.error(v-show="item.errorText || modalMessage.confirmError") {{ item.errorText ? item.errorText: modalMessage.confirmError}}
          input(:type="item.typeString" :ref="item.name" :name="item.name"
            autocomplete="off" :id="item.name" v-model="inputFieldsData[item.name]"
            @keypress="press($event)"
            @input="handleInput($event, item.name)"
            @focus="focusHandler($event, item.name)"
            @blur="blurHandler($event, item.name)")
          span.floating-label(:ref="`${item.name}|fls`"
                              :class="{ 'chg-pass': setClassesFlLabel }") {{ item.placeholder }}
          .forgot(v-if="forgotPasswordWait <= 0"
                    @click="listenEmitButton('changeEmail')") {{ $t('auth.repeatCodeLink') }}
          .send-sms(v-if="forgotPasswordWait > 0")
              span.send-sms__title {{ $t('auth.forgotCodeAgainLabel') }}
              span.send-sms__timer {{ forgotPasswordWait }} {{ switchForgotTitle }}

    .modal-wrapper-button.code(v-if="modalMessage.isShowCode" :class="{ 'inputs': getInputFieldsBool }")
      Button.ok(title="Ok" :funcToBeCalled="modalMessage.confirm_action_func"
        @emitButton="listenEmitButton")
</template>

<script>

import Button from '@/components/serviceComponents/ButtonSW';
import Modal from './Modal.vue';
const CrossShadowOff = () => import('@/components/serviceComponents/svg/CrossShadowOff');

export default {
  extends: Modal,
  components: {
    Button,
    CrossShadowOff,
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/serviceComponents/modal_sw.scss";
</style>
