<template lang="pug">
  svg#vk(width='48' height='47' viewBox='0 0 48 47' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFillFirst' d='M42.0273 0H5.02734C2.26592 0 0.0273438 2.23858 0.0273438 5V42C0.0273438 44.7614 2.26592 47 5.02734 47H42.0273C44.7888 47 47.0273 44.7614 47.0273 42V5C47.0273 2.23858 44.7888 0 42.0273 0Z')
    path(:fill='changeFillSecond' fill-rule='evenodd' clip-rule='evenodd' d='M39.6284 14.7617C39.1786 14.4349 37.5667 14.4349 35.9699 14.4349C34.3402 14.4679 32.7099 14.4679 32.3272 14.615C31.9791 14.7452 31.1975 16.6088 30.1834 18.5212C29.0185 20.6803 27.6055 22.7723 26.7071 22.7383C25.5266 22.7383 25.8098 19.1913 25.8925 16.5604C25.9082 14.843 25.626 14.2872 25.3763 14.1411C24.9477 13.8451 23.9959 13.5574 23.4571 13.4099C23.1969 13.3387 23.2762 13.3201 23.007 13.3041C19.8903 13.1188 19.5217 16.8154 19.3801 19.9343C19.3572 20.4375 19.3171 20.9942 19.2573 21.6101C19.2076 21.938 18.4757 24.6842 16.1146 20.9725C15.5156 20.0421 13.2551 14.4673 12.4567 14.4349C9.84475 14.3855 7.25179 14.2712 6.3545 14.7617C5.50583 15.2527 7.48477 19.0771 10.8275 24.2113C13.5373 28.4284 16.4146 31.698 21.4028 32.5147C22.3509 32.6141 24.7785 32.7102 25.5607 32.3516C26.0758 32.1066 25.4271 28.4772 27.2066 28.1169C27.9882 27.9697 31.0304 31.91 32.1774 32.3516C32.8775 32.6295 34.4067 32.7437 35.9694 32.6773C37.6656 32.6295 39.5932 32.842 39.7764 31.698C40.1927 29.1962 34.6397 25.9266 34.4894 24.3904C34.3558 23.2793 36.518 21.2356 37.6153 19.6658C38.9786 17.7693 40.4765 15.4812 39.6284 14.7617Z')
</template>

<script>
export default {
  name: 'VK',
  props: {
    isClicked: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    changeFillFirst() {
      if (this.isClicked) {
        return '#ffffff';
      } else {
        return '#4A9eba';
      }
    },
    changeFillSecond() {
      if (this.isClicked) {
        return '#079fd1';
      } else {
        return '#ffffff';
      }
    },
  },
};
</script>