<template lang="pug">
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {currencyFormat} from '@/utils/moneyUtils';

export default {
  name: 'LastNews',
  data: () => (
    {
      moneyCurency: convertCurrencyToSymbol,
      moneyFormat: currencyFormat,
      //Выбранная обложка
      gameSelectIndex: -1,
      //Отображаемый список
      games: [],
      list: [],
      //Анимация
      is_anim: false
    }
  ),

  computed: {
    ...mapGetters(
      {
        userLanguageCode: 'user/getLanguageCode',
      }
    ),
    isMobile() {
      return window.innerWidth < 1280;
    },
  },

  mounted() {
    this.fetch();
  },

  methods: {
    ...mapActions(
      {
        fetchLastWins: 'games/fetchLastWins',
        doChangeFavorites: 'games/doChangeFavorites',
        fetchGameUrl: 'games/fetchGameUrl',
      }
    ),
    ...mapMutations(
      {
        SET_DEMO_RUN_GAME: 'games/SET_DEMO_RUN_GAME',
        SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
        SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE'
      }
    ),
    async fetch() {
      this.list = await this.fetchLastWins();
      setTimeout(
        () => {
          this.fetch();
        },
        30000
      );
      this.add_row();
    },

    add_row ( cb )
      {
        let get_row = false;

        get_row = this.get_row();
        if (get_row !== false)
          {
            this.games.splice(0, 0, get_row);
            this.games.splice(5);
            this.is_anim = true;
          }
        if (get_row !== false)
          setTimeout(
            () => {
              this.is_anim = false;
              this.add_row(cb);
            },
            400
          );
        else if (typeof cb === 'function')
          cb();
      },

    get_row() {
      let out = false;
      let id_first = 0;
      if (this.games.length>0)
        id_first = this.games[0].id;
      let find = false;
      const len = this.list.length;
      for (let i=len-1; i>=0; i--)
        {
          if (id_first === 0)
            return this.list[i];
          if (this.list[i].id === id_first)
            find = true;
          else if (id_first === 0 || find === true)
            {
              out = this.list[i];
              break;
            }
        }
      return out;
    },

    url_icon (game) {
      let out = 'game-icons/no_cover.svg';
      if (game.image_file) {
        try {
          out = 'game-icons/' + game.image_file;
        } catch (e) {
          console.log('Cover not find: ', game.image_file);
        }
      } else if (game.aggregator && game.aggregator === 'softswiss') {
        return game.aggregator_params.game_icon.url + game.provider + '/' + (game.provider_id.split(':')[1]) + '.' + game.aggregator_params.game_icon.format;
      }
      return out;
    },

    getTitle(card) {
      let s = 'game_' + this.userLanguageCode;
      return (typeof card[s] === 'string' && card[s]!=='' ? card[s] : card['game_en']);
    },

    getText(card) {
      return (typeof card['text_' + this.userLanguageCode] === 'string' ? card['text_' + this.userLanguageCode] : '');
    },

    async doOpenGameUrl(gameId, isDemo = false) {
      if (isDemo === false && !this.isAuthUser) {
        this.SET_SIGNIN_VISIBLE(true);
        return;
      }
      const url = await this.fetchGameUrl({gameId, demo: isDemo, homeButton: true, mobile: this.isMobile});

      if (url.length > 0) {
        window.location.href = url;
        if (this.$metrika && isDemo === false) {
          this.$metrika.reachGoal('gameRun');
        }
      }
    },

    async setFavorite(gameId, isFavorite) {
      await this.doChangeFavorites(
          {
            id: gameId,
            value: isFavorite
          }
      );

      for (let game of this.games){
        if (game.game_id === gameId) {
          game.game_is_favorite = isFavorite;
          break;
        }
      }
    },

    //Обработчик выбора обложки
    selectGame(gameId = 0, isDemo = false, index = -1) {
      if (!this.isMobile) {
        if (isDemo) {
          this.SET_DEMO_RUN_GAME(true);
        }
        this.doOpenGamePage(gameId);
      } else {
        if (this.gameSelectIndex !== index) {
          this.gameSelectIndex = index;
        } else {
          if (!isDemo && this.$metrika) {
            this.$metrika.reachGoal('gameRun');
          }
          this.doOpenGameUrl(gameId, isDemo);
        }
      }
    },

    async doOpenGamePage(gameId) {
      if (!this.isMobile) {
        this.SET_OPEN_PAGE('personalGame');
        await this.$router.push(`/game?id=${gameId}`);
        window.scrollTo(0, 0);
      }
    }
  }
};
</script>

<style lang="scss">
</style>