import {clientApi} from '@/utils/clientApi';

export const doChangeFavorites = async (ctx, payload) => {
    try {
        let response = await clientApi(
            'get',
            'game/favorites-' + (payload.value === true ? 'add' : 'delete') + '?gameId=' + payload.id,
            {
                gameId: payload.id
            }
        );
        if (response) {
            const {error} = response.data;
            if (error === 0) {
                ctx.commit('SET_LIST_ITEM', {
                        id: payload.id,
                        value: {
                            is_favorite: payload.value
                        }
                    }
                );
            } else {
                console.log(error);
            }
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};