import {clientApiAuth} from '@/utils/clientApiAuth';
import Vue from 'vue';
// import i18n from '@/i18n';

export const doPhoneActivateRequest = async ({commit, rootGetters, dispatch}, phone) => {
    let response, error = 0;

    try {
        response = await clientApiAuth('post', 'profile/phone-activate-request', {'phone': phone});

        const {message} = response.data;

        if (response.data.hasOwnProperty('error')) {
            error = response.data.error;
        }

        if (error !== 0) {
            if (error !== 401) {
                commit('main/SET_MODAL_MESSAGE', {
                    level: 'error',
                    icon: 'x',
                    buttons: 'ok',
                    text: message,
                    accept_action_func: 'close',
                }, {root: true});
            }
        } else {
            dispatch('user/fetchProfile', null, { root: true });

            let modalMessage = rootGetters['main/getModalMessage'];
            Vue.set(modalMessage,'notifyText', message);
            Vue.set(modalMessage,'isShowCode', true);
            Vue.set(modalMessage,'buttons', '');


            commit('main/SET_MODAL_MESSAGE', modalMessage, {root: true});
        }
    } catch (error) {
        console.log(error);
    }
};
