import * as actions from './actions/';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    //object: id, title
    activeTab: null,
};

export const exchangeHistory = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};