<template lang="pug">
  .toast-sw(data-autohide='false' :class="toastClassMerger")
    .toast-sw-header
      strong.mr-auto.text-primary
      small.text-muted
      button.ml-2.mb-1.close(type='button' data-dismiss='toast' @click="closeToast") ×
    .toast-sw-body {{ getMessage }}
</template>

<script>

import {mapMutations} from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {displayCoins} from '@/utils/moneyUtils';

export default {
  name: 'ToastSW',
  data: () => ({
    currencyFormat: convertCurrencyToSymbol,
    moneyFormat: displayCoins,
  }),
  props: {
    notification: {
      type: Object
    },
    index: {
      type: Number,
      default: 0,
    }
  },
  computed: {
    toastClassMerger() {
      return {
        'show': this.notification.message !== '',
      };
    },
    getMessage() {
      let message = this.$t(`toast.${this.notification.message}`);
      if (this.notification.params) {
        for (let paramName of Object.keys(this.notification.params)) {
          let paramValue = this.notification.params[paramName];
          if (paramName === 'currency') {
            paramValue = this.currencyFormat(paramValue);
          } else if (paramName === 'amount') {
            paramValue = this.moneyFormat(paramValue.value, paramValue.currency_separator, paramValue.currency_minor_unit);
          }
          message = message.replace(`{${paramName}}`, paramValue);
        }
      }
      return message;
    },
  },
  methods: {
    ...mapMutations({
      DELETE_SOCKET_NOTIFICATION: 'personal/DELETE_SOCKET_NOTIFICATION'
    }),
    closeToast() {
      this.DELETE_SOCKET_NOTIFICATION(this.notification.id);
      this.notification.status = 'read';
      this.$socket.client.emit('updateNoticeStatus', this.notification);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/serviceComponents/toast_sw.scss';
</style>