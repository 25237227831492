<template lang="pug">
  svg#iug_moneyGame.icon(width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M7.133 5.724L3.149 5.73C2.516 5.73 2.001 5.215 2.001 4.582C2.001 3.949 2.516 3.434 3.149 3.434H4.142V3.953C4.142 4.506 4.59 4.954 5.142 4.954C5.694 4.954 6.142 4.506 6.142 3.953V3.434H9.284C9.284 3.434 10.284 3.434 10.284 2.413C10.284 1.5 9.284 1.434 9.284 1.434H6.142V1C6.142 0.448 5.694 0 5.142 0C4.59 0 4.142 0.448 4.142 1V1.434H3.149C1.413 1.434 0.000999928 2.846 0.000999928 4.582C0.000999928 6.318 1.414 7.73 3.151 7.73L7.136 7.724C7.769 7.724 8.284 8.239 8.284 8.872C8.284 9.505 7.768 10.019 7.136 10.019H6.142V9.48C6.142 8.927 5.695 8.479 5.142 8.479C4.59 8.479 4.142 8.927 4.142 9.48V10.019H1C1 10.019 0 10.059 0 11.039C0 12.019 1 12.019 1 12.019H4.142V12.432C4.142 12.985 4.59 13.433 5.142 13.433C5.695 13.433 6.142 12.985 6.142 12.432V12.019H7.135C8.871 12.019 10.283 10.607 10.283 8.872C10.284 7.136 8.871 5.724 7.133 5.724Z')
</template>

<script>
import IUG_icon from './IUG_icon.vue';

export default {
  name: 'IUG_MoneyGameSVG',
  components: { IUG_icon },
  extends: IUG_icon,
};
</script>