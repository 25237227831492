<template lang="pug">
  ValidationProvider.ti-wrapper(tag="div" :rules="rules" :skipIfEmpty="skipIfEmpty"
                                v-slot="{ errors, required, failedRules }" :vid="vid"
                                :disabled="disabledProps")
    label.ti-wrapper__label(v-show="!floatingLabel" :for="name" :class="tiClassMerger") {{ renderedTextLabel }}
    input(v-if="!hasVMask" :type="typeString" :ref="refText" :name="name"
           autocomplete="off" :id="name" v-model="inputValue"
           @input="emitInputValue" :placeholder="placeholder"
           @focus="focusHandler($event)" @blur="blurHandler($event)"
           :class=`{'error': isError || errors[0], 'readonly': readonly}`
           @keypress="press($event)" @paste="press($event)"
           :readonly="readonly")
    input(v-if="hasVMask" :type="typeString" :ref="refText" :name="name"
           autocomplete="off" :id="name" v-model="inputValue"
           v-mask="maskTemplate" @focus="focusHandler($event)" @blur="blurHandler($event)"
           @input="emitInputValue" :placeholder="placeholder"
           :class=`{ 'error': isError || (errors[0] && failedRules.hasOwnProperty('required') || failedRules.hasOwnProperty('minBwana')
                    || failedRules.hasOwnProperty('maxBwana') || failedRules.hasOwnProperty('luhn') || failedRules.hasOwnProperty('length')
                    || failedRules.hasOwnProperty('cryptoMinWithdrawal') || failedRules.hasOwnProperty('cryptoMaxWithdrawal')
                    || failedRules.hasOwnProperty('minWithdrawal') || failedRules.hasOwnProperty('maxWithdrawal')
                    || failedRules.hasOwnProperty('cardholderLength')) }`
           @keypress="press($event)" @paste="press($event)" :readonly="readonly")
    span.floating-label(:ref="`${name}|fls`" v-show="floatingLabel"
                        :class="{ 'amount': name === 'summ' }") {{ renderedTextLabel }}
    .valid(v-if="hasValue && !errors[0] && validValue && validDiv")
    .invalid(v-if="errors[0] && invalidDiv")
    span.e-wp(v-if="errors[0]" :class="tiClassMerger") {{ renderedTextEwp }}
    span.e-lp(v-if="errors[0] && failedRules.hasOwnProperty('confirmed') || failedRules.hasOwnProperty('maxBwana')"
               :class="tiClassMerger") {{ renderedTextElp }}
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'TextInput',
  props: {
    name: {
      type: String,
      default: '',
    },
    renderedTextLabel: {
      type: String,
      default: '',
    },
    renderedTextEwp: {
      type: String,
      default: '',
    },
    renderedTextElp: {
      type: String,
      default: '',
    },
    refText: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String, Array],
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    typeString: {
      type: String,
      default: 'text',
    },
    step: {
      type: String,
      default: '',
    },
    validDiv: {
      type: Boolean,
      default: true,
    },
    invalidDiv: {
      type: Boolean,
      default: true,
    },
    isOperations: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasVMask: {
      type: Boolean,
      default: false,
    },
    maskTemplate: {
      type: String,
      default: '',
    },
    clearFields: {
      type: Boolean,
      default: false,
    },
    floatingLabel: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: [String, Number, null],
      default: null,
    },
    skipIfEmpty: {
      type: Boolean,
      default: false,
    },
    disabledProps: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
      required: false
    },
    availableSymbols: {
      type: Array,
      default: () => [],
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
  },
  data: () => ({
    inputValue: '',
  }),
  computed: {
    ...mapGetters({
      brand: 'main/getBrand',
    }),
    hasValue() {
      return !!this.inputValue;
    },
    validValue() {
      if (this.name === 'old-password' || this.name === 'new-password' || this.name === 'new-repeat-new-password') {
        return this.inputValue.length >= 7;
      }

      return true;
    },
    tiClassMerger() {
      return {
        'operations': this.isOperations,
        'dep': this.name === 'summ',
      };
    },
  },
  methods: {
    ...mapMutations({
    }),
    emitInputValue() {
      this.$emit('textInputEmit', {name: this.name, value: this.inputValue});
    },
    /**
     *
     * @param event метод-обработчик для события фокуса
     */
    // eslint-disable-next-line no-unused-vars
    focusHandler(event) {
      let spanNode = this.$refs[`${this.name}|fls`];

      if (spanNode != null) {
        spanNode.classList.add('focused');
      }
    },
    /**
     *
     * @param event метод-обработчик для события снятия фокуса
     */
    // eslint-disable-next-line no-unused-vars
    blurHandler(event) {
      let spanNode = this.$refs[`${this.name}|fls`];

      if (spanNode != null && (this.inputValue == null || this.inputValue == '')) {
        spanNode.classList.remove('focused');
      }
    },
    press(evt) {
      if (evt instanceof KeyboardEvent) {
        const keyPressed = evt.key;
        if (this.availableSymbols.length > 0 && !this.availableSymbols.includes(keyPressed)) {
          evt.preventDefault();
        }
      } else if (evt instanceof ClipboardEvent) {
        {
          const pasteText = evt.clipboardData.getData('text');
          if (this.availableSymbols.length > 0) {
            for (let char of pasteText) {
              if (!this.availableSymbols.includes(char)) {
                evt.preventDefault();
                return;
              }
            }
          }
        }
      }
    }
  },
  watch: {
    clearFields(newVal) {
      if (newVal) {
        this.inputValue = '';
      }
    },
    inputValue(newInputVal) {
      if (newInputVal != null) {
        this.focusHandler();
      }

      //try to clear value for amount
      if (this.name === 'bid_amount' && typeof newInputVal === 'string' && newInputVal.length > 1) {
        if (newInputVal != null && !newInputVal.includes('.') && newInputVal.includes('0')) {
          if (newInputVal.startsWith('00') && newInputVal.length === 2) {
            this.inputValue = 1;
          }
          if (newInputVal.startsWith('00') && newInputVal.length > 2) {
            this.inputValue = newInputVal.substring(2);
          }
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    defaultValue(newVal) {
        this.$nextTick(() => {
          this.inputValue = this.defaultValue ? this.defaultValue : '';
        });
    },
  },
  mounted() {
    if (this.name === 'old-password') {
      this.$nextTick(() => {
        if (this.typeString === 'text') {
          const oldPass = document.querySelector('#old-password');

          if (oldPass != null) {
            oldPass.style.fontFamily = 'Montserrat';
          }
        } else {
          const oldPass = document.querySelector('#old-password');

          if (oldPass != null) {
            oldPass.style.fontFamily = 'password';
          }
        }
      });
    }

    if (this.name === 'new-password') {
      this.$nextTick(() => {
        if (this.typeString === 'text') {
          const newPass = document.querySelector('#new-password');

          if (newPass != null) {
            newPass.style.fontFamily = 'Montserrat';
          }
        } else {
          const newPass = document.querySelector('#new-password');

          if (newPass != null) {
            newPass.style.fontFamily = 'password';
          }
        }
      });
    }

    if (this.name === 'repeat-new-password') {
      this.$nextTick(() => {
        if (this.typeString === 'text') {
          const repeatPass = document.querySelector('#repeat-new-password');

          if (repeatPass != null) {
            repeatPass.style.fontFamily = 'Montserrat';
          }
        } else {
          const repeatPass = document.querySelector('#repeat-new-password');

          if (repeatPass != null) {
            repeatPass.style.fontFamily = 'password';
          }
        }
      });
    }

    if (this.name === 'username' || this.name === 'fname' || this.name === 'lname' || this.name === 'country' ||
        this.name === 'city' || this.name === 'address' || this.name === 'epPhone' || this.name === 'epEmail' ||
        this.name === 'state' || this.name === 'postCode') {
      this.$nextTick(() => {
        this.inputValue = this.defaultValue;
      });
    }
  },
  beforeDestroy() {
    this.inputValue = '';
  },
};
</script>

<style lang="scss" scoped>
</style>