<template lang="pug">
  .security
    .overlay
    .security-data
      .sec-head
        .csoff-wrapper(@click="edCrossHandler")
          img(:src="require('@/assets/images/personal/sunduk-white/cross_starz57.svg')" alt="cross")
        span.sec-repl {{ $t('profile.breadcrumb.securityLabel') }}

      .sec-body
        .top-section-wrapper
          .toggles
            .form-radio-lgns(@click="checkRadioBtn('logins')")
              .lab-logins-wrapper
                input(type="radio" id="logins" v-model="userData.login_notify")
                label.lab-logins
              span {{ $t('profile.security.radioLoginsLabel') }}

            .form-radio-ips(@click="checkRadioBtn('ips')")
              .lab-ips-wrapper
                input(type="radio" id="ips" v-model="userData.ip_notify")
                label.lab-ips
              span {{ $t('profile.security.radioIPsLabel') }}

            .dfa-switcher(@click="checkRadioBtn('dfa')")
              .dfa-wrapper
                input.dfa-toggle(type="checkbox" id="dfa" v-model="userData.auth_2f")
                label.lab-dfa
              span {{ $t('profile.security.dualFactorAuthLabel') }}

        .sec-btn-wrapper
          .sec-btn(:class="{ 'disabled': false, 'clicked': isButtonClicked }"
                  @click="saveSecurityHandler") {{ $t('profile.editProfile.editProfileButton') }}
          .chpass(@click="switchChgPass" :class="{'selected': isChpassClicked}") {{ $t('profile.changePassword.changePasswordLabel') }}
</template>

<script>
const CrossShadowOff = () => import('@/components/serviceComponents/svg/CrossShadowOff');

import Security from '../Security.vue';

export default {
  extends: Security,
  components: {
    CrossShadowOff,
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/personal/security_sw.scss";
</style>
