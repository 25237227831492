import Vue from 'vue';
import Vuex from 'vuex';

import {chat} from '@/stores/modules/chat';

import {main} from '@/stores/modules/main';
import {auth} from '@/stores/modules/auth';
import {user} from '@/stores/modules/user';
import {personal} from '@/stores/modules/personal';
import {pay} from '@/stores/modules/pay';
import {games} from '@/stores/modules/games';
import {fund} from '@/stores/modules/fund';
import {exchangeHistory} from '@/stores/modules/exchangeHistory';


Vue.use(Vuex);

export default new Vuex.Store({
    strict: false,
    modules: {
        chat,
        main,
        auth,
        user,
        personal,
        pay,
        games,
        fund,
        exchangeHistory,
    }
});
