import moment from 'moment';
import { translations } from '../mixins';

/**
 *
 * @param {*} dateTimeValue
 * @param {*} lang, 'fr'
 * @param {*} timezoneFlag optional, type = Boolean
 * @param {*} isLine optional, type = Boolean
 *
 * This function accept timestamp argument and optional argument timezoneFlag.
 * If timezoneFlag is true, return combination for BetsHistory table.
 * Otherwise, return values for setLineData mutations.
 * Return formated time
 */
export const timeFormatter = (dateTimeValue, lang, timezoneFlag = false, isLine = false) => {
  let result = null;

  if (dateTimeValue && dateTimeValue !== '-:-' && dateTimeValue !== '-') {
    if (typeof dateTimeValue === 'object' && /\n/.test(dateTimeValue.timeBefore)) {
      let resultArr = [];
      const dateTimesArray = dateTimeValue.timeBefore.split('\n');

      for (const item of dateTimesArray) {
        const dateInMilliseconds = new Date(item * 1000);
        moment.locale(lang);
        const parsedDateTime = moment(dateInMilliseconds, 'YYYYMMDD').format('LL');
        const now = moment(new Date(), 'YYYYMMDD').format('LL');
        const dateMonthNow = `${now.split(' ')[0]} ${now.split(' ')[1]}`;
        const dateMonthResult = `${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]}`;
        const parsedTime = moment(dateInMilliseconds).format();
        const preResultTime = parsedTime.split('T')[1].split('+')[0].split(':');

        if (dateMonthNow === dateMonthResult) {
          if (lang === 'ru' || lang === 'ru-RU') {
            resultArr.push(`${translations.data().locales[1].ru.timeFormatters.today} ${preResultTime[0]}:${preResultTime[1]}`);
          }
          else if (lang === 'en' || lang === 'en-EN') {
            resultArr.push(`${translations.data().locales[0].en.timeFormatters.today} ${preResultTime[0]}:${preResultTime[1]}`);
          }
          else if (lang === 'fr' || lang === 'fr-FR') {
            resultArr.push(`${translations.data().locales[2].fr.timeFormatters.today} ${preResultTime[0]}:${preResultTime[1]}`);
          }
        } 
        else if (Number(parsedDateTime.split(' ')[0]) - Number(now.split(' ')[0]) === 1) {
          if (lang === 'ru' || lang === 'ru-RU') {
            resultArr.push(`${translations.data().locales[1].ru.timeFormatters.tomorrow} ${preResultTime[0]}:${preResultTime[1]}`);
          }
          else if (lang === 'en' || lang === 'en-EN') {
            resultArr.push(`${translations.data().locales[0].en.timeFormatters.tomorrow} ${preResultTime[0]}:${preResultTime[1]}`);
          }
          else if (lang === 'fr' || lang === 'fr-FR') {
            resultArr.push(`${translations.data().locales[2].fr.timeFormatters.tomorrow} ${preResultTime[0]}:${preResultTime[1]}`);
          }
        }
         else if (Number(parsedDateTime.split(' ')[0]) - Number(now.split(' ')[0]) === -1) {
          if (lang === 'ru' || lang === 'ru-RU') {
            resultArr.push(`${translations.data().locales[1].ru.timeFormatters.yesterday} ${preResultTime[0]}:${preResultTime[1]}`);
          }
          else if (lang === 'en' || lang === 'en-EN') {
            resultArr.push(`${translations.data().locales[0].en.timeFormatters.yesterday} ${preResultTime[0]}:${preResultTime[1]}`);
          }
          else if (lang === 'fr' || lang === 'fr-FR') {
            resultArr.push(`${translations.data().locales[2].fr.timeFormatters.yesterday} ${preResultTime[0]}:${preResultTime[1]}`);
          }
        } 
        else {
          if (lang === 'ru' || lang === 'ru-RU') {
            resultArr.push(`${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]} ${translations.data().locales[1].ru.timeFormatters.in} ${preResultTime[0]}:${preResultTime[1]}`);
          }
          else if (lang === 'en' || lang === 'en-EN') {
            resultArr.push(`${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]} ${translations.data().locales[0].en.timeFormatters.in} ${preResultTime[0]}:${preResultTime[1]}`);
          }
          else if (lang === 'fr' || lang === 'fr-FR') {
            resultArr.push(`${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]} ${translations.data().locales[2].fr.timeFormatters.in} ${preResultTime[0]}:${preResultTime[1]}`);
          }
        }
      }

      result = resultArr;
    } 
    else if (typeof dateTimeValue === 'object' && !/\n/.test(dateTimeValue.timeBefore)) {
      const dateInMilliseconds = new Date(dateTimeValue.timeBefore * 1000);
      moment.locale(lang);
      const parsedDateTime = moment(dateInMilliseconds, 'YYYYMMDD').format('LL');
      const now = moment(new Date(), 'YYYYMMDD').format('LL');
      const dateMonthNow = `${now.split(' ')[0]} ${now.split(' ')[1]}`;
      const dateMonthResult = `${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]}`;
      const parsedTime = moment(dateInMilliseconds).format();
      const preResultTime = parsedTime.split('T')[1].split('+')[0].split(':');

      if (dateMonthNow === dateMonthResult) {
        if (lang === 'ru' || lang === 'ru-RU') {
          result = `${translations.data().locales[1].ru.timeFormatters.today} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'en' || lang === 'en-EN') {
          result = `${translations.data().locales[0].en.timeFormatters.today} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'fr' || lang === 'fr-FR') {
          result = `${translations.data().locales[2].fr.timeFormatters.today} ${preResultTime[0]}:${preResultTime[1]}`;
        }
      } 
      else if (Number(parsedDateTime.split(' ')[0]) - Number(now.split(' ')[0]) === 1) {
        if (lang === 'ru' || lang === 'ru-RU') {
          result = `${translations.data().locales[1].ru.timeFormatters.tomorrow} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'en' || lang === 'en-EN') {
          result = `${translations.data().locales[0].en.timeFormatters.tomorrow} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'fr' || lang === 'fr-FR') {
          result = `${translations.data().locales[2].fr.timeFormatters.tomorrow} ${preResultTime[0]}:${preResultTime[1]}`;
        }
      }
       else if (Number(parsedDateTime.split(' ')[0]) - Number(now.split(' ')[0]) === -1) {
        if (lang === 'ru' || lang === 'ru-RU') {
          result = `${translations.data().locales[1].ru.timeFormatters.yesterday} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'en' || lang === 'en-EN') {
          result = `${translations.data().locales[0].en.timeFormatters.yesterday} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'fr' || lang === 'fr-FR') {
          result = `${translations.data().locales[2].fr.timeFormatters.yesterday} ${preResultTime[0]}:${preResultTime[1]}`;
        }
      } 
      else {
        result = `${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]} в ${preResultTime[0]}:${preResultTime[1]}`;
      }
    }
    else if (typeof dateTimeValue === 'number' || typeof dateTimeValue === 'string' && !(/\:/).test(dateTimeValue)) {
      const dateInMilliseconds = new Date(dateTimeValue * 1000);
      moment.locale(lang);
      const parsedDateTime = moment(dateInMilliseconds, 'YYYYMMDD').format('LL');
      const now = moment(new Date(), 'YYYYMMDD').format('LL');
      const dateMonthNow = `${now.split(' ')[0]} ${now.split(' ')[1]}`;
      const dateMonthResult = `${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]}`;
      const parsedTime = moment(dateInMilliseconds).format();
      const preResultTime = parsedTime.split('T')[1].split('+')[0].split(':');

      if (dateMonthNow === dateMonthResult) {
        if (lang === 'ru' || lang === 'ru-RU') {
          result = `${translations.data().locales[1].ru.timeFormatters.today} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'en' || lang === 'en-EN') {
          result = `${translations.data().locales[0].en.timeFormatters.today} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'fr' || lang === 'fr-FR') {
          result = `${translations.data().locales[2].fr.timeFormatters.today} ${preResultTime[0]}:${preResultTime[1]}`;
        }
      }
      else if (Number(parsedDateTime.split(' ')[0]) - Number(now.split(' ')[0]) === 1) {
        if (lang === 'ru' || lang === 'ru-RU') {
          result = `${translations.data().locales[1].ru.timeFormatters.tomorrow} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'en' || lang === 'en-EN') {
          result = `${translations.data().locales[0].en.timeFormatters.tomorrow} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'fr' || lang === 'fr-FR') {
          result = `${translations.data().locales[2].fr.timeFormatters.tomorrow} ${preResultTime[0]}:${preResultTime[1]}`;
        }
      }
      else if (Number(parsedDateTime.split(' ')[0]) - Number(now.split(' ')[0]) === -1) {
        if (lang === 'ru' || lang === 'ru-RU') {
          result = `${translations.data().locales[1].ru.timeFormatters.yesterday} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'en' || lang === 'en-EN') {
          result = `${translations.data().locales[0].en.timeFormatters.yesterday} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'fr' || lang === 'fr-FR') {
          result = `${translations.data().locales[2].fr.timeFormatters.yesterday} ${preResultTime[0]}:${preResultTime[1]}`;
        }
      }
      else {
        if (lang === 'ru' || lang === 'ru-RU') {
          result = `${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]} ${translations.data().locales[1].ru.timeFormatters.in} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'en' || lang === 'en-EN') {
          result = `${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]} ${translations.data().locales[0].en.timeFormatters.in} ${preResultTime[0]}:${preResultTime[1]}`;
        }
        else if (lang === 'fr' || lang === 'fr-FR') {
          result = `${parsedDateTime.split(' ')[0]} ${parsedDateTime.split(' ')[1]} ${translations.data().locales[2].fr.timeFormatters.in} ${preResultTime[0]}:${preResultTime[1]}`;
        }
      }
    }
    else if (typeof dateTimeValue === 'string' && (/\:/).test(dateTimeValue)) {
      result = dateTimeValue;
    }
  }
  else if (dateTimeValue && dateTimeValue === '-:-' && isLine) {
    if (lang === 'ru' || lang === 'ru-RU') {
      result = `${translations.data().locales[1].ru.timeFormatters.matchStarted}`;
    }
    else if (lang === 'en' || lang === 'en-EN') {
      result = `${translations.data().locales[0].en.timeFormatters.matchStarted}`;
    }
    else if (lang === 'fr' || lang === 'fr-FR') {
      result = `${translations.data().locales[2].fr.timeFormatters.matchStarted}`;
    }
  }
  else if (dateTimeValue && dateTimeValue === '-:-' && !isLine || dateTimeValue && dateTimeValue === '-' && !isLine) {
    result = null;
  }
  else if (timezoneFlag && !dateTimeValue) {
    return 'live';
  }
  else if (typeof dateTimeValue === 'string' && dateTimeValue === '') {
    result = '-:-';
  }

  return result;
};
