import {clientApi} from '@/utils/clientApi';

export const doSaveEditProfile = async ({commit}, payload) => {
    let response;

    try {
        response = await clientApi('post', 'profile/save-profile', payload);
        const {error, message} = response.data;

        if (error === 0) {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'info',
                icon: 'i',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        } else {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        }
    } catch (error) {
        console.log(error);
    }

    //clear
    response = null;
};
