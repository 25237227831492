import {clientApi} from '@/utils/clientApi';

export const fetchAccountsHistory = async ({commit}, payload) => {
    let response = null;
    const {currency, offset, limit} = payload;

    try {
        response = await clientApi('get',
            `payment/history-for-currency?currency=${currency}&offset=${offset}&limit=${limit}`);
    } catch (error) {
        console.log(error);
        throw error;
    }

    if (response != null) {
        const {error, data} = response.data;
        if (error === 0) {
            const paymentById = data.reduce(function (map, obj) {
                map[obj.id] = obj;
                return map;
            }, {});

            commit('SET_ACCOUNTS_HISTORY', {currency, payments: paymentById});
        }

        response = null;
    }
};