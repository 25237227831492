<template lang="pug">
  aside.wrapper

    .account-info-wrapper(v-if="userProfile.phone")
      span.account-type {{ $t('profile.breadcrumb.phoneLabel') }}
      span.account-uniq {{ userProfile.phone_hidden }}
    .account-info-wrapper(v-else="userProfile.email")
      span.account-type {{ $t('profile.breadcrumb.emailLabel') }}
      span.account-uniq {{ userProfile.email_hidden }}

    ul.u-bredcrumb
      li.u-bredcrumb-item(v-for="(crumb, index) in crumbArray" :key="index"
        :class="{ active: crumb.clicked }")
        .li-row(v-show="crumb.show" @click="switchActiveTab(index)" :class="{ expanded: isExpanded }")
          .li-row__title
            span {{ $t(crumb.title) }}

        ul.sub-u-bredcrumb(:class="{ 'expand': crumb.isSubMenuOpen }")
          li
            .sub-u-bredcrumb__item(v-for="(sub, innerIndex) in crumb.submenu" :key="innerIndex"
                                  :class="{ 'inactive': !sub.active, 'clicked': isSelected(sub.id) }"
                                  @click="selectSubmenu(sub, innerIndex, index)")
              span {{ $t(sub.title) }}

      li.u-bredcrumb-item
        ul.sub-u-bredcrumb.expand
          li.sub-u-bredcrumb__item.exit(v-if="isAuthUser" :class="{ 'clicked': isClickedExit, 'disabled': false }" @click="logoutHandler")
             span {{ $t('headerNew.logoutButton') }}
</template>

<script>
import Breadcrumb from '../Breadcrumb.vue';

export default {
  extends: Breadcrumb,
};
</script>

<style lang="scss">
@import "@/assets/styles/personal/bredcrumb_sw.scss";
</style>
