<template lang="pug">
  svg#topSlots.icon(width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M12.7991 0.583557H10.0273C9.5382 0.583557 9.21211 1.08356 8.96754 1.83356C8.88601 1.83356 8.72297 1.75022 8.64145 1.75022L6.03271 1.66689C5.38052 1.66689 4.80986 2.33356 4.48377 3.25022L2.1196 3.16689C0.896754 3.16689 0 5.41689 0 7.58356C0 9.75023 0.815231 12.0002 2.03808 12.0002L4.32072 12.0836C4.56529 12.0836 4.89138 11.9169 5.05443 11.7502V11.6669C5.299 11.9169 5.62509 12.0836 5.95118 12.0836L8.55992 12.1669C8.88601 12.1669 9.13058 12.0002 9.37515 11.8336C9.5382 12.0836 9.78277 12.1669 10.0273 12.1669C10.9241 12.1669 11.9024 12.1669 12.3915 12.1669H12.7991C14.3481 12.1669 14.3481 7.00022 14.3481 6.41689C14.3481 5.83356 14.3481 0.583557 12.7991 0.583557ZM4.40225 11.3336C3.99463 11.2502 3.09788 10.0002 3.09788 7.66689C3.09788 5.66689 3.75006 4.41689 4.15768 4.08356C3.99463 4.91689 3.91311 5.91689 3.91311 6.83356C3.91311 8.41689 4.2392 10.0002 4.72834 11.0002C4.56529 11.2502 4.48377 11.3336 4.40225 11.3336ZM8.64145 11.2502C8.23383 11.1669 7.4186 9.66689 7.4186 6.91689C7.4186 4.16689 8.23383 2.66689 8.64145 2.58356C8.72297 2.58356 8.72297 2.66689 8.80449 2.66689C8.4784 4.16689 8.4784 6.00022 8.4784 6.33356C8.4784 6.75022 8.4784 9.33356 9.04906 10.9169C8.88601 11.0836 8.72297 11.2502 8.64145 11.2502ZM12.9622 10.6669C12.6361 10.6669 12.31 8.75022 12.31 6.41689C12.31 4.08356 12.5546 2.16689 12.9622 2.16689C13.3698 2.16689 13.6144 4.08356 13.6144 6.41689C13.5328 8.75022 13.2883 10.6669 12.9622 10.6669Z')
</template>

<script>
export default {
  name: 'TopSlotsSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#94b8af',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>