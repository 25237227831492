import {clientApi} from '@/utils/clientApi';

// eslint-disable-next-line no-unused-vars
export const fetchGameListFast = async ({commit, state}, payload) => {

    const params =
        {
            search_string: payload,
            page: 1,
            limit: 10,
        };

    try {
        let response = await clientApi(
            'post',
            'game/list',
            {search: params}
        );
        if (response) {
            const {data, error, search} = response.data;
            let count = 0;
            if (error === null) {
                if (typeof search.count === 'number') {
                    count = search.count;
                }

                commit('games/SET_FAST_SEARCH_RESULTS', { data, count }, {root: true});
                // return {data, count};
            }
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};