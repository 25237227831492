<template lang="pug">
  .dropdown-wrapper(:class="{}")
    .dropdown(:class="dropdownClassMerger"
      @click="expandDropdown" v-click-outside="outsideClick")

      slot(name="date-picker")
      slot(name="month-picker")
      slot(name="year-picker")
      slot(name="timezone")
      slot(name="currency")
      slot(name="country-picker")

    .dd-search-input-wrapper(v-show="hasSearch && expandAccount")
      input.dd-search-input(ref="ddSearchInput" v-model="currencyQuery" @input="handleCurrencySearch")

    .dropdown-cell(:class="{ expanded: expandAccount, 'expanded-dd': open, 'with-search': hasSearch }")
      .dropdown-cell__item(:class="{ 'expanded__item': open }" v-for="(item, index) in dItemArray"
        :key="index" @click="currentRowHandler(item)")
        span.icell(:class="{ 'active': item[1] }") {{ renderCellItem(item) }}
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'Dropdown',
  components: {},
  props: {
    itemArray: {
      type: [Array, Object]
    },
    dropdownType: {
      type: String,
    },
    open: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    expandAccount: false,
    coloredStar: false,
    ddValue: null,
    dItemArray: [],
    currencyQuery: '',
  }),
  computed: {
    ...mapGetters({
      currentLanguage: 'user/getLanguage',
    }),
    cMaxLengthSearch() {
      let maxLength;
      if(typeof this.itemArray[0] === 'string') {
        maxLength = this.itemArray[0];
      } else if (typeof item === 'number') {
        maxLength = this.itemArray[0];
      } else {
        maxLength = this.itemArray[0].title;
      }

      this.itemArray.forEach((item) => {
        if (typeof item === 'string') {
          if (item.length > maxLength.length) {
            maxLength = item;
          }
        } else if (typeof item === 'number') {
          if (String(item).length > maxLength.length) {
            maxLength = String(item);
          }
        } else{
          if (item.title.length > maxLength.length) {
            maxLength = item.title;
          }
        }
      });

      return this.hasSearch ? maxLength.length : 0;
    },
    dropdownClassMerger() {
      return {
        'datepicker': this.dropdownType === 'datepicker',
        'error': this.hasError,
      };
    },
  },
  mounted(){
    this.dItemArray = this.itemArray;
  },
  methods: {
    currentRowHandler(item) {
      this.ddValue = item;
      this.$emit('emitDDItem', item);
    },
    findSimilarElements(arr, searchString) {
      let similar = [];
      arr.forEach((item) => {

        if (!item.hasOwnProperty('title') && item.toLowerCase().includes(searchString.toLowerCase())) {
          similar.push(item);
        } else if (item.hasOwnProperty('title') && item.title.toLowerCase().includes(searchString.toLowerCase())) {
            similar.push(item);
        }
      });
      return similar;
    },
    expandDropdown() {
      this.expandAccount = !this.expandAccount;

      if (this.currencyQuery === '' && this.expandAccount) {
        this.dItemArray = this.itemArray;
      }

      this.$emit('toggleDropdown', this.expandAccount);
    },
    outsideClick(e) {
      if (this.expandAccount) {
        if (!e.target.classList.contains('dd-search-input')) {
          this.expandAccount = false;
          this.currencyQuery = '';
          this.$emit('emitCurrencySearch', this.currencyQuery);
        }

        this.$emit('toggleDropdown', this.expandAccount);
      }
    },
    renderCellItem(item) {
      if (typeof item === 'object') {
        return item.title;
      }
      return item;
    },
    //метод обработки события ввода для поиска по списку валют
    handleCurrencySearch(e) {
      if(e.target.value.length > this.cMaxLengthSearch) {
        this.currencyQuery = e.target.value.slice(0, this.cMaxLengthSearch);
      }
      this.dItemArray = this.findSimilarElements(this.itemArray, String(e.target.value));
      this.$emit('emitCurrencySearch', e.target.value);
    },
  },
  watch: {
    expandAccount(newVal) {
      if (newVal && this.hasSearch) {
        const ddsi = this.$refs.ddSearchInput;

        if (ddsi != null) {
          this.$nextTick(() => {
            ddsi.focus();
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
