<template lang="pug">
.fund-withdrawal-wrapper
    .for-title {{ showWithdrawalTitle }}

    .form-withdrawal-wrapper
        form(ref='withdrawalForm' @submit.prevent="doWithdrawal")
          .line(v-if="currency.is_crypto")
            .network-wrapper(v-if="cryptoWallets && cryptoWallets.length>1")
              .networks(v-for="cryptoWallet of cryptoWallets")
                input(type="radio" name="network" :id="cryptoWallet.network" v-model="withdrawalData.network" v-bind:value="cryptoWallet.network")
                label(:for="cryptoWallet.network") {{ cryptoWallet.network }}

          .line(v-if="currency.is_crypto")
            TextInput(name="cryptoAddress" :renderedTextLabel="$t('funds.addressTitle')"
              :defaultValue="withdrawalData.cryptoAddress"
              :floatingLabel="false" :disabledProps="true"
              @textInputEmit="listenTextInput" :validDiv="false"
            )
          .line
            TextInput(name="totalAmount" :renderedTextLabel="$t('funds.amountWriteOffTitle')"
              :defaultValue="withdrawalData.displayTotalAmount"
              :floatingLabel="false" :disabledProps="true"
              @textInputEmit="listenTextInput" :validDiv="false"
              :availableSymbols="['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']"
            )
          .line
            TextInput(name="fee" :renderedTextLabel="$t('funds.commissionTitle')"
              :defaultValue="withdrawalData.displayFee"
              :floatingLabel="false" :disabledProps="true"
              @textInputEmit="listenTextInput" :validDiv="false"
              readonly=true
            )
          .line
            TextInput(name="amount" :renderedTextLabel="$t('funds.fundWithSummTitle')"
              :defaultValue="withdrawalData.displayAmount"
              :floatingLabel="false" :disabledProps="true"
              @textInputEmit="listenTextInput" :validDiv="false"
              readonly=true
            )
          .line
            TextInput(name="smsCode"
              :renderedTextLabel="$t('funds.smsTitle') + withdrawalData.codePlaceholder"
              :defaultValue="withdrawalData.smsCode"
              :floatingLabel="false" :disabledProps="true"
              @textInputEmit="listenTextInput" :validDiv="false"
              :readonly="withdrawalData.codeDisabled"
            )
          button(:class="{'disable': isSubmitDisable}") {{  withdrawalData.buttonText }}

          .s-code(v-if="withdrawalData.buttonLockTime > 0 && withdrawalData.buttonAction == 'sendSms'")
            div {{ $t('funds.sendSmsFundTitle') }} {{ withdrawalData.buttonLockTime + $t('funds.fundSmsSecTitle') }}
          .s-code(v-else)
            div <br><br>

    .operations-info(v-html="description")

    .min-max-wrapper(v-if="paymentMethod")
      tr
        span {{ $t('profile.payments.minPaymentLabel') +': ' + min }}
      tr
        span {{ $t('profile.payments.maxPaymentLabel') +': ' + max }}
      tr
        span {{ $t('profile.payments.feePaymentLabel') +': ' + fee }}
</template>

<script>
import FundWithdrawal from './FundWithdrawal.vue';
const TextInput = () => import('@/components/serviceComponents/textInput/TextInputEXW');


export default {
  name: 'FundWithdrawalSW',
  extends: FundWithdrawal,
  components: {
    TextInput,
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/fund/fund-withdrawal-sw.scss";
</style>