<template lang="pug">
  .sup-signin-nh-wrapper
    VueRecaptcha(v-if="isSigninVisible && !isRemindPassword" ref="recaptchaLog" size="invisible" :sitekey="siteKey"
                    @verify="nhSignin" @expired="onCaptchaExpired" badge="bottomright"
                    :loadRecaptchaScript="true" render="explicit")

    VueRecaptcha(v-if="isSignupVisible" ref="recaptchaReg" size="invisible" :sitekey="siteKey"
                @verify="nhSignup" @expired="onCaptchaExpired" badge="bottomright"
                :loadRecaptchaScript="true")

    VueRecaptcha(v-if="isRemindPassword || !isForgot" ref="recaptchaPass" size="invisible" :sitekey="siteKey"
                @verify="sendPassword" @expired="onCaptchaExpired" badge="bottomright"
                :loadRecaptchaScript="true")

    VueRecaptcha(v-if="isForgot" ref="recaptchaForgot" size="invisible" :sitekey="siteKey"
                @verify="changePassword" @expired="onCaptchaExpired" badge="bottomright"
                :loadRecaptchaScript="true")

    .signin-nh-wrapper
      .head
        .head__close(@click="closeForm")
          CrossShadowOff(:isClickedCross="isClickedCross" :width="16" :height="16")

      .middle
        form.auth(:class="{ 'reg': isSignupVisible }")
          .active-link-wrapper(:class="{ 'rem': isRemindPassword }")
            .login-wrapper(v-if="!isForgot" @click="switchAuthForm('login')")
              Button(:isActive="isSigninVisible"
                    :title="$t('auth.signinLabel')")

            .signup-wrapper(v-if="!isForgot" @click="switchAuthForm('signup')")
              Button(:isActive="isSignupVisible"
                    :title="$t('auth.signupLabel')")

          //.tab-switcher(v-if="!isExBrand && !isForgot")
          //  .phone-title(:class="{ 'active': tabSwitcher.isPhoneActive }"
          //              @click="tabSwitcherHandler('phone')") {{ $t('auth.phoneLabel') }}
          //  .email-title(:class="{ 'active': tabSwitcher.isEmailActive }"
          //              @click="tabSwitcherHandler('email')") {{ $t('auth.emailLabel') }}


          .cont(:class="{ 'reg': isSignupVisible }")
            .logemph(v-show="tabSwitcher.isPhoneActive && !isForgot" :class="{ 'sign': isSignupVisible }")
              span.phone-error(v-if="phoneError") {{ $t('auth.invalidPhoneMessage') }}
              input.phone(ref="phone" id="phone" name="phone" type="text" v-model="userPhoneNumber" autocomplete="off"
                          @keypress="keypressPhone" @blur="blurPhone"
                          @input="inputPhone"
                          :placeholder="phonePlaceholder"
                          :class="{ 'error': !checkPhone }")

            .email-wrapper(v-show="tabSwitcher.isEmailActive && !isForgot" :class="{ 'reg': isSignupVisible }")
              span.email-error(v-if="!checkEmail") {{ $t('auth.invalidEmailMessage') }}
              input.email(ref="email" id="email" name="email" type="text" v-model="userData.email" autocomplete="off"
                          :placeholder="$t('auth.emailPlaceholder')"
                          @blur="blurEmail"
                          :class="{ 'error': !checkEmail }")

            .rem-title-wrapper(v-show="isForgot")
              .rem-title {{ $t('auth.setNewPasswordLabel') }}

            .pass(:class="{ 'reg': isSignupVisible }")
              span.pass-error(v-if="passwordError") {{  passwordError }}
              input.password-nh(ref="password" type="password" v-model="userData.password" autocomplete="off"
                            :placeholder="$t('auth.passwordPlaceholder')"
                            @keypress="keypressPassword" :class="{ 'pswd-error': passwordError }")
              img.eye(v-if="isEyeOpen" :src="require('@/assets/images/auth/eye.svg')" @click="switchPasswordType()")
              img.cl-eye(v-else :src="require('@/assets/images/auth/closed_eye.svg')" @click="switchPasswordType()")

            .pass(v-show="!isSigninVisible" :class="{ 'reg': isSignupVisible }")
              span.pass-error(v-if="repeatPasswordError") {{ repeatPasswordError }}
              input.password-nh(ref="rpt-password" type="password" v-model="userData.rptpassword" autocomplete="off"
                            :placeholder="$t('auth.repeatPasswordPlaceholder')"
                            @keypress="keypressRptPassword" :class="{ 'pswd-error': repeatPasswordError && checkEmail && !phoneError && !passwordError}")
              img.eye(v-if="isEyeOpenRpt" :src="require('@/assets/images/auth/eye.svg')" @click="switchPasswordType('rpt')")
              img.cl-eye(v-else :src="require('@/assets/images/auth/closed_eye.svg')" @click="switchPasswordType('rpt')")

            .tos-wrapper(v-show="isSignupVisible && !isRemindPassword"
              :class="{ 'pswd-error': !isToSValid && checkEmail && !phoneError && !passwordError && !repeatPasswordError}")
              Checkbox(name="tosCheckbox" :title="$t('auth.confirmTermsLabel')" textColor="black"
                      :links="[$t('auth.termsAndCondLinkLabel'), $t('auth.andLabel'), $t('auth.privacyConditionLabel')]"
                      @eventValue="listenCheckbox" :isTos="true")

            .offers-wrapper(v-show="isSignupVisible && !isRemindPassword")
              Checkbox(name="offersCheckbox" :title="$t('auth.confirmPromoNotifyLabel')"
                      @eventValue="listenCheckbox" textColor="black")

          .attention(v-if="!isRemindPassword")
            p.att-title1(v-if="!isRemindPassword") {{ $t('auth.signupAttention1Label') }}
            p.att-title2(v-if="!isRemindPassword") {{ $t('auth.signupAttention2Label') }}

          .auth-buttons
            .signin-ab-wrapper(v-if="isSigninVisible")
              Button.signup-ab(funcToBeCalled="applyCallSigninCheck"
                    :title="$t('auth.signinButton')"
                    :isActive="isSigninABClicked"
                    @emitButton="listenBtnEvent")

            .signup-ab-wrapper(v-if="isSignupVisible")
              Button.signup-ab(funcToBeCalled="applyCallSignupCheck"
                    :title="$t('auth.signupButton')"
                    :isActive="isSignupABClicked"
                    @emitButton="listenBtnEvent")

            .signup-ab-wrapper(v-if="isForgot")
              Button.signup-ab(funcToBeCalled="applyCallChangePassCheck"
                    :title="$t('auth.changePassButton')"
                    :isActive="isSignupABClicked"
                    @emitButton="listenBtnEvent")

            .forgot(v-if="isSigninVisible && forgotPasswordWait <= 0"
                    @click="showForgotData") {{ $t('auth.forgotLink') }}

            .send-sms(v-if="forgotPasswordWait > 0")
              span.send-sms__title {{ $t('auth.forgotPasswordAgainLabel') }}
              span.send-sms__timer {{ forgotPasswordWait }} {{ switchForgotTitle }}

          .snet-wrapper(v-if="!isForgot && getSocials.length>0")
            .snet-wrapper__title {{ $t('auth.loginWithLabel') }}

            .social
              .social__item(v-for="(item, index) of getSocials" :key="index"
                            :class="[{'clicked': socialsClick[index], 'disabled': item.disabled}, `${item.title}`]"
                            @click="callSocial(index)")
                component.sicon(:is="getImgForSocial(index)" :isClicked="socialsClick[index]")
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import {extend} from 'vee-validate';

const CrossShadowOff = () => import('@/components/serviceComponents/svg/CrossShadowOff');
const Button = () => import('@/components/serviceComponents/ButtonSW');
const Checkbox = () => import('@/components/serviceComponents/CheckboxSW');
const TextInput = () => import('@/components/serviceComponents/textInput/TextInputSW');
const Dropdown = () => import('@/components/serviceComponents/dropdown/DropdownSW');
import Signin from './Signin.vue';

extend('minFour', value => {
  return value.length >= 4;
});

export default {
  extends: Signin,
  components: {
    Button,
    Checkbox,
    CrossShadowOff,
    Dropdown,
    TextInput,
    VueRecaptcha,
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/auth/signin_sw.scss";
</style>