import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    balance: {value: null, currency: null, minor_unit: 0, separator: '.', is_crypto: false},
    profile: {
        birth_date: null,
        email: null,
        fio: null,
        id: null,
        is_bet: null,
        max_bet: null,
        name: null,
        new_password: null,
        params: null,
        phone: '',
        reg_status: null,
    },
    language: null,
    defaultLanguage: 'en-EN',
};

export const user = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
