<template lang="pug">
  svg#in_sta(width='47' height='47' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(d='M42 0H5C2.23858 0 0 2.23858 0 5V42C0 44.7614 2.23858 47 5 47H42C44.7614 47 47 44.7614 47 42V5C47 2.23858 44.7614 0 42 0Z' fill='#4A9EBA')
    path(d='M23.482 28.9169C26.4025 28.9169 28.7763 26.542 28.7763 23.6215C28.7763 22.0364 28.0754 20.6132 26.9695 19.6412C26.5802 19.3004 26.1426 19.0157 25.6667 18.7998C24.9996 18.496 24.2616 18.3261 23.4831 18.3261C22.7046 18.3261 21.9655 18.496 21.2995 18.7998C20.8225 19.018 20.3837 19.3026 19.9967 19.6424C18.8886 20.6144 18.1877 22.0375 18.1877 23.6226C18.1877 26.542 20.5638 28.9169 23.482 28.9169ZM23.5 19.7099C25.6611 19.7099 27.4139 21.4615 27.4139 23.6226C27.4139 25.7838 25.6611 27.5365 23.5 27.5365C21.3389 27.5365 19.5861 25.7849 19.5861 23.6226C19.5861 21.4615 21.3389 19.7099 23.5 19.7099ZM29.3759 20.125H37V31.6877C37 34.6217 34.6217 37 31.6877 37H15.3831C12.4491 37 10 34.6217 10 31.6877V20.125H17.5904C16.9784 21.1521 16.6094 22.3401 16.6094 23.6226C16.6094 27.4184 19.6862 30.4953 23.482 30.4953C27.2778 30.4953 30.3546 27.4184 30.3546 23.6226C30.3558 22.3401 29.9867 21.1521 29.3759 20.125ZM31.6877 10H17.875V15.625H16.75V10H15.625V15.625H14.5V10.0866C14.1108 10.1507 13.7327 10.2475 13.375 10.3904V15.625H12.25V11.0102C10.8977 11.9744 10 13.537 10 15.3123V19H18.4071C19.72 17.5578 21.5549 16.75 23.482 16.75C25.4193 16.75 27.2519 17.5656 28.5569 19H37V15.3123C37 12.3783 34.6217 10 31.6877 10V10ZM34.75 15.9231C34.75 16.3799 34.3754 16.75 33.9119 16.75H31.087C30.6246 16.75 30.25 16.3799 30.25 15.9231V13.0769C30.25 12.6201 30.6246 12.25 31.087 12.25H33.9119C34.3754 12.25 34.75 12.6201 34.75 13.0769V15.9231Z' fill='white')

</template>

<script>
export default {
  name: 'Istagram',
  props: {
    isClicked: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    changeFillFirst() {
      if (this.isClicked) {
        return '#ffffff';
      } else {
        return '#4A9eba';
      }
    },
    changeFillSecond() {
      if (this.isClicked) {
        return '#0c93dd';
      } else {
        return '#ffffff';
      }
    },
  },
};
</script>