<template lang="pug">
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'GamesList',
  props: {
    defaultLimit: {
      type: Number,
      default: 10,
      required: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
      required: false,
    },
    page: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    localSearchString: null,
    //Указатель на корневой элемент СВГ обложки
    svg: null,
    //Таймер для переключения анимаций обложек
    timer: null,
    //Флаг наведения
    hover: false,
    //Список фильтров
    filters: [
      {name: 'Slots', title: 'slotsLabel', icon: 'gl-icon-slots'},
      {name: 'Pyramid', title: 'pyramidLabel', icon: 'gl-icon-pyramid'},
      {name: 'Blackjack', title: 'blackjackLabel', icon: 'gl-icon-blackjack'},
      {name: 'Bingo', title: 'bingoLabel', icon: 'gl-icon-bingo'},
      {name: 'Roulette', title: 'rouletteLabel', icon: 'gl-icon-roulette'},
      {name: 'Mini games', title: 'miniGamesLabel', icon: 'gl-icon-minigames'},
    ],
    //Флаг отображения фильтров
    isFiltersShow: true,
    isAllProvidersShow: false,
    //Выбранная обложка
    gameSelectId: 0,
    isMobile: false,
    isClickedCross: false,
  }),
  computed: {
    ...mapGetters({
      userLanguageCode: 'user/getLanguageCode',
      list: 'games/getList',
      providers: 'games/getProviders',
      listCount: 'games/getListCount',
      isFavoritesFilter: 'games/isFavoritesFilter',
      isNewFilter: 'games/isNewFilter',
      searchString: 'games/getSearchString',
      additionalFilters: 'games/getAdditionalFilters',
      listLimit: 'games/getListLimit',
      isAuthUser: 'auth/isAuthUser',
      pages: 'main/getPages',
      brand: 'main/getBrand',
    }),
    isBtnShow() {
      return this.listLimit < this.listCount;
    },
    isAll() {
      return !this.additionalFilters || this.additionalFilters.length === 0;
    },
    getProviders() {
      let p = this.providers;
      if (!this.isAllProvidersShow) {
        return [{id: -1, display_name_en: 'ALL', display_name_ru: 'ВСЕ'}].concat(p).slice(0, 10);
      } else {
        return [{id: -1, display_name_en: 'ALL', display_name_ru: 'ВСЕ'}].concat(p);
      }
    }
  },
  async mounted() {
    this.SET_LIST_LIMIT(this.defaultLimit);
    if (this.page === 'Main') {
      this.SET_SEARCH_STRING('');
    } else {
      this.localSearchString = this.searchString;
    }
    if(!this.pages.games) {
      this.SET_NEW_FILTER(false);
      this.SET_FAVORITES_FILTER(false);
      this.SET_ADDITIONAL_FILTERS('');
    }

    await this.fetch();
    window.addEventListener('orientationchange', this.resize, false);
    window.addEventListener('resize', this.resize, false);
  },
  methods: {
    ...mapActions({
      fetchGameList: 'games/fetchGameList',
      doChangeFavorites: 'games/doChangeFavorites',
      fetchGameUrl: 'games/fetchGameUrl',
    }),
    ...mapMutations({
      SET_LIST_LIMIT: 'games/SET_LIST_LIMIT',
      SET_NEW_FILTER: 'games/SET_NEW_FILTER',
      SET_FAVORITES_FILTER: 'games/SET_FAVORITES_FILTER',
      SET_ADDITIONAL_FILTERS: 'games/SET_ADDITIONAL_FILTERS',
      SET_SEARCH_STRING: 'games/SET_SEARCH_STRING',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_DEMO_RUN_GAME: 'games/SET_DEMO_RUN_GAME',
      SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE',
    }),
    addToFavoritesText(bool) {
      return bool ? this.$t('games.favoritesRemoveLabel') : this.$t('games.favoritesLabel');
    },
    filtersResetListener() {
      this.SET_LIST_LIMIT(this.defaultLimit);
      this.SET_ADDITIONAL_FILTERS([]);
      this.fetch();
    },
    resize() {
      if (window.innerWidth >= 1280) {
        this.isMobile = false;
      }
      else {
        this.isMobile = true;
      }

      const el_wrap = this.$refs['games_wrap'];
      if (el_wrap) {
        const width_wrap = el_wrap.offsetWidth;
        const el_covers = el_wrap.querySelectorAll(':scope .gl-card');
        if (el_covers) {
          const covers_count = el_covers.length;
          const isLast = !this.isBtnShow;
          let cols_count = 0;
          let width_cover = 0;
          let cover_show_count = 0;
          let cover_index_max = 0;
          el_covers.forEach(
              (el_cover, index) => {
                if (width_cover === 0) {
                  width_cover = el_cover.offsetWidth;
                  cols_count = (width_wrap / width_cover) | 0;
                  cover_show_count = (covers_count / cols_count) | 0;
                  cover_index_max = cover_show_count * cols_count;
                }
                el_cover.classList.toggle('dhide', isLast === false && cover_index_max > 0 && index >= cover_index_max);
              }
          );
        }
      }
    },
    async fetch() {
      this.icon_stop();
      await this.fetchGameList();
      this.resize();
    },
    getTitle(game){
        let s = 'title_' + this.userLanguageCode;
        return (typeof game[s] === 'string' && game[s]!=='' ? game[s] : game['title_en']);
    },
    getTitleProvider(provider){
      let s = 'display_name_' + this.userLanguageCode;
      return (typeof provider[s] === 'string' && provider[s]!=='' ? provider[s] : provider['display_name_en']);
    },
    url_icon(game) {
      let out = 'game-icons/no_cover.svg';
      if (game.image_file) {
        try {
          out = 'game-icons/' + game.image_file;
        } catch (e) {
          console.log('Cover not find: ', game.image_file);
        }
      } else if (game.aggregator && game.aggregator === 'softswiss') {
        return game.aggregator_params.game_icon.url + game.provider + '/' + (game.provider_id.split(':')[1]) + '.' + game.aggregator_params.game_icon.format;
      }
      return out;
    },
    icon_timer_create() {
      if (this.timer === null)
        this.timer = setTimeout(
            () => {
              this.icon_timer();
            },
            10000
        );
    },
    icon_timer(rand = true) {
      if (this.timer !== null)
        clearTimeout(this.timer);
      this.timer = null;
      if (rand === true)
        this.icon_random();
    },
    icon_play() {
      if (
          this.svg &&
          this.svg !== null &&
          this.svg.unpauseAnimations &&
          this.svg.animationsPaused &&
          this.svg.animationsPaused() === true
      )
        this.svg.unpauseAnimations();
      this.icon_timer_create();
    },
    icon_stop() {
      if (
          this.svg &&
          this.svg !== null &&
          this.svg.pauseAnimations &&
          this.svg.animationsPaused() === false
      )
        this.svg.pauseAnimations();
      this.svg = null;
      this.icon_timer_create();
    },
    icon_random() {
      if (this.hover === false) {
        const icons = this.list.filter(icon => icon.image_file !== null);
        this.icon_stop();
        if (icons.length > 0) {
          //eslint-disable-next-line
          const icon = icons[Math.floor(Math.random() * icons.length)];
          const el_array = this.$refs['game_' + icon.id];
          if (el_array && el_array.length > 0) {
            const el = el_array[0];
            if (el) {
              if (el.contentDocument === null)
                this.icon_random();
              else {
                this.svg = el.contentDocument.documentElement;
                this.icon_play();
              }
            }
          }
        }
      }
    },
    async on_over() {
      this.hover = true;
      // this.icon_timer(false);
      // this.icon_stop();
      // this.svg = event.target.firstChild.contentDocument.documentElement;
      // this.icon_play();
    },
    async on_out() {
      this.hover = false;
      // this.icon_random();
    },
    async on_svg_load(event) {
      if (this.svg !== null) {
        const svg_event = event.target.contentDocument.documentElement;
        if (
            svg_event &&
            svg_event.pauseAnimations &&
            svg_event.animationsPaused() === false
        )
          svg_event.pauseAnimations();
      }
    },
    async doOpenGameUrl(gameId, isDemo = false) {
      const url = await this.fetchGameUrl({gameId, demo: isDemo, homeButton: true, mobile: this.isMobile});

      if (url.length > 0) {
        window.location.href = url;
        if (this.$metrika && isDemo === false) {
          this.$metrika.reachGoal('gameRun');
        }
      }
    },
    async on_click_view_more() {
      if (this.page === 'Main') {
        this.SET_OPEN_PAGE('games');
        this.$router.push('/games');
        window.scrollTo(0, 0);
      } else {
        this.SET_LIST_LIMIT(this.listLimit + this.defaultLimit);
        this.fetch();
      }
    },
    async selectProvider(provider) {
      if (provider.id === -1) {
        this.filtersResetListener();
        return;
      }
      let newAdditionalFilters = this.additionalFilters;
      if (!newAdditionalFilters) {
        newAdditionalFilters = [];
      }
      let idx = this.additionalFilters.indexOf(provider.id);
      if (idx === -1) {
        newAdditionalFilters.push(provider.id);
      } else {
        if (this.additionalFilters.length === 1) {
          newAdditionalFilters = [];
        } else {
          newAdditionalFilters.splice(idx, 1);
        }
      }

      this.SET_LIST_LIMIT(this.defaultLimit);
      this.SET_ADDITIONAL_FILTERS(newAdditionalFilters);
      this.fetch();
    },

    async setFavorite(game_id, is_favorite) {
      await this.doChangeFavorites(
          {
            id: game_id,
            value: is_favorite
          }
      );
      if (this.isFavoritesFilter && !is_favorite) {
        this.fetch();
      }
    },
    isFilter(provider) {
      return this.additionalFilters.indexOf(provider.id) !== -1 || (this.additionalFilters.length===0 && provider.id === -1);
    },
    //Обработчик выбора обложки
    selectGame(gameId = 0, isDemo = false) {
      if (isDemo === false && !this.isAuthUser) {
        this.SET_SIGNIN_VISIBLE(true);
        return;
      }

      if (!this.isMobile) {
        if (isDemo) {
          this.SET_DEMO_RUN_GAME(true);
        }
        this.doOpenGamePage(gameId);
      } else {
        if (this.gameSelectId !== gameId) {
          this.gameSelectId = gameId;
        } else {
          if (!isDemo && this.$metrika) {
            this.$metrika.reachGoal('gameRun');
          }
          this.doOpenGameUrl(gameId, isDemo);
        }
      }
    },
    keypressSearch(e) {
      if (e.key === 'Enter') {
        this.searchListener();
      }
    },
    allProviderListener() {
     this.isAllProvidersShow = !this.isAllProvidersShow;
    },
    favListener() {
      this.SET_LIST_LIMIT(this.defaultLimit);
      this.SET_FAVORITES_FILTER(!this.isFavoritesFilter);
      if (this.isFavoritesFilter && this.isNewFilter){
        this.SET_NEW_FILTER(!this.isNewFilter);
      }

      this.fetch();
    },
    newListener() {
      this.SET_LIST_LIMIT(this.defaultLimit);
      this.SET_NEW_FILTER(!this.isNewFilter);
      if (this.isFavoritesFilter && this.isNewFilter){
        this.SET_FAVORITES_FILTER(!this.isFavoritesFilter);
      }
      this.fetch();
    },
    searchListener() {
      this.SET_LIST_LIMIT(this.defaultLimit);
      this.SET_SEARCH_STRING(this.localSearchString.toLowerCase());
      this.fetch();
    },
    handlerGlBtnFilters() {
      this.isFiltersShow=!this.isFiltersShow;

      if (this.$metrika) {
        this.$metrika.reachGoal('gamesFilterClick');
      }
    },
    async doOpenGamePage(gameId) {
      if (!this.isMobile) {
        this.SET_OPEN_PAGE('personalGame');
        await this.$router.push(`/game?id=${gameId}`);
        window.scrollTo(0, 0);
      }
    },
    /**
     * метод-обработчик очистки строки поиска
     * @returns {Promise<void>}
     */
    async clearSearchStr() {
      this.isClickedCross = true;
      this.localSearchString = '';
      this.SET_SEARCH_STRING('');
      await this.fetchGameList();
      setTimeout(() => {
        this.isClickedCross = false;
      }, 500);
    },

  },
  watch: {
    searchString(newSearch) {
      if (this.localSearchString !== newSearch) {
        this.localSearchString = newSearch;
        this.fetch();
      }
    },
  }
};
</script>

<style lang="scss">
</style>