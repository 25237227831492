import {clientApiAuth} from '@/utils/clientApiAuth';

export const fetchInitSetting = async ({state}) => {
    let response;

    try {
        response = await clientApiAuth('get', 'user/init-setting');

        if (response !== 401) {
            const {data} = response;

            if (response.status === 200) {
                state.logoId = data.logo;
                state.brand = data.brand;
                state.favicon = data.favicon;
            }
        }
    } catch (error) {
        console.log(error);
    }
};
