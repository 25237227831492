<template lang="pug">
  svg#topRoulettes.icon(width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M12.0997 14.0176H13.471C13.3097 6.43164 7.42118 0.24743 0 6.10352e-05V1.40182V5.77199V7.17375C3.54926 7.50357 6.4532 10.472 6.69519 14.1001H8.0665C7.98583 13.1106 7.8245 12.2036 7.42118 11.2966L11.2931 9.81235C11.7771 11.1316 12.0191 12.5334 12.0997 14.0176ZM2.33928 6.26673L4.03325 2.22638C5.64655 2.88603 7.17918 3.8755 8.38916 5.1948L5.40455 8.32813C4.5979 7.42112 3.54926 6.76147 2.33928 6.26673Z')
    path(:fill='changeFill' d='M6.45433 5.27688C6.45433 4.53477 5.88967 3.95758 5.16369 3.95758C4.4377 3.95758 3.87305 4.53477 3.87305 5.27688C3.87305 6.01898 4.4377 6.59618 5.16369 6.59618C5.88967 6.59618 6.45433 5.93653 6.45433 5.27688Z')
    path(:fill='changeFill' d='M0 9.56488V14.1H4.35591C4.19458 11.7087 2.33928 9.72979 0 9.56488Z')
</template>

<script>
export default {
  name: 'TopRoulettesSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#94b8af',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>