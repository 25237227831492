import {clientApi} from '@/utils/clientApi';

export const fetchGameList = async ({commit, state}, gameId = null) => {

    const params =
        {
            is_new: state.isNewFilter,
            is_favorites: state.isFavoritesFilter,
            search_string: state.searchString,
            page: 1,
            limit: state.listLimit,
            get_list_for_game: gameId,
            filters: state.additionaFilters
        };
    try {
        let response = await clientApi(
            'post',
            'game/list',
            {search: params}
        );
        if (response) {
            const {data, error, search, providers} = response.data;
            if (error === null) {
                if (typeof search.count === 'number') {
                    commit('SET_LIST_COUNT', search.count);
                }
                else {
                    commit('SET_LIST_COUNT', 0);
                }

                commit('SET_LIST', data);
                commit('SET_PROVIDERS', providers);
            }
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};