import * as actions from './actions/';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    fundAccounts: {},
    currencies:{},
    activeFundItem: null,
    //object: id, title
    activeTab: null,
    activeTab2: null,
    accountsHistory: {},
    exchangeToolsCurrency: {},
    exchangeBids: {
        buy: {
            type:'buy',
            amount: null,
            deviation: 0,
            rate: null,
            feePercent: 0.25,
            fee: null,
            totalPrice: null
        },
        sell: {
            type:'sell',
            amount: null,
            deviation: 0,
            rate: null,
            feePercent: 0.25,
            fee: null,
            totalPrice: null
        }
    },
    openBids:{},
    groupBids:{},
    myBids:{},
    paymentMethods:{},

};

export const fund = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};