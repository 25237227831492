<template lang="pug">
  svg#cross_shadoff.close_svg(version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' :width="width" :height="height" viewBox='0 0 26 26' enable-background='new 0 0 26 26' xml:space='preserve')
    g
        path(:fill='changeFill' d='M15.542,12.999l9.889-9.888c0.701-0.701,0.703-1.838,0.002-2.541c-0.703-0.702-1.842-0.702-2.543,0\
        L13,10.458L3.111,0.569c-0.702-0.703-1.839-0.703-2.542,0c-0.703,0.703-0.701,1.839,0,2.541l9.888,9.888L0.57,22.887\
        c-0.702,0.703-0.702,1.842,0.001,2.543c0.702,0.701,1.838,0.701,2.541-0.002L13,15.541l9.889,9.889\
        c0.701,0.703,1.84,0.703,2.541,0.002c0.701-0.703,0.703-1.842,0-2.545L15.542,12.999z')
</template>

<script>
export default {
  name: 'CrossShadowOff',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    isHoveredCross: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 30,
    },
    width: {
      type: Number,
      default: 28,
    },
    color: {
      type: String,
      default: '#84bace',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else if (this.isHoveredCross) {
        return '#0c93dd';
      } else {
        return this.color;
      }
    }
  },
};
</script>