import {clientApi} from '@/utils/clientApi';

export const doChangePassword = async ({ commit, rootGetters }, payload) => {
  let response;

  let currentLang = rootGetters['user/getLanguage'];

  try {
    response = await clientApi('post', `user/change-password?lang=${currentLang}`, payload);
  } catch (error) {
    console.log('error = ', error);
  }

  const { error, message } = response.data;

  if (error === 0) {
    commit('main/SET_MODAL_MESSAGE', {
          level: 'info',
          icon: 'i',
          buttons: 'ok',
          text: message,
          accept_action_func: 'close',
        }, {root: true});
  }
  else {
    commit('main/SET_MODAL_MESSAGE', {
      level: 'error',
      icon: 'x',
      buttons: 'ok',
      text: message,
      accept_action_func: 'close',
    }, {root: true});
  }
};