import {clientApi} from '@/utils/clientApi';

// eslint-disable-next-line no-empty-pattern
export const fetchGameInfo = async ({}, gameId) => {
    try {
        let response = await clientApi('get', `game/info?gameId=${gameId}`);

        if (response) {
            const {data, error} = response.data;
            if (error == 0) {
                return data;
            }
        }
    } catch (error) {
        console.log(error);
        throw error; // Важно передать ошибку дальше - чтобы было понятно в чем причина отказа
    }
};