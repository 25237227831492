<template lang="pug">
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {displayCoins} from '@/utils/moneyUtils.js';
import {timeFormatter} from '@/utils/eventWorkers/timeFormatter';


export default {
  name: 'History',
  data: () => ({
    displayCurrency: convertCurrencyToSymbol,
    timeFormatter,
    displayCoins,
    bidsErrors: {buy: {amountError: false}}
  }),

  computed: {
    ...mapGetters({
      activeTabExH: 'exchangeHistory/activeTabExH',
      myBids: 'fund/getMyBids',
      lang: 'user/getLanguageCode',
      currencies: 'fund/getCurrencies',
    }),

    compoundTabsArray() {
      return [
        {
          id: 'orders_history',
          title: this.$t('exchange.history.tab.order')
        },
      ];
    },
    compoundExchHistoryHead() {
      return [
        '#',
        this.$t('exchange.history.tableDateTitle'),
        this.$t('exchange.history.tableTypeTitle'),
        this.$t('exchange.history.tableAmountTitle'),
        this.$t('exchange.history.tableRateTitle'),
        this.$t('exchange.history.tableStatusTitle'),
        this.$t('exchange.history.gaveTitle'),
        this.$t('exchange.history.receivedTitle'),
        this.$t('exchange.history.restTitle'),

      ];
    },
    bids() {
      return Object.values(this.myBids).sort((a, b) => Number(b.id) - Number(a.id));
    }
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({}),
    //@TODO
    loadData() {
      this.$socket.client.emit('getMyBids', {offset: Object.values(this.myBids).length, limit: 20});
    },
    type(item) {
      return this.$t(`exchange.history.${item.type}Type`)
          + (item.method ? (' (' + this.$t(`exchange.history.${item.method}Method`) + ')') : '');
    },
    gave(item) {
      let sum = 0;
      let currencyCode = item.type === 'sell' ? item.currency_code : item.tools_currency_code;

      if (item.method == 'market') {
        sum = item.type === 'sell' ? item.amount : item.price;
      } else {
        if (item.story.length == 0) {
          return `0 ${currencyCode}`;
        }


        if (item.type == 'sell') {
          sum = item.story.reduce((sum, s) => {
            sum += parseInt(s.amount);
            return sum;
          }, 0);
        } else if (item.type == 'buy') {
          sum = item.story.reduce((sum, s) => {
            sum += parseInt(s.price);
            return sum;
          }, 0);
        }
      }
      return this.displayCoins(sum, this.currencies[currencyCode]);
    },
    received(item) {
      let sum = 0;
      let currencyCode = item.type === 'sell' ? item.tools_currency_code : item.currency_code;

      if (item.method == 'market') {
        sum = item.type === 'sell' ? item.price : item.amount;
      } else {
        if (item.story.length == 0) {
          return `0 ${currencyCode}`;
        }

        if (item.type == 'sell') {
          sum = item.story.reduce((sum, s) => {
            sum += parseInt(s.price);
            return sum;
          }, 0);
        } else if (item.type == 'buy') {
          sum = item.story.reduce((sum, s) => {
            sum += parseInt(s.amount);
            return sum;
          }, 0);
        }
      }
      return this.displayCoins(sum, this.currencies[currencyCode]);
    }
  },
};
</script>

<style lang="scss">
</style>