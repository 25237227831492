<template lang="pug">
  .banner1
    .banner-layer(data-layer="1")
    .banner-layer(data-layer="2")
    .banner-layer(data-layer="3")
    .banner-layer(data-layer="4")
    .banner-layer(data-layer="5")
    .banner-layer(data-layer="6")
    .banner-layer(data-layer="7")
    .banner-wrap(v-if="!authUser")
      InviteWindow 
</template>

<script>
const InviteWindow = () => import('@/components/topMenu/sundukWhite/banner/InviteWindow');
import { mapGetters } from 'vuex';

export default {
    name: 'Banner1',
    components: {
      InviteWindow
    },
    computed: {
      ...mapGetters({
        authUser: 'auth/isAuthUser',
      }),
    },
};
</script>

<style lang="scss">
@import '@/assets/styles/top-menu/sunduk-white/banner/1_sw.scss';
</style>