<template lang="pug">
.tabs
    .tab(v-for="(item) in tabsArray" :key="item.id"
        @click="tabHandler(item)"
        :class="{ 'active': innerLevel == '2' ? (activeTab2 && activeTab2.id === item.id) : ((activeTab && activeTab.id === item.id) || (activeTabExH && activeTabExH.id === item.id)) }") {{ item.title }}
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'TabSW',
    props: {
        tabType: {
            type: String,
            default: '',
        },
        tabsArray: {
            type: Array,
            default: () => [],
        },
        innerLevel: {
            type: String,
            default: '',
            required: false
        },
    },
    data: () => ({
    }),
    computed: {
        ...mapGetters({
          activeTab: 'fund/getActiveTab',
          activeTabExH: 'exchangeHistory/activeTabExH',
          activeTab2: 'fund/getActiveTab2',
        }),
    },
    methods: {
        ...mapMutations({
            SET_ACTIVE_TAB: 'fund/SET_ACTIVE_TAB',
            SET_ACTIVE_TAB2: 'fund/SET_ACTIVE_TAB2',
            SET_ACTIVE_TAB_ExH: 'exchangeHistory/SET_ACTIVE_TAB_ExH',
        }),
        tabHandler(item) {
          if (this.innerLevel == '2') {
            this.SET_ACTIVE_TAB2(item);
          } else {
            if (this.tabType === 'exch_history') {
                this.SET_ACTIVE_TAB_ExH({ id: 'history_orders', title: 'orders history' });
            }
            else {
                this.SET_ACTIVE_TAB(item);
            }
          }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/serviceComponents/tab-sw.scss";
</style>