import {clientApi} from '@/utils/clientApi';

export const doBid = async ({commit}, payload) => {
    let response = null;

    try {
        response = await clientApi('post', 'exchange/bid', payload);
    } catch (error) {
        console.log(error);
        throw error;
    }

    if (response != null) {
        const {error, message, data} = response.data;
        if (error === 0) {
            console.log(data);
            return true;
        } else {
            if (message) {
                if (error !== 401) {
                    commit('main/SET_MODAL_MESSAGE', {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: message,
                        accept_action_func: 'close',
                    }, {root: true});
                }
            }
        }
    }
};