<template lang="pug">
  .ti-valoff-wrapper
    .soc-img(:class="socImgClassMerger")
      img(v-show="name === 'fb'" :src="require('@/assets/images/personal/edit_profile/fbk.svg')" alt="facebook")
      img(v-show="name === 'tw'" :src="require('@/assets/images/personal/edit_profile/tw_t_ter.svg')" alt="tw")
      img(v-show="name === 'ytb'" :src="require('@/assets/images/personal/edit_profile/you_tu_be.svg')" alt="ytb")
    input(:name="name" autocomplete="off" :id="name" v-model="inputValue"
           @input="emitInputValue" :class="")
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'TextInputValidationOff',
  props: {
    name: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: [String, null],
      default: null,
    },
  },
  data: () => ({
    inputValue: '',
  }),
  computed: {
    ...mapGetters({}),
    hasValue() {
      return !!this.inputValue;
    },
    socImgClassMerger() {
      return {
        'ytb': this.name === 'ytb',
        'fbk': this.name === 'fb',
        'twt': this.name === 'tw',
      };
    },
  },
  methods: {
    ...mapMutations({
    }),
    emitInputValue() {
      this.$emit('textInputEmit', {name: this.name, value: this.inputValue});
    },
  },
  watch: {
    clearFields(newVal) {
      if (newVal) {
        this.inputValue = '';
      }
    },
  },
  mounted() {
    if (this.name === 'fb' || this.name === 'tw' || this.name === 'ytb') {
      this.$nextTick(() => {
        this.inputValue = this.defaultValue;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
</style>