<template lang="pug">
.exchange-cmp
  .balances
    .item(v-for="account in Object.values(fundAccount)" :key="account.id") {{ displayCoins(account.value, account.currency) }}
  .choose-tools
    .text {{ $t("exchange.chooseCurrency") }}
    .buttons
      .button(v-for="currency of toolsCurrencies" :key="currency.id"
        :class="{ 'active': toolsCurrency.code == currency.code }"  @click="currencyHandler(currency)") {{ currency.code }}
  .pairs
    .text {{ $t("exchange.choosePair") }}
    TabSW(:tabsArray='currencyPairs')
  .bid-methods
    .text {{ $t("exchange.chooseOrder") }}
    TabSW(:tabsArray='bidMethod' :innerLevel="'2'")
  .bids
    .buy
      BidSW(type="buy" :bestRate="bestSell")
    .sell
      BidSW(type="sell" :bestRate="bestBuy")
  .orders
    .sell
      .text {{ $t("exchange.orderSaleTitle") }}
      .table-wrapper
        table
          thead
            tr
              th {{ $t('exchange.rateTitle') }}
              th {{ $t('exchange.amountTitle') }}
              th {{ $t('exchange.priceTitle') }}
            
          tbody
            tr(v-for="bid in sellBids" :key="bid.rate" @click="selectSell(bid)")
              td {{ displayCoins(bid.rate, toolsCurrency) }}
              td {{ displayCoins(bid.amount, activeTab.currency) }}
              td {{ displayCoins(bid.price, toolsCurrency) }}
    .buy
      .text {{ $t("exchange.orderBuyTitle") }}
      
      .table-wrapper
        table
          thead
            tr
              th {{ $t('exchange.rateTitle') }}
              th {{ $t('exchange.amountTitle') }}
              th {{ $t('exchange.priceTitle') }}

          tbody
            tr(v-for="bid in buyBids" :key="bid.rate" @click="selectBuy(bid)")
              td {{ displayCoins(bid.rate, toolsCurrency) }}
              td {{ displayCoins(bid.amount, activeTab.currency) }}
              td {{ displayCoins(bid.price, toolsCurrency) }}
</template>

<script>

import Exchange from './Exchange.vue';

const TabSW = () => import('@/components/serviceComponents/TabSW');
const BidSW = () => import('@/components/exchange/BidSW');


export default {
  extends: Exchange,
  components: {
    TabSW,
    BidSW
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/exchange/exchange_sw.scss";
</style>