<template lang="pug">
  svg#twit_ter(width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFillFirst' d='M42.0547 0.019104H5.05469C2.29326 0.019104 0.0546875 2.25768 0.0546875 5.0191V42.0191C0.0546875 44.7805 2.29326 47.0191 5.05469 47.0191H42.0547C44.8161 47.0191 47.0547 44.7805 47.0547 42.0191V5.0191C47.0547 2.25768 44.8161 0.019104 42.0547 0.019104Z')
    path(:fill='changeFillSecond' d='M35.8959 16.4386C36.1547 16.1379 35.8222 15.713 35.4422 15.8269C35.0315 15.95 34.7515 15.3251 35.0233 14.9934C35.1566 14.8307 35.2805 14.6603 35.3942 14.4831C35.6582 14.0714 35.1911 13.6601 34.7416 13.853C34.2362 14.0699 33.7146 14.2482 33.1815 14.3862C32.7962 14.4859 32.3981 14.3446 32.0936 14.0884C30.0263 12.3493 26.9385 12.3952 24.9213 14.2904C23.8358 15.31 23.2199 16.7328 23.2182 18.2218C23.2199 18.8469 22.7382 19.425 22.1184 19.3442C19.0426 18.9431 16.1686 17.6204 13.8689 15.5646C13.0086 14.7955 11.606 14.9509 11.5109 16.101C11.4003 17.4399 11.7935 18.7872 12.6252 19.8599C12.9303 20.2533 12.6625 20.8763 12.1949 20.7054C11.9543 20.6176 11.7196 20.5125 11.4926 20.3906C11.4642 20.3753 11.4295 20.3958 11.4295 20.4281C11.4308 22.3948 12.4924 24.1518 14.1198 25.0966C14.4984 25.3164 14.4526 25.9234 14.0155 25.8978C13.7024 25.8795 13.4465 26.1755 13.5796 26.4595C13.839 27.0127 14.1868 27.5098 14.6026 27.9357C15.5842 28.9411 15.9916 31.0719 14.6395 31.4539C13.6788 31.7254 12.6789 31.8647 11.6673 31.8624C11.3524 31.8616 11.2148 32.2896 11.4929 32.437C13.6984 33.6066 16.1636 34.2194 18.673 34.2124C28.626 34.2124 34.0393 25.984 34.0393 18.8516C34.0393 18.7851 34.0393 18.719 34.0393 18.6532C34.0393 18.3367 34.1926 18.0415 34.4397 17.8437C34.9677 17.421 35.4552 16.9505 35.8959 16.4386Z')
</template>

<script>
export default {
  name: 'Twitter',
  props: {
    isClicked: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    changeFillFirst() {
      if (this.isClicked) {
        return '#ffffff';
      } else {
        return '#4A9eba';
      }
    },
    changeFillSecond() {
      if (this.isClicked) {
        return '#0cb4ec';
      } else {
        return '#ffffff';
      }
    },
  },
};
</script>