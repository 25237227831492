import {clientApiAuth} from '@/utils/clientApiAuth';

export const fetchBalance = async ({commit, state, rootGetters}) => {
    let response;
    let currentLang = rootGetters['user/getLanguage'];
    try {
        response = await clientApiAuth('get', `user/balance?lang=${currentLang}`);

        if (response !== 401) {
            state.balance = {
                value: response.data.balance,
                currency: response.data.currency_code,
                minor_unit:response.data.currency_minor_unit,
                separator:response.data.currency_separator,
                is_crypto: response.data.currency_is_crypto
            };
        } else {
            state.balance = {value: null, currency: null, minor_unit:0, separator:'.'};

            if (response === 401) {
                if (rootGetters['auth/isAuthUser'] !== false) {
                    commit('auth/SET_AUTH_USER', false, {root: true});
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
};
