import {clientApi} from '@/utils/clientApi';

export const fetchSocials = async ({commit, state}) => {
    let result;

    try {
        result = await clientApi('get', 'socials/list');
    } catch (error) {
        console.log('error = ', error);
    }

    if (result) {
        let {error, message, data} = result.data;

        if (error !== 0) {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        } else {
            let socials=  {
                facebook: {title:'fb', url: '', disabled: false},
                instagram: {title:'insta',  url: '', disabled: false},
                twitter: {title: 'twitter',  url: '', disabled: false},
                vkontakte: { title: 'vk', url: '', disabled: false},
                whatsapp: { title: 'watsup',  url: '', disabled: false},
                youtube: {title: 'youtube', url: '', disabled: false},
                google: {title: 'google', url: '', disabled: false}
            };
            for (let socialKey in socials) {
                if (data.hasOwnProperty(socialKey)) {
                    socials[socialKey].url = data[socialKey];
                } else {
                    delete socials[socialKey];
                }
            }
            state.socials = socials;
        }
    }
};
