<script>
import Dropdown from './Dropdown';

export default {
  extends: Dropdown,
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/serviceComponents/dropdown_sw.scss";
</style>
