import {clientApi} from '@/utils/clientApi';

export const fetchPayStatus = async ({commit, dispatch}, trxId) => {
    let response;
    let intervalId = null;

    try {
        response = await clientApi('get', `payment/check-pay-status?trx_id=${trxId}`);
        let {data} = response.data;
        if (data && data.hasOwnProperty('url')) {
            commit('main/SET_MODAL_MESSAGE', null, {root: true});
            // window.open(data.url);
            // dispatch('pay/doRedirect', {trxId}, {root: true});
            commit('personal/SET_OPEN_SECTION', 'paymentsHistory', {root: true});
        } else {
            if (data.state === 'wait') {
                intervalId = setInterval(async () => {
                    response = await clientApi('get', `payment/check-pay-status?trx_id=${trxId}`);
                    let {data} = response.data;

                    if (data && data.hasOwnProperty('url')) {
                        commit('main/SET_MODAL_MESSAGE', null, {root: true});
                        commit('pay/SET_PAY_URL', {id:trxId,url:data.url}, {root: true});
                        // dispatch('pay/doRedirect', {trxId}, {root: true});
                        // commit('personal/SET_OPEN_SECTION', 'paymentsHistory', {root: true});
                        clearInterval(intervalId);
                    }

                    if (data.state === 'fail' || data.state === 'success' || response.data.error !== 0) {
                        if (data.state === 'fail') {
                            commit('main/SET_MODAL_MESSAGE', null, {root: true});
                            commit('main/SET_MODAL_MESSAGE', {
                                level: 'error',
                                icon: 'x',
                                buttons: 'ok',
                                text: data.message,
                                accept_action_func: 'close',
                            }, {root: true});
                        } else if (data.state === 'success') {
                            dispatch('user/fetchBalance', null, {root: true});
                            commit('main/SET_MODAL_MESSAGE', null, {root: true});
                            commit('main/SET_MODAL_MESSAGE', {
                                level: 'info',
                                icon: 'i',
                                buttons: 'ok',
                                text: data.message,
                                accept_action_func: 'close',
                            }, {root: true});
                        }

                        clearInterval(intervalId);
                    } else if (data.state === 'wait' && data.hasOwnProperty('message')) {
                        commit('main/SET_MODAL_MESSAGE', null, {root: true});
                        commit('main/SET_MODAL_MESSAGE', {
                            level: 'info',
                            icon: 'i',
                            buttons: 'ok',
                            text: data.message,
                            accept_action_func: 'close',
                        }, {root: true});
                        clearInterval(intervalId);
                    }
                }, 1000);
            } else if (data.state === 'fail') {
                commit('main/SET_MODAL_MESSAGE', null, {root: true});
                commit('main/SET_MODAL_MESSAGE', {
                    level: 'error',
                    icon: 'x',
                    buttons: 'ok',
                    text: data.message,
                    accept_action_func: 'close',
                }, {root: true});
                clearInterval(intervalId);
            } else if (data.state === 'success') {
                commit('main/SET_MODAL_MESSAGE', null, {root: true});
                dispatch('user/fetchBalance', null, {root: true});
                commit('main/SET_MODAL_MESSAGE', {
                    level: 'info',
                    icon: 'i',
                    buttons: 'ok',
                    text: data.message,
                    accept_action_func: 'close',
                }, {root: true});
                clearInterval(intervalId);
            }
        }
    } catch (error) {
        clearInterval(intervalId);
        commit('main/SET_MODAL_MESSAGE', {
            level: 'error',
            icon: 'x',
            buttons: 'ok',
            text: error,
            accept_action_func: 'close',
        }, {root: true});
    }
};
