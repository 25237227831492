<template lang="pug">
  svg#wtsp(width='48' height='47' viewBox='0 0 48 47' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFillFirst' d='M42.5137 0H5.51367C2.75225 0 0.513672 2.23858 0.513672 5V42C0.513672 44.7614 2.75225 47 5.51367 47H42.5137C45.2751 47 47.5137 44.7614 47.5137 42V5C47.5137 2.23858 45.2751 0 42.5137 0Z')
    path(:fill='changeFillSecond' d='M11 36L12.8979 29.0666C11.7267 27.0371 11.1114 24.7365 11.1125 22.3774C11.1159 15.0019 17.1178 9 24.4921 9C28.0707 9.00113 31.43 10.395 33.9568 12.924C36.4824 15.453 37.8729 18.8145 37.8718 22.3898C37.8684 29.7664 31.8665 35.7683 24.4921 35.7683C22.2534 35.7671 20.0472 35.2058 18.0931 34.1393L11 36ZM18.4216 31.7171C20.3071 32.8365 22.1071 33.507 24.4876 33.5081C30.6166 33.5081 35.6094 28.5199 35.6128 22.3875C35.615 16.2427 30.6459 11.2612 24.4966 11.259C18.3631 11.259 13.3737 16.2473 13.3715 22.3785C13.3704 24.8816 14.1039 26.7559 15.3358 28.7167L14.2119 32.8207L18.4216 31.7171ZM31.232 25.5701C31.1488 25.4306 30.926 25.3474 30.5908 25.1798C30.2566 25.0121 28.613 24.2032 28.3059 24.0919C27.9999 23.9805 27.7771 23.9243 27.5532 24.2595C27.3305 24.5936 26.6892 25.3474 26.4946 25.5701C26.3 25.7929 26.1042 25.821 25.7701 25.6534C25.436 25.4858 24.3582 25.1336 23.0814 23.994C22.088 23.1075 21.4164 22.0129 21.2217 21.6776C21.0271 21.3435 21.2015 21.1624 21.368 20.9959C21.5187 20.8463 21.7021 20.6055 21.8698 20.4097C22.0396 20.2163 22.0947 20.0768 22.2072 19.8529C22.3186 19.6301 22.2635 19.4344 22.1791 19.2668C22.0948 19.1003 21.4265 17.4544 21.1486 16.785C20.8764 16.1336 20.6007 16.2214 20.396 16.2112L19.7548 16.2C19.532 16.2 19.1698 16.2832 18.8637 16.6185C18.5577 16.9537 17.6937 17.7615 17.6937 19.4074C17.6937 21.0533 18.8919 22.6429 19.0584 22.8656C19.226 23.0884 21.4152 26.4656 24.7689 27.9135C25.5665 28.2577 26.1897 28.4636 26.6746 28.6178C27.4756 28.872 28.2046 28.836 28.7806 28.7505C29.423 28.6549 30.7584 27.9416 31.0374 27.1609C31.3164 26.379 31.3164 25.7096 31.232 25.5701Z')
</template>

<script>
export default {
  name: 'Whatsup',
  props: {
    isClicked: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    changeFillFirst() {
      if (this.isClicked) {
        return '#ffffff';
      } else {
        return '#4A9eba';
      }
    },
    changeFillSecond() {
      if (this.isClicked) {
        return '#4aba63';
      } else {
        return '#ffffff';
      }
    },
  },
};
</script>