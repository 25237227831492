<template lang="pug">
.fop-wrapper
    .for-title {{ switchOperationsTitle }}

    .fop-dep-fiat-wrapper(v-if="showDepositFiat")
        p.title visit the Shop to make a deposit

        .bottom-wrapper
            .b-title For easy and secure access to Bwanabet

            .middle
                img.gl(:src="require('@/assets/images/fund/googleplay.svg')")
                .text 
                    span.l-text download and install the app 2DSCAN via Android Market or direct download
                    span.r-text 2DSCAN:
                img.twods(:src="require('@/assets/images/fund/2dscan.svg')")

            .qcode
                img.qim(:src="require('@/assets/images/fund/google_play_qr.png')")

    .fop-with-fiat-wrapper(v-if="showWithdrawalFiat")
        span Форма создания заявки на списание, пока точного описания нет

    .fop-dep-crypto-wrapper(v-if="showDepositCrypto || showWithdrawalCrypto")
        .min-max-wrapper
            .min-wrapper
                span Минимальная сумма депозита: 5.0 {{ activeTab.currency_code }}
            .max-wrapper
                span Максимальная сумма депозита: 500.0 {{ activeTab.currency_code }}

        .address-wrapper
            input

        .operations-info {{ showSomeText }}
</template>

<script>
import FundOperations from './FundOperations.vue';

export default {
    name: 'FundOperations',
    extends: FundOperations,
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/fund/fund-operations-sw.scss";
</style>