<template lang="pug">
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    name: 'PersonalGame',
    props: {
      gameId: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      sliderList: [],
      isDemo: null,
      isFavorite: false,
      isDemoModeClicked: false,
      isNewTabClicked: false,
      isFullScrClicked: false,
      isReloadClicked: false,
      isCloseClicked: false,
      isFavoritesClicked: false,
      iconsColor: '#FFFFFF',
      checkedIconColor: '#0a73bb',
      initSliderPosition: 0,
      sliderPosition: 0,
      cardWidth: 222,
      sliderIndex: 0,
      sliderPositionStr: '',
      isBtnDisabled: false,
      //Выбранная обложка
      gameSelectIndex: -1,
      //Указатель на корневой элемент СВГ обложки
      svg: null,
      //Флаг наведения
      hover: false,
      toggleTooltip: false,
      tooltipTitle: null,
    }),
    computed: {
      ...mapGetters({
        isAuthUser: 'auth/isAuthUser',
        languageCode: 'user/getLanguageCode',
        list: 'games/getList',
        isDemoRunGame: 'games/isDemoRunGame',
        gameDescription: 'games/getGameDescription',
        brand: 'main/getBrand',
      }),
      isMobile() {
        return window.innerWidth < 1280;
      },
      getTitleForMoney() {
        return this.isDemo ? this.$t('games.realButton') : this.$t('games.demoButton');
      },
      getTitleForFScreen() {
        return this.$t('games.fullScreenLabel');
      },
      getTitleForFavorite() {
        return this.isFavorite ? this.$t('games.favoritesRemoveLabel') : this.$t('games.favoritesLabel');
      },
      getTitleForReload() {
        return this.$t('games.reloadLabel');
      },
      getTitleForHome() {
        return this.$t('footer.footerHomeLabel');
      },
    },
    methods: {
      ...mapActions({
        fetchGameList: 'games/fetchGameList',
        doChangeFavorites: 'games/doChangeFavorites',
        fetchGameUrl: 'games/fetchGameUrl',
        fetchGameDesc: 'games/fetchGameDesc',
        fetchGameInfo: 'games/fetchGameInfo',
        fetchGameFavorite: 'games/fetchGameFavorite',
      }),
      ...mapMutations({
        SET_DEMO_RUN_GAME: 'games/SET_DEMO_RUN_GAME',
        SET_SIGNIN_VISIBLE: 'auth/SET_SIGNIN_VISIBLE',
        SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      }),
      getIconUrl(game) {
        let out = 'game-icons/no_cover.svg';
        if (game.image_file) {
          try {
            out = 'game-icons/' + game.image_file;
          } catch (e) {
            console.log('Cover not find: ', game.image_file);
          }
        } else if (game.aggregator && game.aggregator === 'softswiss') {
          return game.aggregator_params.game_icon.url + game.provider + '/' + (game.provider_id.split(':')[1]) + '.' + game.aggregator_params.game_icon.format;
        }
        return out;
      },
      /**
       * @param direction
       * метод для осуществления ротации влево и вправо игр из
       * блока Similar games
       */
      rotatorHandler(direction) {
        this.sliderPosition += (direction === 'left') ? this.cardWidth : -this.cardWidth;
        if (direction === 'left') {
          this.sliderIndex = Math.ceil(this.sliderPosition / this.cardWidth);
        } else {
          this.sliderIndex = Math.floor(Math.abs(this.sliderPosition / this.cardWidth));
          if (this.sliderIndex === (this.sliderList.length - 10) + 7) {
            this.sliderList.push(...this.list);
          }
        }

        this.changeSliderPosition(this.sliderPosition);
      },
      /**
       * метод для переключения режимов "демо-реальные деньги"
       */
      async changeDemoModeHandler() {
        this.isDemoModeClicked = true;
        if (this.isDemo && !this.isAuthUser) {
          this.SET_SIGNIN_VISIBLE(true);
        } else {
          this.isDemo = !this.isDemo;
          let isLoad = await this.loadGame(this.isDemo);
          if (!isLoad) {
            this.isDemo = !this.isDemo;
          }
        }
        setTimeout(() => {
          this.isDemoModeClicked = false;
        }, 500);
      },
      /**
       * метод открытия айфрем в новой вкладке
       */
      async openNewTabHandler() {
        this.isNewTabClicked = true;
        const url = await this.fetchGameUrl({gameId: this.gameId, demo: this.isDemo, homeButton: true, mobile: this.isMobile});
        if (url.length > 0) {
          window.open(url);
        }
        setTimeout(() => {
          this.isNewTabClicked = false;
        }, 500);
      },
      /**
       * метод открытия айфрем во весь экран
       */
      fullScreenHandler() {
        this.isFullScrClicked = true;
        document.querySelector('.frame-container').style = 'z-index:15;';
        document.querySelector('.frame').style = 'position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden;';
        setTimeout(() => {
          this.isFullScrClicked = false;
        }, 500);
      },
      /**
       * метод перезагрузки айфрем
       */
      reloadIFrameHandler() {
        this.isReloadClicked = true;
        try {
          const _frame = document.querySelector('.frame');

          if (_frame != null) {
            if ('src' in _frame) {
              const frameSrc = _frame.src;

              if (new RegExp('back_url').test(frameSrc)) {
                _frame.src = document.querySelector('.frame').src;
              } else {
                console.log('Frame error = ' + JSON.stringify(_frame));
              }
            }
          }

        } finally {
          setTimeout(() => {
            this.isReloadClicked = false;
          }, 500);
        }
      },
      /**
       * метод закрытия айфрем
       */
      closeIFrame() {
        this.isCloseClicked = true;
        this.$router.push('/').catch({});
        window.scrollTo(0, 0);
        this.isCloseClicked = false;
      },
      /**
       * метод добавления в закладки
       */
      async favoriteHandler() {
        this.isFavoritesClicked = true;
        try {
          await this.doChangeFavorites({id: this.gameId, value: !this.isFavorite});
          this.isFavorite = !this.isFavorite;
        } finally {
          setTimeout(() => {
            this.isFavoritesClicked = false;
          }, 500);
        }
      },
      /**
       * переключение позиции текущего слайда
       */
      changeSliderPosition(sliderPosition) {
        this.sliderPositionStr = 'transform: translateX(' + sliderPosition + 'px);transition: transform 1.0s ease';
      },
      /**
       * переключение позиции текущего слайда
       */
      changeSliderPositionAnimOff(sliderPosition) {
        this.changeSliderPosition(sliderPosition);

         setTimeout(() => {
           this.sliderPositionStr = 'transform: translateX(' + sliderPosition + 'px);';
         }, 1000);
      },
      async sliderGameHandler(gameId) {
        await this.$router.push(`/game?id=${gameId}`);
        window.scrollTo(0,0);
        this.loadGame(this.isDemo);
      },
      async loadGame(isDemo) {
        if (isDemo === false && !this.isAuthUser) {
          isDemo = true;
        }

        await this.fetchGameDesc(this.gameId);
        await this.fetchGameList(this.gameId);
        /**
         * модифицируем массив с играми для ротатора
         */
        this.sliderList.push(...this.list);

        const url = await this.fetchGameUrl({gameId: this.gameId, demo: isDemo, homeButton: false, mobile: this.isMobile});
        if (url.length > 0) {
          if (this.$refs['frameGames'] != null) {
            this.$refs['frameGames'].src = 'about:blank';
            this.$refs['frameGames'].src = url;
          }

          if (this.$metrika && isDemo === false) {
            this.$metrika.reachGoal('gameRun');
          }
          return true;
        }
        return false;
      },
      getTitle(card) {
        let s = 'title_' + this.languageCode;
        return (typeof card[s] === 'string' && card[s] !== '' ? card[s] : card['title_en']);
      },
      addToFavoritesText(bool) {
        return bool ? this.$t('games.favoritesRemoveLabel') : this.$t('games.favoritesLabel');
      },
      //Обработчик выбора обложки
      selectGame(gameId = 0, isDemo = false, index = -1) {
        if (!this.isMobile) {
          if (isDemo) {
            this.SET_DEMO_RUN_GAME(true);
          }
          //this.doOpenGamePage(gameId);
          this.sliderGameHandler(gameId);
        } else {
          if (this.gameSelectIndex !== index) {
            this.gameSelectIndex = index;
          } else {
            if (!isDemo && this.$metrika) {
              this.$metrika.reachGoal('gameRun');
            }
            this.doOpenGameUrl(gameId, isDemo);
          }
        }
      },
      async doOpenGamePage(gameId) {
        if (!this.isMobile) {
          this.SET_OPEN_PAGE('personalGame');
          await this.$router.push(`/game?id=${gameId}`);
          window.scrollTo(0, 0);
        }
      },
      async doOpenGameUrl(gameId, isDemo = false) {
        if (isDemo === false && !this.isAuthUser) {
          isDemo = true;
        }
        const url = await this.fetchGameUrl({gameId, demo: isDemo, homeButton: true, mobile: this.isMobile});
        if (url.length > 0) {
          window.location.href = url;
          if (this.$metrika && isDemo === false) {
            this.$metrika.reachGoal('gameRun');
          }
        }
      },
      async setFavorite(game_id, is_favorite) {
        await this.doChangeFavorites(
            {
              id: game_id,
              value: is_favorite
            }
        );
      },
      async on_over(event) {
        this.hover = true;
        this.icon_stop();
        this.svg = event.target.firstChild.contentDocument.documentElement;
        this.icon_play();
      },
      async on_out() {
        this.hover = false;
      },
      async on_svg_load(event) {
          this.svg = event.target.contentDocument.documentElement;
          this.icon_stop();
      },
      icon_play() {
        if (
            this.svg && this.svg.unpauseAnimations && this.svg.animationsPaused &&
            this.svg.animationsPaused() === true
        )
          this.svg.unpauseAnimations();
      },
      icon_stop() {
        if (
            this.svg && this.svg.pauseAnimations &&
            this.svg.animationsPaused() === false
        )
          this.svg.pauseAnimations();
          this.svg = null;
      },

      getTooltipText(divName) {
        switch (divName) {
          case 'money': {
            return this.getTitleForMoney;
          }
          case 'screen': {
            return this.tooltipTitle = this.getTitleForFScreen;
          }
          case 'favorites': {
            return this.tooltipTitle = this.getTitleForFavorite;
          }
          case 'reload': {
            return this.tooltipTitle = this.getTitleForReload;
          }
          case 'home': {
            return this.tooltipTitle = this.getTitleForHome;
          }
          default: {
            return null;
          }
        }
      },
    },
  watch:{
    isAuthUser(newAuth, oldAuth) {
      if (newAuth !== oldAuth && newAuth !== null) {
        let isDemo = !newAuth;
        if (this.isDemo !== isDemo) {
          this.isDemo = isDemo;
          this.loadGame(this.isDemo);
          this.SET_DEMO_RUN_GAME(this.isDemo);
        }
      }
    }
  },
  async beforeMount() {
    this.changeSliderPosition(this.sliderPosition);
  },
  async mounted() {
      if (this.isAuthUser !== null) {
        this.isDemo = !this.isAuthUser || this.isDemoRunGame;
        this.loadGame(this.isDemo);
        this.SET_DEMO_RUN_GAME(this.isDemo);
        this.isFavorite = await this.fetchGameFavorite(this.gameId);
      }
    }
};
</script>

<style lang="scss">
</style>