<template lang="pug">
  .settings
    .overlay
    .settings-data
      .settings-head
        .csoff-wrapper(@click="edCrossHandler")
          img(:src="require('@/assets/images/personal/sunduk-white/cross_starz57.svg')" alt="cross")
        span.settings-repl {{ $t('profile.settings.settingsLabel') }}

      .settings-body
        .top-section-wrapper
          LanguageSelector(v-if="brand.is_change_lang").language-selector(
            :dafaultSpan="$t('profile.settings.settingsLangLabel')" :isProfile="true")

          .timezone-container
            Dropdown(
              :itemArray="timezoneArray"
              :open="expandTimezone"
              @emitDDItem="listenDD($event, 'timezone')"
              dropdownType="datepicker"
              :hasSearch="true")
              template(v-slot:timezone)
                span.floating-label(ref="tzFlSpan" ) {{ defaultTZLabel }}
                .timezone-picker-content
                  .timezone-picker__text(:class="{'filled': selectedTZValue != $t('profile.settings.settingsTimezoneLabel')}") {{ selectedTZValue }}
                  .timezone-picker__chevron

          .currency-container(v-if="brand.code!='ex_w'")
            Dropdown(:itemArray="currencyArray" :open="expandTimezone"
              @emitDDItem="listenDD($event, 'currency')"
              @emitCurrencySearch="listenCurrencySearch"
              dropdownType="datepicker"
              :hasSearch="true")
              template(v-slot:currency)
                span.floating-label(ref="currencyFlSpan" ) {{ defaultCurrencyLabel }}
                .currency-picker-content
                  .currency-picker__text(:class="{'filled': selectedCurrency != $t('profile.settings.settingsCurrencyLabel')}") {{ selectedCurrency }}
                  .currency-picker__chevron

          .toggles
            .toggle-switcher(v-if="brand.code!='ex_w'" @click="checkRadioBtn('set_balance')")
              .toggle-wrapper(:class="{ 'disabled': false }")
                input.toggle(type="checkbox" id="set_balance" v-model="userData.balance_visible_in_top"
                  :class="{ 'disabled': false }")
                label.label(:class="{ 'disabled': false }")
              span {{ $t('profile.settings.settingsShowBalanceLabel') }}

            .toggle-switcher(v-if="brand.code!='ex_w'" @click="checkRadioBtn('set_stat')")
              .toggle-wrapper(:class="{ 'disabled': false }")
                input.toggle(type="checkbox" id="set_stat" v-model="userData.statistic_incognito"
                  :class="{ 'disabled': false }")
                label.label(:class="{ 'disabled': false }")
              span {{ $t('profile.settings.settingsStatisticShowLabel') }}

            .toggle-switcher(@click="checkRadioBtn('set_promo_notify')")
              .toggle-wrapper(:class="{ 'disabled': false }")
                input.toggle(type="checkbox" id="set_promo_notify" v-model="userData.promo_notify"
                  :class="{ 'disabled': false }")
                label.label(:class="{ 'disabled': false }")
              span {{ $t('auth.confirmPromoNotifyLabel') }}

        .sec-btn-wrapper
          .sec-btn(:class="{ 'disabled': false, 'clicked': isButtonClicked }"
            @click="saveSettingsHandler") {{ $t('profile.editProfile.editProfileButton') }}
</template>

<script>

const CrossShadowOff = () => import('@/components/serviceComponents/svg/CrossShadowOff');
const Dropdown = () => import('@/components/serviceComponents/dropdown/DropdownSW');
const LanguageSelector = () => import('@/components/serviceComponents/languageSelector/LanguageSelectorSW');
const Toggle = () => import('@/components/serviceComponents/ToggleSW');

import Settings from '../Settings.vue';

export default {
  extends: Settings,
  components: {
    CrossShadowOff,
    Dropdown,
    LanguageSelector,
    Toggle,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/personal/settings_sw.scss";
</style>
