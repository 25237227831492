import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'root',
      component: () => import('@/pages/sundukWhite/Main'),
    },
    {
      path: '/games',
      name: 'games',
      component: () => import('@/pages/sundukWhite/Games'),
    },
    {
      path: '/personal',
      name: 'personal',
      component: () => import('@/pages/sundukWhite/Personal.vue'),
    },
    {
      path: '/game',
      name: 'personal-game',
      component: () => import('@/pages/sundukWhite/PersonalGame.vue'),
      props: (route) => ({gameId: parseInt(route.query.id)}),
    },
    {
      path: '/terms-of-use',
      name: 'terms-of-use',
      component: () => import('@/pages/sundukWhite/TermsOfUse.vue')
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('@/pages/sundukWhite/PrivacyPolicy.vue')
    },
    {
      path: '/bonus-policy',
      name: 'bonus-policy',
      component: () => import('@/pages/sundukWhite/BonusPolicy.vue')
    },
    {
      path: '/payment-methods',
      name: 'payment-methods',
      component: () => import('@/pages/sundukWhite/PaymentMethods.vue')
    },
    {
      path: '/payment-policy',
      name: 'payment-policy',
      component: () => import('@/pages/sundukWhite/PaymentPolicy.vue')
    },
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@/pages/sundukWhite/ContactUs.vue')
    },
    {
      path: '/responsible-gambling',
      name: 'responsible-gambling',
      component: () => import('@/pages/sundukWhite/ResponsibleGamebling.vue')
    },
    {
      path: '/kyc-policy',
      name: 'kyc-policy',
      component: () => import('@/pages/sundukWhite/KycPolicy.vue')
    },
    {
      path: '/funds',
      name: 'funds',
      component: () => import('@/pages/sundukWhite/FundPage.vue')
    },
    {
      path: '/history',
      name: 'history',
      component: () => import('@/pages/sundukWhite/HistoryExchPage.vue')
    },
  ]
});

export default router;
