<template lang="pug">
  .search-wrapper(@click="toggleSearchField" :class="{ 'not-auth': !isAuthUser }")
    .search-wrapper__field
      .btn-search

      input#searchField.search__field(type="text" v-model="searchString" autocomplete="off"
        :placeholder="$t('headerNew.searchPlaceholder')" @input="keypressSearch")

      .fast-search-results-wrapper(v-if="fastSearchResults && fastSearchResults.count > 0")
        .head__close(@click="clearSearchStr")
          CrossShadowOff(:isClickedCross="isClickedCross")

        .fast-search-results
          .results(v-for="(result) in fastSearchResults.data")
            .result-img-wrapper
              img.result-image(:src="getIconUrl(result)")
            .result-content-wrapper
              .result__title {{ getTitle(result) }}
              .search-btn-play(@click="handleSearchBtn(result)") {{ getTitleForMoney }}
</template>

<script>

const CrossShadowOff = () => import('@/components/serviceComponents/svg/CrossShadowOff');

import Search from './Search.vue';

export default {
  extends: Search,
  components: {
    CrossShadowOff,
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/serviceComponents/search_sw.scss';
</style>