import { clientApi } from '@/utils/clientApi';

export const generateChatJWT = async ({rootGetters}) => {
    let response, authData;

    let currentLang = rootGetters['user/getLanguage'];

    try {
        response = await clientApi('post', 'integration/generate-jwt?subsystem=interchat');
        authData = response.data;
        authData['lang'] = currentLang;
        return authData;
    } catch (error) {
        console.log(error);
        throw error; // Важно передать ошибку дальше - чтобы было понятно в чем причина отказа
    }
    
    // eslint-disable-next-line no-unreachable
    response = null;
    authData = null;
};