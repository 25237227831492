<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import timezoneArr from '@/utils/timezoneHelper';

export default {
  name: 'Settings',
  data: () => ({
    isButtonClicked: false,
    userData: {},
    selectedTZValue: null,
    selectedCurrency: null,
    currencyArray: [],
    timezoneArray: [],
    expandTimezone: false,
    defaultCurrencyLabel: null,
    defaultTZLabel: null,
    settingFields: {}
  }),
  computed: {
    ...mapGetters({
      currentLanguage: 'user/getLanguage',
      openSection: 'personal/getOpenSection',
      brand: 'main/getBrand',
    }),
  },
  methods: {
    ...mapActions({
      saveSettings: 'personal/doSaveSettings',
      fetchUserProfile: 'user/fetchProfile',
      fetchSettings: 'personal/fetchSettings',
    }),
    ...mapMutations({
      SET_MODAL_MESSAGE: 'main/SET_MODAL_MESSAGE',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
    /**
     * метод-обработчик на кресте закрытия формы для @1024
     */
    edCrossHandler() {
      //eslint-disable-next-line
      this.$router.push('/').catch(e => {});
      window.scrollTo(0, 0);
      this.SET_OPEN_PAGE('root');
    },
    /**
     * метод-обработчик по кнопке Save changes
     */
    async saveSettingsHandler() {
      const dataForReq = {
        data: {
          balance_visible_in_top: this.userData.balance_visible_in_top,
          currencies: this.currencyArray,
          default_currency: this.selectedCurrency,
          language: this.currentLanguage.split('-')[0],
          languages: this.userData.languages,
          statistic_incognito: this.userData.statistic_incognito,
          timezone: this.selectedTZValue,
          promo_notify: this.userData.promo_notify,
        }
      };

      await this.saveSettings(dataForReq);
      this.fetchUserProfile();
    },
    /**
     * установка значений из данных, пришедших из стора и установка переключателей
     */
    setSettingsData() {
      this.userData.balance_visible_in_top = this.settingFields.balance_visible_in_top != null ?
          this.settingFields.balance_visible_in_top : false;

      if (document.querySelector('#set_balance')) {
        document.querySelector('#set_balance').checked = this.userData.balance_visible_in_top;
      }

      this.userData.promo_notify = this.settingFields.promo_notify != null ?
          this.settingFields.promo_notify : false;

      if (document.querySelector('#set_promo_notify')) {
        document.querySelector('#set_promo_notify').checked = this.userData.promo_notify;
      }

      this.userData.statistic_incognito = this.settingFields.statistic_incognito != null ?
          this.settingFields.statistic_incognito : false;

      if (document.querySelector('#set_stat')) {
        document.querySelector('#set_stat').checked = this.userData.statistic_incognito;
      }
      
      this.selectedCurrency = this.settingFields.default_currency != null ?
          this.settingFields.default_currency : null;
      this.defaultCurrencyLabel = this.$t('profile.settings.settingsCurrencyLabel');
      this.defaultTZLabel = this.$t('profile.settings.settingsTimezoneLabel');

      if (this.settingFields.hasOwnProperty('currencies')) {
        this.currencyArray = this.settingFields.currencies.length > 0 ?  this.settingFields.currencies : [];
      }

      if (this.settingFields.hasOwnProperty('languages')) {
        this.userData.languages = this.settingFields.languages.length > 0 ?  this.settingFields.languages : [];
      }

      this.timezoneArray = timezoneArr;
    },
    /**
     * Метод-обработчик для выбора даты.
     */
    listenDD(data, arg) {
      if (data) {
        switch (arg) {
          case 'timezone': {
            this.selectedTZValue = data;
            this.userData.timezone = data;
            return;
          }
          case 'currency': {
            this.selectedCurrency = data;
            return;
          }
        }
      }
    },
    /**
     * Метод-обработчик для выбора радиокнопки.
     */
    checkRadioBtn(arg) {
      if (arg === 'set_balance') {
        this.userData.balance_visible_in_top = !this.userData.balance_visible_in_top;

        if (document.querySelector('#set_balance')) {
          document.querySelector('#set_balance').checked = this.userData.balance_visible_in_top;
        }
      } else if (arg === 'set_stat') {
        this.userData.statistic_incognito = !this.userData.statistic_incognito;

        if (document.querySelector('#set_stat')) {
          document.querySelector('#set_stat').checked = this.userData.statistic_incognito;
        }
      } else if (arg === 'set_promo_notify') {
        this.userData.promo_notify = !this.userData.promo_notify;

        if (document.querySelector('#set_promo_notify')) {
          document.querySelector('#set_promo_notify').checked = this.userData.promo_notify;
        }
      }
    },
    /**
     * Метод для запроса данных по секции Settings + установка данных.
     */
    async fetchAndInit() {
      this.settingFields = await this.fetchSettings().catch(e => console.error(e));
      if(this.settingFields.timezone && this.settingFields.timezone !== ''){
        this.selectedTZValue = this.settingFields.timezone;
      }
      this.$nextTick(() => {
        this.setSettingsData();
      });
    },
    /**
     * метод-слушатель ввода списка курса валют
     * @param data
     */
    listenCurrencySearch(data) {
      if (data != null) {
        const filtered = this.currencyArray.filter(currency => currency.toLowerCase().includes(data.toLowerCase()));
        this.currencyArray = filtered;
      }
      if (data === '') {
        this.currencyArray = this.settingFields.currencies.length > 0 ?  this.settingFields.currencies : [];
      }
    },
  },
  watch: {
    isButtonClicked(newClicked) {
      if (newClicked) {
        setTimeout(() => {
          this.isButtonClicked = false;
        }, 500);
      }
    },
    selectedCurrency(newVal) {
      const node = this.$refs['currencyFlSpan'];

      if (newVal != null) {
        if (node != null) {
          node.classList.add('up');
        }
      }
      else {
        if (node != null) {
          node.classList.remove('up');
        }
      }
    },
    selectedTZValue(newVal) {
      const node = this.$refs['tzFlSpan'];

      if (newVal != null) {
        if (node != null) {
          node.classList.add('up');
        }
      }
      else {
        if (node != null) {
          node.classList.remove('up');
        }
      }
    },
    async openSection(newVal) {
      if (newVal === 'settings') {
        await this.fetchAndInit();
      }
    },
    currentLanguage(newLang, oldLang) {
      if (newLang !== oldLang) {
        this.defaultTZLabel = this.$t('profile.settings.settingsTimezoneLabel');
        this.defaultCurrencyLabel = this.$t('profile.settings.settingsCurrencyLabel');
      }
    },
  },
  async mounted() {
    await this.fetchAndInit();
  },
  beforeDestroy() {
    this.isButtonClicked = false;
  },
};
</script>

<style lang="scss" scoped>
</style>
