<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {displayCoins} from '@/utils/moneyUtils';

export default {
  name: 'Footer',
  data: () => ({
    btcEur: null,
    moneyCurency: convertCurrencyToSymbol,
    moneyFormat: displayCoins,
  }),
  computed: {
    ...mapGetters({
      brand: 'main/getBrand',
      contentPageType: 'main/getContentPageType',
      currencies: 'fund/getCurrencies',

    }),
    /**
     * массив ссылок-переводов для Навигации
     */
    getNavigationLinks() {
      return {
        sunduk_w: [
          {id: 'root', title: this.$t('footer.footerHomeLabel'), route: ''},
          {id: 'games', title: this.$t('footer.footerGamesLabel'), route: 'games'},
          // {id: 'promotions', title: this.$t('footer.footerPromotionsLabel'), route: ''},
          {id: 'contactUs', title: this.$t('footer.footerContactUsLabel'), route: 'contact-us'},
        ]
        , ex_w: [{id: 'root', title: this.$t('menu.exchange'), route: ''},
          {id: 'fee', title: this.$tc('menu.fee', 1), route: ''}
        ]
      }.[this.brand.code];
    },
    isSunduk(){
      return this.brand.code=='sunduk_w';
    },
    /**
     * массив ссылок-переводов для левой половины Agreements
     */
    getAgreementsLinksLeft() {
      return [
        {id: 'termsOfUse', disabled: false, title: this.$t('footer.footerTermsOfUseLabel'), route: 'terms-of-use'},
        {id: 'privacyPolicy', disabled: false, title: this.$t('footer.footerPrivacyLabel'), route: 'privacy-policy'},
        {id: 'paymentMethods', disabled: true, title: this.$t('footer.footerPaymentsLabel'), route: 'payment-methods'},
        {
          id: 'responsibleGaming',
          disabled: false,
          title: this.$t('footer.responsibleGamingLabel'),
          route: 'responsible-gambling'
        },
      ].filter(i => this.isSunduk || i.id !== 'responsibleGaming');
    },
    /**
     * массив ссылок-переводов для правой половины Agreements
     */
    getAgreementsLinksRight() {
      return [
        {
          id: 'paymentPolicy',
          disabled: false,
          title: this.$t('footer.footerPaymentPolicyLabel'),
          route: 'payment-policy'
        },
        {id: 'bonusPolicy', disabled: true, title: this.$t('footer.footerBonusPolicyLabel'), route: 'bonus-policy'},
        {id: 'kycPolicy', disabled: false, title: this.$t('footer.footerKycAmlLabel'), route: 'kyc-policy'},
      ].filter(i => this.isSunduk || i.id !== 'bonusPolicy');
    },
    /**
     * проверяем, что brand имеет свойство logo, и если имеет, возвращаем лого
     */
    getBrandLogo() {
      return this.brand.hasOwnProperty('logo') ? require(`@/assets/images/top-menu/sunduk-white/${this.brand.logo}`) : null;
    },
    getSupportEmail() {
      return this.brand.hasOwnProperty('support_email') ? this.brand.support_email : null;
    },
    getBTCCurrencyObj() {
      return Object.keys(this.currencies).length > 0 && this.currencies.hasOwnProperty('BTC') ? this.currencies['BTC'] : null;
    },
  },
  sockets: {
    setBtcEur(price) {
      this.btcEur = price;
    }
  },
  methods: {
    ...mapActions({}),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
    async callRoute(item) {
      if (window.location.pathname !== `/${item.route}`) {
        this.SET_OPEN_PAGE(item.id);
        this.$router.push(`/${item.route}`);
        window.scrollTo(0, 0);
      }
    },
    renderFooterCurrency() {
      // const result = this.moneyFormat(this.btcEur, this.getBTCCurrencyObj, 2);
      const result = this.btcEur;

      return result;
    },
  },
  mounted() {
    this.$socket.client.emit('getBtcEur');
    const logo = document.querySelector('.lc-logo');

    if (logo && !logo.getAttribute('src')) {
      logo.src = this.getBrandLogo;
    }
    // eslint-disable-next-line no-undef
    anj_eaa29d51_76e2_49dd_97be_7bd8747d2d15.init();
  },
  watch: {
    brand: {
      deep: true,
      async handler(newBrand) {
        if (newBrand.hasOwnProperty('logo')) {
          await this.$nextTick(() => {
            const logo = document.querySelector('.lc-logo');

            if (logo) {
              logo.src = this.getBrandLogo;
            }
          });
        }
      }
    }
  },
};
</script>

<style lang="scss">
</style>