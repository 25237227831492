<template lang="pug">
.page
  FundSW

</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';

const FundSW = () => import('@/components/fund/FundSW');

export default {
  name: 'FundPage',
  components: {
    FundSW,
  },
  computed: {
    ...mapGetters({
      profile: 'user/getProfile',
      pages: 'main/getPages'
    })
  },
  methods: {
    ...mapActions({
      fetchProfile: 'user/fetchProfile',
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_MODAL_MESSAGE: 'main/SET_MODAL_MESSAGE',
    }),
  },
  async beforeMount() {
    await this.fetchProfile();
    if (!this.profile.phone || this.profile.phone_status !== 'accept') {
      if (!this.pages.personal) {
        this.$router.push('/personal');
        this.SET_OPEN_PAGE('personal');
        window.scrollTo(0, 0);
      }

      this.SET_MODAL_MESSAGE({
        level: 'warning',
        icon: '!',
        buttons: 'ok',
        text: this.$t('auth.doVerifyPhoneMessage'),
        accept_action_func: 'close'
      });
      return;
    }
    this.SET_OPEN_PAGE('funds');
  },
};
</script>

<style lang="scss">
.page {
  height: 100%;
  width: 100%;
}
</style>