<template lang="pug">
  .checkbox-wrapper(:class="{ 'games': isGames }")
    label
      input.checkbox-wrapper__inp(type="checkbox" :id="name" v-model="value")
      div.self-checkbox(:class="{ 'games': isGames }")
    .text-wrapper
      label.text(v-show="!isTos" :for="name" :class="{ 'text-bl': textColor === 'black' }") {{ title }}
      label.text(v-show="isTos" :for="name" :class="{ 'text-bl': textColor === 'black' }") {{ title + ' ' }} <a class='link' href="terms-of-use">{{ links[0] }}</a> {{ links[1] }} <a class='link' href="privacy-policy">{{ links[2] }}</a>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    isTos: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
      default: () => [],
    },
    isGames: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      default: '',
    },
  },
  data: () =>({
    isClicked: false,
    value: false,
  }),
  watch: {
    value(newVal, oldVal) {
      if (newVal != null && newVal !== oldVal) {
        this.$emit('eventValue', { name: this.name, value: this.value });
      }
    },
  },
  beforeDestroy() {
    this.value = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/serviceComponents/checkbox_sw.scss';
</style>
