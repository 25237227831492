<template lang="pug">
  svg#topNew.icon(width='9' height='17' viewBox='0 0 9 17' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M5.96749 6.10352e-05L0 9.60006L3.22908 10.9L2.34786 16.8001L8.80449 7.40006L4.69649 5.89996L5.96749 6.10352e-05Z')
</template>

<script>
export default {
  name: 'TopNewSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#94b8af',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>