import axios from 'axios';

const API_URL = '/api';

export const clientApi = async (method, url, params = {}) => {
    try {
        return await axios[method](`${API_URL}/${url}`, params);
    } catch (error) {
        return error.response;
    }
};
