import {clientApi} from '@/utils/clientApi';

export const fetchAvailableMethods = async ({commit,state}) => {
    let response;
    let intervalId = null;

    try {
        response = await clientApi('get', 'payment/available-methods');
        let {data, error, message} = response.data;

        if (Array.isArray(data) && error === 0) {
            state.paymentMethods = data;
            return data;
        } else {
            commit('main/SET_MODAL_MESSAGE', null, {root: true});
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        }
    } catch (error) {
        clearInterval(intervalId);
        commit('main/SET_MODAL_MESSAGE', {
            level: 'error',
            icon: 'x',
            buttons: 'ok',
            text: error,
            accept_action_func: 'close',
        }, {root: true});
    }
};
