import {clientApiAuth} from '@/utils/clientApiAuth';
import Vue from 'vue';
// import i18n from '@/i18n';

export const doEmailActivateRequest = async ({commit, dispatch, rootGetters}, email) => {
    let response;

    try {
        response = await clientApiAuth('post', 'profile/email-activate-request', {'email': email});

        const {error, message} = response.data;

        if (error != 0) {
            if (error === 104) {
                if (response.data.hasOwnProperty('data') && response.data.data.hasOwnProperty('waitSec') &&
                    response.data.data.waitSec > 0) {
                    commit('auth/SET_FORGOT_PASSWORD_WAIT', response.data.data.waitSec, {root: true});
                } else {
                    commit('auth/SET_FORGOT_PASSWORD_WAIT', 60, {root: true});
                }
                let modalMessage = rootGetters['main/getModalMessage'];
                Vue.set(modalMessage, 'isShowCode', true);
                Vue.set(modalMessage, 'buttons', '');
                commit('main/SET_MODAL_MESSAGE', modalMessage, {root: true});
            } else {
                commit('main/SET_MODAL_MESSAGE', {
                    level: 'error',
                    icon: 'x',
                    buttons: 'ok',
                    text: message,
                    accept_action_func: 'close',
                }, {root: true});
            }
        } else {
            dispatch('user/fetchProfile', null, {root: true});

            let modalMessage = rootGetters['main/getModalMessage'];
            Vue.set(modalMessage, 'notifyText', message);
            Vue.set(modalMessage, 'isShowCode', true);
            Vue.set(modalMessage, 'buttons', '');
            commit('auth/SET_FORGOT_PASSWORD_WAIT', 60, {root: true});

            commit('main/SET_MODAL_MESSAGE', modalMessage, {root: true});
        }
    } catch (error) {
        console.log(error);
    }
};
