import {clientApi} from '@/utils/clientApi';

export const fetchSettings = async ({commit}) => {
    let response;

    try {
        response = await clientApi('get', 'profile/get-settings');
        const {data, error} = response.data;

        if (error !== 0) {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: error,
                accept_action_func: 'close',
            }, {root: true});
        } else {
            return data;
        }
    } catch (error) {
        console.log(error);
        commit('main/SET_MODAL_MESSAGE', {
            level: 'error',
            icon: 'x',
            buttons: 'ok',
            text: error,
            accept_action_func: 'close',
        }, {root: true});
    }
    return null;
};
