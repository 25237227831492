<template lang="pug">
    .ln( v-if="games.length>0") 
      .ln-wrap
        .ln-block
          .ln-title {{ $t('lastnews.latestNewsLabel') }}
          .ln-list(:class="{'ln-anim': is_anim}")
            .ln-card(v-for='(card,index) in games' :key='card.id' @click.stop="selectGame(card.game_id, false, index)" :class="{'ln-card_selected': (index === gameSelectIndex)}")
                .ln-col-first
                    .ln-btn-demo( @click.stop="selectGame(card.game_id, true, index)") {{ $t('lastnews.demoButton') }}
                    //.ln-btn-favorites( :class="{'ln-btn_selected': card.game_is_favorite}" @click.stop="setFavorite(card.game_id, !card.game_is_favorite)")
                    .ln-btn-new(v-show='card.game_is_new')
                    .ln-cover
                        object.icon(:data-game_id="'game_'+card.id" :ref="'game_'+card.id" :data="url_icon(card)")
                .ln-col-last
                    .ln-text
                      .ln-caption {{ getTitle(card) }}
                      .ln-description {{ getText(card) }}
                    .ln-win(:data-win="moneyFormat(card.win, card.currency_separator, card.currency_minor_unit)" :data-currency="moneyCurency(card.currency)")
                .ln-reflect
                      .ln-win(:data-win="moneyFormat(card.win, card.currency_separator, card.currency_minor_unit)" :data-currency="moneyCurency(card.currency)")
</template>

<script>
import LastNews from './LastNews.vue';

export default {
  extends: LastNews
};
</script>

<style lang="scss">
@import "@/assets/styles/lastnews/last-news_sw.scss";
</style>