<template lang="pug">
</template>

<script>
export default {
  name: 'IUG_icon',
  props: {
    isClickedIcon: {
      type: Boolean,
      default: false
    },
    checkedColor: {
      type: String,
      default: '#ffffff'
    },
    defaultColor: {
      type: String,
      default: '#667b87',
    },
  },
  computed: {
    changeFill() {
      return this.isClickedIcon ? this.checkedColor : this.defaultColor;
    }
  },
};
</script>