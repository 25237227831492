<template lang="pug">
.bid-cmp
  .top
    .name {{title+' '+activeTab.currency.code}}
  .main
    .line.input
      TextInput(name="bid_amount" :renderedTextLabel="$t('exchange.bid.amount')+', '+activeTab.currency.code"
                      :defaultValue="bids[type].amount"
                      :floatingLabel="false" :disabledProps="true"
                      @textInputEmit="listenTextInput" :validDiv="false"
                      :availableSymbols="['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']"
                    )
    .line.input
      TextInput(name="rate" :renderedTextLabel="$t('exchange.bid.rate')+', '+toolsCurrency.code"
                      :defaultValue="bids[type].rate"
                      :floatingLabel="false" :disabledProps="true"
                      @textInputEmit="listenTextInput" :validDiv="false"
                      :availableSymbols="['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']"
                      :readonly="activeTab2 && activeTab2.id === 'market'"
                    )
    //.line.input
    //  TextInput(name="deviation" :renderedTextLabel="$t('exchange.bid.deviation')+', %'"
    //                  :defaultValue="bids[type].deviation"
    //                  :floatingLabel="false" :disabledProps="true"
    //                  @textInputEmit="listenTextInput" :validDiv="false"
    //                  :availableSymbols="['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '-']"
    //                )
    .line.input.price
      TextInput(name="totalPrice" :renderedTextLabel="$t('exchange.bid.price')+', '+toolsCurrency.code"
                      :defaultValue="bids[type].totalPrice"
                      :floatingLabel="false" :disabledProps="true"
                      @textInputEmit="listenTextInput" :validDiv="false"
                      :availableSymbols="['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']"
                    )
    .fees-wrapper
      span {{ fee }}

    .button-wrapper
      button.exch-btn(@click="doOperation()" :class="{ 'buy': type === 'buy', 'sell': type === 'sell' }") {{ changeBtnTitle }}

</template>

<script>
import Bid from './Bid.vue';

const TextInput = () => import('@/components/serviceComponents/textInput/TextInputEXW');

export default {
  extends: Bid,
  components:{
    TextInput,
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/exchange/bid_sw.scss";
</style>