<template lang="pug">
  #app(:class="{ 'scroll-off': isSigninVisible || isSignupVisible || forgotToken}")
    .app-main
        Header
        LeftMenuMobile
        Banner1(v-if="false && isBanner1Visible")
        router-view

        .notifications-container(v-show="showNotifications")
          .notification(v-for="notification of getNotifyMessages")
            ToastSW(:notification="notification")

        Footer

    .spinner-wrapper(id="spinner")
      .spinner

    .snh(v-if="isSigninVisible || isSignupVisible || forgotToken")
      Signin

    .bet-modal(v-if="modalMessage != null")
      Modal
  </div>

</template>

<script>
const Header = () => import('@/components/topMenu/sundukWhite/HeaderSW');
const LeftMenuMobile = () => import('@/components/topMenu/sundukWhite/LeftMenuMobileSW');
const Banner1 = () => import('@/components/topMenu/sundukWhite/banner/Banner1');
const Footer = () => import('@/components/footer/FooterSW');
const Modal = () => import('@/components/serviceComponents/modal/ModalSW');
const Signin = () => import('@/components/auth/SigninSW');
const ToastSW = () => import('@/components/serviceComponents/ToastSW');

import App from './App.vue';

export default {
  extends: App,
  components: {
    LeftMenuMobile,
    Banner1,
    Footer,
    Header,
    Modal,
    Signin,
    ToastSW,
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/app_sw.scss";
</style>