<template lang="pug">
.foh-wrapper
    .fund-history-table
        .t-body
            .tr-col(v-for="item in payments")
                .id # {{ item.id }}
                .time {{ formatDate(item.created_ts)}}
                .summ-wrapper
                    .summ(:class="{ 'success': item.state === 'success', 'fail': item.state === 'fail', 'pending': item.state === 'processing' }") {{ getType(item.type) + displayCoins(item.amount,account.currency)}}
                    .img-wrapper(v-if="item.state === 'processing'")
                        img(:src="require('@/assets/images/fund/clock.svg')")
                .link(v-if="item.type=='withdrawal' && item.params.hasOwnProperty('address')") {{$t('funds.addressTitle') + ' '}}
                  a(v-if="item.currency_code!='XMR'" :href="getLink(item.params.address,item.currency_code, getNetwork(item),'address')" target="_blank") {{ renderLinkItem(item.params.address) }}
                  span(v-else) {{ renderLinkItem(item.params.address) }}
                .link(v-if="item.tx_hash")
                  a(:href="getLink(item.tx_hash,item.currency_code, getNetwork(item),'tx')" target="_blank") {{ renderLinkItem(item.tx_hash) }}
                // отображаем не таблицей, а как на https://app.freewallet.org/
                //на основании статуса item.state определяем цвет шрифта суммы. success зеленый, fail красный, processing оранжевый с часиками.
    .other-button(v-if="payments.length>0" @click="loadHistory()")
      a {{$t('funds.loadHistory')}}
</template>

<script>
import FundOperationsHistory from './FundOperationsHistory.vue';

export default {
    name: 'FundOperationsHistory',
    extends: FundOperationsHistory,
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/fund/fund_ophistory_sw.scss";
</style>