<template lang="pug">
  svg#iug_favorites.icon(width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M6.24642 2.55078L7.01328 4.99876H9.38505C9.38505 4.99876 9.70281 4.95834 9.77398 5.18328C9.83838 5.38889 9.62315 5.50838 9.62315 5.50838L7.64033 7.00125L8.38855 9.39211C8.38855 9.39211 8.51735 9.7014 8.34364 9.8332C8.15637 9.97379 7.9081 9.68031 7.9081 9.68031L5.9973 8.24017L4.08142 9.68383C4.08142 9.68383 3.73993 9.8622 3.64672 9.79717C3.56877 9.74182 3.59842 9.40968 3.59842 9.40968L4.35342 7.00212L2.29857 5.45215C2.29857 5.45215 2.10622 5.31156 2.16469 5.12792C2.22994 4.92143 2.53583 4.99876 2.53583 4.99876H4.98216L5.75156 2.54287C5.75156 2.54287 5.82528 2.09914 5.99815 2.09914C6.17101 2.09914 6.24642 2.55078 6.24642 2.55078ZM10.3002 4.99876H10.3053H10.3002ZM5.99815 0C5.26433 0 4.61271 0.490298 4.38647 1.21432L3.75095 3.24142H1.6944C0.960591 3.24142 0.308971 3.73171 0.0827257 4.45574C-0.143519 5.17976 0.104757 5.9732 0.699604 6.42044L2.36212 7.67167L1.7266 9.70052C1.49951 10.4245 1.74779 11.218 2.34264 11.6652C2.64006 11.8884 2.99002 12 3.33913 12C3.68824 12 4.0382 11.8884 4.33563 11.6652L5.99899 10.4122L7.66152 11.6652C7.95809 11.8884 8.3089 12 8.65801 12C9.00712 12 9.35624 11.8884 9.65451 11.6652C10.2485 11.2171 10.4968 10.4245 10.2705 9.70052L9.63502 7.67343L11.2636 6.4468C11.7068 6.13048 12 5.59977 12 4.99876C12 4.0287 11.2416 3.24142 10.307 3.24142H10.3019H8.2445L7.60898 1.21432C7.38273 0.490298 6.73196 0 5.99815 0Z')
</template>

<script>
import IUG_icon from './IUG_icon.vue';
export default {
  name: 'IUG_FavoritesSVG',
  components: { IUG_icon },
  extends: IUG_icon,
};
</script>