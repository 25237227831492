<template lang="pug">
  .footer
    .level-one
      .left-container
        img.lc-logo

        .center-container-mob
          .navigation-wrapper
            .navigation-title {{ $t('footer.footerNavigationLabel') }}

            .navigation-link(v-for="link in getNavigationLinks" :key="link.title" @click="callRoute(link)" ) {{ link.title }}

          .agreements-wrapper(v-if="isSunduk")
            .agreements-title {{ $t('footer.footerAgreementsLabel') }}

            .agreements-link-wrapper
              .left-block
                .agreements-link(v-for="link in getAgreementsLinksLeft" :key="link.title"
                                @click="callRoute(link)" :class="[{ 'disabled': link.disabled }, `${link.id}`]") {{ link.title }}

              .right-block
                .agreements-link(v-for="link in getAgreementsLinksRight" :key="link.title"
                                @click="callRoute(link)" :class="[{ 'disabled': link.disabled }, `${link.id}`]") {{ link.title }}

        .lc-text(v-if="isSunduk") {{ $t('footer.footerInfo1Label') }}
        .lc-text(v-if="isSunduk") {{ $t('footer.footerInfo21Label') }}
        .lc-text(v-if="isSunduk") {{ $t('footer.footerInfo22Label') }}
        .lc-text(v-if="isSunduk") {{ $t('footer.footerInfo23Label') }}
        .lc-text(v-if="isSunduk") {{ $t('footer.footerInfo24Label') }}

        .lc-text(v-if="isSunduk") {{ $t('footer.footerInfo3Label') }}
        .lc-text(v-if="isSunduk").last {{ $t('footer.footerInfo4Label') }}
           a(:href="'mailto:' + getSupportEmail") {{ ' ' + getSupportEmail}}

      .center-container
        .navigation-wrapper
          .navigation-title(v-if="isSunduk") {{ $t('footer.footerNavigationLabel') }}

          .navigation-link(v-for="link in getNavigationLinks" :key="link.title" @click="callRoute(link)" ) {{ link.title }}

        .agreements-wrapper
          .agreements-title(v-if="isSunduk") {{ $t('footer.footerAgreementsLabel') }}

          .agreements-link-wrapper
            .left-block
              .agreements-link(v-for="link in getAgreementsLinksLeft" :key="link.title"
                              @click="callRoute(link)"
                              :class="[{ 'disabled': link.disabled }, `${link.id}`]") {{ link.title }}

            .right-block
              .agreements-link(v-for="link in getAgreementsLinksRight" :key="link.title"
                              @click="callRoute(link)"
                              :class="[{ 'disabled': link.disabled }, `${link.id}`]") {{ link.title }}

    .level-logos(v-if="isSunduk")
      .protect-wrapper
        a(href="https://licensing.gaming-curacao.com/validator/?lh=f18c986d0d7cc58a315df5f67ad6f952" TARGET="_blank")
          img(src="https://licensing.gaming-curacao.com/validator/images/Gaming-Curacao-ClickToVerify.png"
            width="150" height="50" border="0" alt="Gaming Curacao: Click to Validate")
        div(id="anj-eaa29d51-76e2-49dd-97be-7bd8747d2d15" data-anj-seal-id="eaa29d51-76e2-49dd-97be7bd8747d2d15"
          data-anj-image-size="128" data-anj-image-type="basic-small")

      .restr
        .restr-text {{ $t('footer.responsibilityLabel') }}
        img.restr-img(:src="require('@/assets/images/footer/sunduk-white/eighteen.svg')" alt="restr image")

    .level-two(v-if="isSunduk && renderFooterCurrency() != null")
      img.btc(:src="require('@/assets/images/footer/sunduk-white/bitcoin.svg')")
      span {{ renderFooterCurrency() }}
      span {{ moneyCurency('EUR') }}

    .level-three
        .l-three
          .b-title © {{ new Date().getFullYear() }}. {{ brand.name + ' ' + $t('footer.allRightsReservedLabel') }}
        img.restr-img(:src="require('@/assets/images/footer/sunduk-white/eighteen.svg')" alt="restr image")
</template>

<script>
const LanguageSelector = () => import('@/components/serviceComponents/languageSelector/LanguageSelectorSW');

import Footer from './Footer.vue';


export default {
  extends: Footer,
  components: {
    LanguageSelector,
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/footer/footer_sw.scss';
</style>