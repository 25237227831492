import {clientApi} from '@/utils/clientApi';

export const fetchGameDesc = async ({state}, gameId) => {
    try {
        let response = await clientApi('get', `game/get-lines-desc?gameId=${gameId}`);

        if (typeof response.data === 'string') {
            state.gameDescription = response.data;
        } else {
            //@TODO if object
        }
    } catch (error) {
        console.log(error);
        throw error; // Важно передать ошибку дальше - чтобы было понятно в чем причина отказа
    }
};