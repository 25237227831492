<template lang="pug">
.fund-deposit-wrapper(@mousemove="setCursorPosition")
    .for-title {{ showDepositTitle }}

    .fop-dep-crypto-wrapper(v-if="showDepositCrypto && cryptoWallets")
        .address-wrapper(v-for="cryptoWallet of cryptoWallets")
            .address-prepend {{ cryptoWallet.network }}
            .deposit-address(@click="copyDepositAddress(cryptoWallet.address)") {{ cryptoWallet.address }}
              .copy
                img(:src="require('@/assets/images/fund/copy-icon.svg')")
        .tooltip(ref="copied-tooltip") {{ $t("common.copiedTooltip") }}


    .fop-dep-fiat-wrapper
      p.desc
        .dynamic-content-wrapper(v-html="description")

    .min-max-wrapper(v-if="paymentMethod")
      tr
        span {{ $t('profile.payments.minPaymentLabel') +': ' + min }}
      tr
        span {{ $t('profile.payments.maxPaymentLabel') +': ' + max }}
      tr
        span {{ $t('profile.payments.feePaymentLabel') +': ' + fee }}
</template>

<script>
import FundDeposit from './FundDeposit.vue';

export default {
    name: 'FundDepositSW',
    extends: FundDeposit,
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/fund/fund-deposit-sw.scss";
</style>