import Vue from 'vue';

export const RECALC_BID = (state, payload) => {
    let bid = payload;
    let toolsCurrency = state.exchangeToolsCurrency;
    let currency = state.activeTab.currency;

    if (!bid.rate) {
        return;
    }

    let correction = bid.deviation > 0 ? (1 + bid.deviation / 100) : (bid.deviation < 0 ? (1 - bid.deviation / 100) : 1);
    let rate = Math.round(bid.rate * correction * Math.pow(10, toolsCurrency.minor_unit)) / Math.pow(10, toolsCurrency.minor_unit);

    if (bid.rate == null || bid.rate <= 0) {
        Vue.set(state.exchangeBids, bid.type, bid);
        return;
    }

    if ((bid.amount == null || bid.amount <= 0) && (bid.totalPrice == null || bid.totalPrice <= 0)) {
        Vue.set(state.exchangeBids, bid.type, bid);
        return;
    }
    if (bid.amount == null) {
        bid.totalPrice = parseFloat(bid.totalPrice);
        if (bid.type == 'buy') {
            let totalAmount = bid.totalPrice / parseFloat(rate);
            bid.amount = Math.floor(totalAmount / (100 + bid.feePercent) * 100 * 100) / 100;
        } else {
            bid.amount = Math.floor(bid.totalPrice / (100 - bid.feePercent) * 100 / bid.rate * 100) / 100;
        }
    }

    bid.amount = parseFloat(bid.amount);

    if (bid.type == 'sell') {
        let price = Math.round(bid.amount * rate * Math.pow(10, toolsCurrency.minor_unit)) / Math.pow(10, toolsCurrency.minor_unit);
        bid.fee = price * bid.feePercent / 100.00;
        bid.totalPrice = Math.round((price - bid.fee) * Math.pow(10, toolsCurrency.minor_unit)) / Math.pow(10, toolsCurrency.minor_unit);
        bid.displayFee = displayFloat(bid.fee, toolsCurrency);
    } else {
        bid.fee = bid.amount * bid.feePercent / 100.00;

        bid.totalPrice = round((bid.amount + bid.fee) * rate, toolsCurrency.minor_unit);
        bid.displayFee = displayFloat(bid.fee, currency);
    }
    bid.amount = displayFloat(bid.amount, currency);
    bid.totalPrice = displayFloat(bid.totalPrice, toolsCurrency);

    Vue.set(state.exchangeBids, bid.type, bid);
};

function round(value, units) {
    let res = value * Math.pow(10, units);
    let rest = Math.round(res % 1 * 10);
    if (rest >= 5) {
        res = res + 1;
    }
    return Math.round(res - (res % 1)) / Math.pow(10, units);
}

function displayFloat(amount, currency) {
    return parseFloat(amount).toFixed(currency.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, '');
}