<template lang="pug">
  svg#ggl(width='47' height='47' viewBox='0 0 47 47' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFillFirst' d='M42 0H5C2.23858 0 0 2.23858 0 5V42C0 44.7614 2.23858 47 5 47H42C44.7614 47 47 44.7614 47 42V5C47 2.23858 44.7614 0 42 0Z')
    path(:fill='changeFillSecond' d='M10.3461 23.5111C10.1802 19.1982 13.9568 15.217 18.2753 15.1617C19.8845 15.0673 21.4733 15.4403 22.8579 16.2175C23.6929 16.6862 23.7566 17.8039 23.0942 18.4955C22.5575 19.0558 21.7074 19.1372 20.9991 18.8205C19.8053 18.2867 18.4968 18.0231 17.2191 18.4738C14.4705 19.4553 13.0384 22.4793 14.0209 25.228C15.0024 27.9766 18.0264 29.4088 20.7751 28.4267C21.5795 28.0921 22.2612 27.5425 22.756 26.8531C23.3953 25.9622 22.8223 25.2308 21.7258 25.2307C21.2102 25.2307 20.6389 25.2309 20.0949 25.2272C19.3145 25.2219 18.696 24.5839 18.696 23.8035C18.696 23.0035 19.3441 22.3555 20.144 22.3555C20.9527 22.3555 21.8402 22.3555 22.6937 22.3555C24.9014 22.3555 26.7977 24.179 26.2557 26.3192C26.0037 27.3146 25.6101 28.2842 24.999 29.0405C22.9255 31.7057 19.0991 32.4908 15.9806 31.4458C12.6071 30.2824 10.3444 27.1063 10.3461 23.5387')
    path(:fill='changeFillSecond' d='M31.8837 19.906H34.2834V22.2947H36.6721V24.6724H34.2834V27.0666C33.4927 27.0666 32.6965 27.0666 31.9058 27.0666C31.9058 26.2654 31.9058 25.4741 31.9058 24.6779H29.5171V22.3003H31.9058C31.9058 21.504 31.9058 20.7078 31.9058 19.9115')
</template>

<script>
export default {
  name: 'Google',
  props: {
    isClicked: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    changeFillFirst() {
      if (this.isClicked) {
        return '#ffffff';
      } else {
        return '#4A9eba';
      }
    },
    changeFillSecond() {
      if (this.isClicked) {
        return '#0c93dd';
      } else {
        return '#ffffff';
      }
    },
  },
};
</script>