import axios from 'axios';

const API_URL = '/api';

export const clientApiAuth = async (method, url, params = {}) => {
  let request = null;

  try {
    request = await axios[method](`${API_URL}/${url}`, params);
  } catch (error) {
    request = error.response.status;
    // console.clear();
  }

  return request;
};
