<template lang="pug">
  div.main
    GamesList(v-if="brand.code=='sunduk_w'" :showFilters="false" page="Main")
    LastNewsSW(v-if="brand.code=='sunduk_w'")
    LeaderboardBlock(v-if="brand.code=='sunduk_w'")
    ExchangeSW(v-if="brand.code=='ex_w'")
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

const GamesList = () => import('@/components/games/GamesListSW');
const LeaderboardBlock = () => import('@/components/leaderboard/LeaderboardBlockSW');
const LastNewsSW = () => import('@/components/lastnews/LastNewsSW');
const ExchangeSW = () => import('@/components/exchange/ExchangeSW');


export default {
  name: 'Main',
  components: {
    GamesList,
    LeaderboardBlock,
    LastNewsSW,
    ExchangeSW
  },
  computed: {
    ...mapGetters({
      brand: 'main/getBrand'
    })
  },
  methods: {
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    })
  },
  mounted() {
    this.SET_OPEN_PAGE('root');
  }
};
</script>
<style lang="scss">
  .main {
    width: 100%;
    margin-top: 75px;
  }
</style>