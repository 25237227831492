<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import convertCurrencyToSymbol from '@/utils/convertCurrencyToSymbol';
import {displayCoins} from '@/utils/moneyUtils';

export default {
  name: 'Deposit',
  data: () => ({
    currencyFormat: convertCurrencyToSymbol,
    moneyFormat: displayCoins,

    d_cards: [
      {
        id: 'visa',
        image: require('@/assets/images/personal/payments/visa.svg'),
      },
      {
        id: 'master',
        image: require('@/assets/images/personal/payments/master.svg'),
      },
      {
        id: 'maestro',
        image: require('@/assets/images/personal/payments/maestro.svg'),
      },
      {
        id: 'cirrus',
        image: require('@/assets/images/personal/payments/cirrus.svg'),
      },
      {
        id: 'ae',
        image: require('@/assets/images/personal/payments/american_express.svg'),
      },
      {
        id: 'mir',
        image: require('@/assets/images/personal/payments/mir.svg'),
      },
    ],
    d_crypto: [
      {
        id: 'btc',
        image: require('@/assets/images/personal/payments/btc.svg'),
      },
      {
        id: 'etc',
        image: require('@/assets/images/personal/payments/etc.svg'),
      },
      {
        id: 'eth',
        image: require('@/assets/images/personal/payments/eth.svg'),
      },
      {
        id: 'usdc',
        image: require('@/assets/images/personal/payments/usdc.svg'),
      },
      {
        id: 'bnb',
        image: require('@/assets/images/personal/payments/bnb.svg'),
      },
      {
        id: 'usdt',
        image: require('@/assets/images/personal/payments/usdt.svg'),
      },
    ],
    d_e_wallets: [
      {
        id: 'webmoney',
        image: require('@/assets/images/personal/payments/webmoney.svg'),
      },
      {
        id: 'yadengi',
        image: require('@/assets/images/personal/payments/yandex.svg'),
      },
    ],
    depositValue: null,
    fee: null,
    isDepositClicked: false,
    isCompanyActive: false,
    isCardsActive: false,
    isCryptoActive: false,
    hideDepositMethods: false,
    payLink: null
  }),
  computed: {
    ...mapGetters({
      currentLanguage: 'user/getLanguage',
      userBalance: 'user/getBalance',
      openSection: 'personal/getOpenSection',
      paymentMethods: 'pay/getPaymentMethods',
      storePayLink: 'pay/getPayUrl',
    }),

    cardDisabled() {
      return !this.paymentMethods || this.paymentMethods.filter(e => e.payment_system_code === 'card').length === 0;
    },

    cryptoDisabled() {
      return !this.paymentMethods || this.paymentMethods.filter(e => e.payment_system_code === 'crypto').length === 0;
    },

    getPaymentMethod() {
      if (this.paymentMethods) {
        let methods = this.paymentMethods.filter(e => e.payment_system_code ===
            (this.isCardsActive ? 'card' : this.isCryptoActive ? 'crypto' : ''));
        if (methods.length > 0) {
          return methods[0];
        }
      }
      return null;
    },

    amountPlaceholder() {
      return `${this.$t('profile.payments.amountLabel')} ` + this.getCurrency;
    },
    getCurrency() {
      return this.currencyFormat(this.userBalance.currency);
    },
    getMinAmount() {
      return this.moneyFormat(this.getPaymentMethod.deposit_min, this.getPaymentMethod, false);
    },
    getMaxAmount() {
      return this.moneyFormat(this.getPaymentMethod.deposit_max, this.getPaymentMethod, false);
    },
    getFee() {
      if (this.getPaymentMethod && this.depositValue > 0) {
        if (this.getPaymentMethod.deposit_fee_percent <= 0) {
          return 0;
        } else {
          let fee = (Math.trunc(this.depositValue * this.getPaymentMethod.deposit_fee_percent / 100
              * Math.pow(10, this.getPaymentMethod.minor_unit))
              / Math.pow(10, this.getPaymentMethod.minor_unit)).toFixed(this.getPaymentMethod.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, '');
          if (fee < parseFloat(this.moneyFormat(this.getPaymentMethod.deposit_fee_min, this.getPaymentMethod, false))) {
            fee = this.moneyFormat(this.getPaymentMethod.deposit_fee_min, this.getPaymentMethod, false);
          }
          return fee;
        }
      }
      return 0;
    },
    getAmountRules() {
      return `required|integer|between:${this.getMinAmount},${this.getMaxAmount}`;
      // v => v >= this.getMinAmount || this.$t('profile.payments.minAmountMessage') + ' ' + this.getMinAmount + this.getCurrency,
      // v => v <= this.getMaxAmount || this.$t('profile.payments.maxAmountMessage') + ' ' + this.getMaxAmount + this.getCurrency,
    },
    getWrongAmountMessage() {
      return (this.$t('profile.payments.wrongAmountMessage'))
          .replaceAll('{min}', this.getMinAmount + ' ' + this.getCurrency)
          .replaceAll('{max}', this.getMaxAmount + ' ' + this.getCurrency);
    }
  },
  methods: {
    ...mapActions({
      doPay: 'pay/doPay',
      doCheckActive: 'pay/doCheckActive',
      fetchAvailableMethods: 'pay/fetchAvailableMethods',
      doRedirectPay: 'pay/doRedirect'
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
    async callPaymentPolicy() {
      this.SET_OPEN_PAGE('paymentPolicy');
      this.$router.push('/payment-policy');
      window.scrollTo(0, 0);
    },
    async clickPayLink() {
      if (this.payLink) {
        await this.doRedirectPay({trxId:this.payLink.id});
      }
    },
    async handleDeposit() {
      this.isDepositClicked = true;
      let source = null;
      if (this.isCardsActive) {
        source = 'card';
      } else if (this.isCryptoActive) {
        source = 'crypto';
      }
      await this.doPay({type: 'deposit', source: source, pan: null, amount: this.depositValue, fee: this.getFee});
    },
    async handleMethod(payMethod) {
      this.depositValue = null;
      this.hideDepositMethods = true;

      if (payMethod === 'cards') {
        this.isCardsActive = true;
        await this.doCheckActive({type: 'deposit', source: 'card'});
      } else if (payMethod === 'crypto') {
        this.isCryptoActive = true;
      }
      window.scrollTo(0, 0);
    },
    listenTextInput(data) {
      if (data) {
        switch (data.name) {
          case 'summ': {
            this.depositValue = data.value.trim();
            return;
          }
        }
      }
    },
  },
  watch: {
    isDepositClicked(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.isDepositClicked = false;
        }, 300);
      }
    },
    async storePayLink(newLink) {
      if (newLink) {
        this.payLink = newLink;
      }
    },
    async openSection(newSection, oldSection) {
      if (newSection !== 'deposit' && oldSection === 'deposit') {
        this.payLink = null;
        this.depositValue = null;
        this.hideDepositMethods = false;
        this.isCardsActive = false;
        this.isCryptoActive = false;

        if (this.$refs.depositPayment != null) {
          this.$refs.depositPayment.reset();
        }

      } else if (newSection === 'deposit') {
        await this.fetchAvailableMethods();

        if (this.$metrika) {
          this.$metrika.reachGoal('depositOpen');
        }
      }
    },
  },
  async mounted() {
    await this.fetchAvailableMethods();
  }
};
</script>

<style lang="scss">
</style>