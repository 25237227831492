import {clientApiAuth} from '@/utils/clientApiAuth';

export const fetchContentPage = async ({rootGetters}, payload) => {
    let response;

    try {
        const currentLang = rootGetters['user/getLanguageCode'];
        response = await clientApiAuth('get', `content/get-html?type=${payload.toString()}&lang=${currentLang}`);

        if (response !== 401) {
            const arr = response.data;
            const data = arr[0].data;
            const error = arr[0].error;

            if (error == null) {
                return data;
            }
        }
    } catch (error) {
        console.log(error);
    }
};
