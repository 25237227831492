<template lang="pug">
</template>

<script>
import {mapGetters} from 'vuex';
import {displayCoins} from '@/utils/moneyUtils';

export default {
  name: 'FundDeposit',
  components: {},
  data: () => ({
    isDepositClicked: false,
    isCardsActive: false,
    displayCoins,
    cursor: {},
    tooltipTimeout: null
  }),
  computed: {
    ...mapGetters({
      activeTab: 'fund/getActiveTab',
      activeFundItem: 'fund/getActiveFundItem',
      fundAccounts: 'fund/getFundAccount',
      paymentMethods: 'fund/getPaymentMethods',
    }),
    showDepositTitle() {
      return this.$t('funds.deposit') + ' ' + this.currency.code;
    },
    showDepositCrypto() {
      return this.currency.is_crypto;
    },
    cryptoWallets() {
      return this.fundAccounts[this.activeFundItem].crypto_wallets != null ? this.fundAccounts[this.activeFundItem].crypto_wallets : '';
    },
    currency() {
      return this.fundAccounts[this.activeFundItem].currency;
    },
    paymentMethod() {
      if (!this.paymentMethods) {
        return null;
      }
      let p = Object.values(this.paymentMethods).filter(p => p.currency_code === this.currency.code);
      if (p && p.length > 0) {
        return p[0];
      }
      return null;
    },
    min() {
      if (this.paymentMethod.deposit_min == null || this.paymentMethod.deposit_min == 0) {
        return this.$t('profile.payments.noLimitPaymentLabel');
      }
      return this.displayCoins(this.paymentMethod.deposit_min, this.currency);
    },
    max() {
      if (this.paymentMethod.deposit_max == null) {
        return this.$t('profile.payments.noLimitPaymentLabel');
      }
      if (this.paymentMethod.deposit_max == 0) {
        return this.$t('profile.payments.notAvailablePaymentLabel');
      }
      return this.displayCoins(this.paymentMethod.deposit_max, this.currency);
    },
    fee() {
      if ((this.paymentMethod.deposit_fee_percent == null || this.paymentMethod.deposit_fee_percent == 0) &&
          (this.paymentMethod.deposit_fee_min == null || this.paymentMethod.deposit_fee_min == 0)) {
        return this.$t('profile.payments.noFeePaymentLabel');
      }
      if (this.paymentMethod.deposit_fee_percent > 0 && this.paymentMethod.deposit_fee_min > 0) {
        return this.paymentMethod.deposit_fee_percent + '%, ' + this.$t('profile.payments.minFeePaymentLabel')
            + this.displayCoins(this.paymentMethod.deposit_fee_min, this.currency);
      }
      if (this.paymentMethod.deposit_fee_percent > 0 && this.paymentMethod.deposit_fee_min == 0) {
        return this.paymentMethod.deposit_fee_percent + '%';
      }
      if (this.paymentMethod.deposit_fee_percent == 0 && this.paymentMethod.deposit_fee_min > 0) {
        return this.displayCoins(this.paymentMethod.deposit_fee_min, this.currency);
      }
      return '';
    },

    description() {
      return this.currency.is_crypto ? this.$t('profile.payments.cryptoDepositLabel') : this.$t('profile.payments.cashDepositLabel');
    },
  },
  methods: {
    async copyDepositAddress(address) {
      await navigator.clipboard.writeText(address);

      this.$refs['copied-tooltip'].style.display = 'block';
      this.$refs['copied-tooltip'].style.top = (this.cursor.y + 10) + 'px';
      this.$refs['copied-tooltip'].style.left = (this.cursor.x - 60) + 'px';
      if (this.tooltipTimeout) {
        clearTimeout(this.tooltipTimeout);
      }
      this.tooltipTimeout = setTimeout(() => {
        this.$refs['copied-tooltip'].style.display = 'none';
      }, 1500);

    },
    setCursorPosition(event) {
      this.cursor = {x: event.clientX, y: event.clientY};
    }
  },
};
</script>

<style lang="scss" scoped>
</style>