<template lang="pug">
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FundOperations',
  components: {
  },
  data: () => ({
    isDepositClicked: false,
    isCardsActive: false,
  }),
  computed: {
    ...mapGetters({
      activeTab: 'fund/getActiveTab',
      activeFundItem: 'fund/getActiveFundItem',
      fundAccounts: 'fund/getFundAccount',
    }),
    switchOperationsTitle() {
      let currencyCode = this.fundAccounts[this.activeFundItem].currency_code;
      return this.activeTab === 'deposit' ? (this.$t('funds.deposit') + ' ' + `${currencyCode}`)
          : (this.$t('funds.withdrawal') + ' ' + `${currencyCode}`);
    },
    showDepositFiat() {
      return !this.fundAccounts[this.activeFundItem].is_crypto && this.activeTab === 'deposit';
    },
    showDepositCrypto() {
      return this.fundAccounts[this.activeFundItem].is_crypto && this.activeTab === 'deposit';
    },
    showWithdrawalCrypto() {
      return this.fundAccounts[this.activeFundItem].is_crypto && this.activeTab === 'withdrawal';
    },
    showWithdrawalFiat() {
      return !this.fundAccounts[this.activeFundItem].is_crypto && this.activeTab === 'withdrawal';
    },
    showSomeText() {
      return 'Lorem ipsum dolor sit amet. Et voluptatibus similique non ipsam corrupti aut quia sunt et quia repudiandae. In velit quasi et optio eius ut libero atque et vero tempore rem veritatis laudantium.';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>