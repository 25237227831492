import i18n from '@/i18n';

const GAME_PLACE_LANG = 'game_place_lang';

export const getLanguage = (state) => {
    if (!state.language) {
        let languageFromStore = localStorage.getItem(GAME_PLACE_LANG);
        if (!languageFromStore) {
            let browserLanguage = getBrowserLanguage();
            if (browserLanguage) {
                state.language = browserLanguage;
            } else {
                state.language = state.defaultLanguage;
            }
        } else {
            state.language = JSON.parse(languageFromStore);
        }
    }
    return state.language;
};

function getBrowserLanguage() {
    let browserLanguage = navigator.language || navigator.userLanguage;

    if (/-/.test(browserLanguage)) {
        const foundLanguage = findLanguage(browserLanguage.split('-')[0]);
        if (foundLanguage) {
            return browserLanguage;
        } else {
            return null;
        }
    } else {
        const foundLanguage = findLanguage(browserLanguage);
        if (foundLanguage) {
            return transformLang(browserLanguage);
        } else {
            return null;
        }
    }
}

function transformLang(language) {
    const prettieredLang = language.replace(/\"/g, '');
    return `${prettieredLang}-${prettieredLang.toUpperCase()}`;
}

/**
 * выбор из массива доступных локалей соответствующей,
 *  или возврат undefined
 */
function findLanguage(lg) {
    return i18n.availableLocales.find(lang => lang === lg);
}
