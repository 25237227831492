<template lang="pug">
  Personal
</template>

<script>
import Personal from '@/components/personal/sundukWhite/PersonalSW';
export default {
  components: {
    Personal
  }
};
</script>

<style></style>
