import {clientApiAuth} from '@/utils/clientApiAuth';
import Vue from 'vue';

export const doEmailActivateConfirm = async ({commit, rootGetters, dispatch}, payload) => {
    let response;
    const {email, code} = payload;

    try {
        response = await clientApiAuth('post', 'profile/email-activate-confirm', {email: email, code: code});

        const {error, message} = response.data;

        if (error != 0 && error != 950) {
            commit('main/SET_SHOW_CODE_BOOL', false, {root: true});
            commit('main/SET_CONFIRM_MESSAGE', {}, {root: true});
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
        } else if (error == 950) {
            let modalMessage = rootGetters['main/getModalMessage'];
            Vue.set(modalMessage, 'confirmError', message);
            commit('main/SET_MODAL_MESSAGE', modalMessage, {root: true});
        } else {
            dispatch('user/fetchProfile', null, {root: true});

            commit('main/SET_MODAL_MESSAGE', {
                level: 'info',
                icon: 'i',
                buttons: 'ok',
                text: message,
                accept_action_func: 'closeVerifyEmail',
            }, {root: true});
        }
    } catch (error) {
        console.log(error);
    }
};
