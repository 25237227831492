import * as actions from './actions/';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
    unreadMessageCount: 0,
};

export const chat = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};