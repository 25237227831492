import cookie from 'vue-cookies';
import {clientApi} from '@/utils/clientApi';

const accessToken = cookie.get('accessToken');
const headers = {
    headers: {
        Authorization: `${accessToken}`
    }
};

export const doLogout = async ({commit, rootGetters, dispatch}) => {

    let currentLang = rootGetters['user/getLanguage'];
    try {
        await clientApi('get', `user/logout?lang=${currentLang}`, headers);

        commit('personal/SET_PAYMENTS', [], {root: true});
        commit('personal/SET_PAYMENTS_MOBILE', [], {root: true});
        cookie.remove('PHPSESSID');
        cookie.remove('_csrf');
        cookie.remove('accessToken');
        dispatch('user/fetchBalance', null, {root: true});
        dispatch('user/fetchProfile', null, {root: true});
    } catch (error) {
        console.log('error = ', error);
    }
};
