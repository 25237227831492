<template lang="pug">
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import i18n from '@/i18n';

export default {
  name: 'Security',
  data: () => ({
    isButtonClicked: false,
    userData: {},
    isChpassClicked: false,
    securityFields: {},
    chgPassInputFields: [{
      placeholder: i18n.t('profile.changePassword.currentPasswordLabel'),
      name: 'old-password',
      it_pass: true,
      regexp: new RegExp(/[0-9]{7,}/),
      equal_name: '',
      typeString: 'password',
      errorText: '',
      value: null,
      isOpenIcon: false,
    }, {
      placeholder: i18n.t('profile.changePassword.newPasswordLabel'),
      name: 'new-password',
      it_pass: true,
      regexp: new RegExp(/[0-9]{7,}/),
      equal_name: 'repeat-new-password',
      typeString: 'password',
      errorText: '',
      value: null,
      isOpenIcon: false,
    }, {
      placeholder: i18n.t('profile.changePassword.repeatPasswordLabel'),
      name: 'repeat-new-password',
      it_pass: true,
      regexp: new RegExp(/[0-9]{7,}/),
      equal_name: 'new-password',
      typeString: 'password',
      errorText: '',
      value: null,
      isOpenIcon: false,
    }]
  }),
  computed: {
    ...mapGetters({
      currentLanguage: 'user/getLanguage',
    }),
  },
  methods: {
    ...mapActions({
      saveSecurity: 'personal/doSaveSecurity',
      fetchUserProfile: 'user/fetchProfile',
      fetchSecurity: 'personal/fetchSecurity',
    }),
    ...mapMutations({
      SET_MODAL_MESSAGE: 'main/SET_MODAL_MESSAGE',
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
    /**
     * Метод-обработчик для выбора радиокнопки.
     */
    checkRadioBtn(arg) {
      if (arg === 'logins') {
        this.userData.login_notify = !this.userData.login_notify;

        if (document.querySelector('#logins')) {
          document.querySelector('#logins').checked = this.userData.login_notify;
        }
      } else if (arg == 'ips') {
        this.userData.ip_notify = !this.userData.ip_notify;

        if (document.querySelector('#ips')) {
          document.querySelector('#ips').checked = this.userData.ip_notify;
        }
      }
      else {
        this.userData.auth_2f = !this.userData.auth_2f;

        if (document.querySelector('#dfa')) {
          document.querySelector('#dfa').checked = this.userData.auth_2f;
        }
      }
    },
    /**
     * метод-обработчик на кресте закрытия формы для @1024
     */
    edCrossHandler() {
      //eslint-disable-next-line
      this.$router.push('/').catch(e => {});
      window.scrollTo(0, 0);
      this.SET_OPEN_PAGE('root');
    },
    /**
     * метод-обработчик для показа форма Смена пароля
     */
    switchChgPass() {
      this.isChpassClicked = true;

      setTimeout(() => {
        this.SET_MODAL_MESSAGE({
          input_fields_title: this.$t('profile.changePassword.changePasswordLabel'),
          buttons: 'ok',
          title: this.$t('profile.changePassword.changePasswordLabel'),
          input_fields: this.chgPassInputFields,
          accept_button_text: this.$t('profile.changePassword.saveNewPasswordLabel'),
          accept_action_func: 'chgPass',
        });
        this.isChpassClicked = false;
      }, 300);
    },
    /**
     * метод-обработчик по кнопке Save changes
     */
    async saveSecurityHandler() {
      const dataForReq = {
        data: {
          login_notify: this.userData.login_notify,
          ip_notify: this.userData.ip_notify,
          auth_2f: this.userData.auth_2f,
        }
      };

      await this.saveSecurity(dataForReq);
      this.fetchUserProfile();
    },
    /**
     * установка значений из данных, пришедших из стора и установка переключателей
     */
    setSecurityData() {
      this.userData.login_notify = this.securityFields.login_notify;

      if (document.querySelector('#logins')) {
        document.querySelector('#logins').checked = this.userData.login_notify;
      }

      this.userData.ip_notify = this.securityFields.ip_notify;

      if (document.querySelector('#ips')) {
        document.querySelector('#ips').checked = this.userData.ip_notify;
      }

      this.userData.auth_2f = this.securityFields.auth_2f;

      if (document.querySelector('#dfa')) {
        document.querySelector('#dfa').checked = this.userData.auth_2f;
      }
    },
  },
  watch: {
    isButtonClicked(newClicked) {
      if (newClicked) {
        setTimeout(() => {
          this.isButtonClicked = false;
        }, 500);
      }
    },
  },
  async mounted() {
    this.securityFields = await this.fetchSecurity();
    this.$nextTick(() => {
      this.setSecurityData();
    });
  },
  beforeDestroy() {
    this.isButtonClicked = false;
  },
};
</script>

<style lang="scss" scoped>
</style>
