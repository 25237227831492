import {clientApi} from '@/utils/clientApi';

export const fetchTopMonthGames = async ({commit}, payload) => {

    //currency: RUB,
    const params = Object.assign(
        {
            limit: 10
        },
        payload
    );
    try {
        let response = await clientApi(
            'get',
            'top-statistic/month-game',
            {'params': params}
        );
        if (response) {
            const data = response.data;
            if (typeof data === 'object' && data !== null)
                commit('SET_TOP_MONTH_GAMES', data);
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};