<template lang="pug">
</template>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'HeaderLinks',
  data: () => ({
    sunduk_w: {
      items: [
        {id: 'root', title: 'home', route: ''},
        {id: 'games', title: 'games', route: 'games'},
        {id: 'nards', title: 'nards', route: 'nards'},
        // {id: 'prootions', title: 'promotions', route: ''},
      ],
      itemsAuth: [
        {id: 'root', title: 'home', route: ''},
        {id: 'games', title: 'games', route: 'games'},
        {id: 'nards', title: 'nards', route: 'nards'},
        // {id: 'promotions', title: 'promotions', route: ''},
      ]
    },
    ex_w: {
      items: [
        {id: 'root', title: 'exchange', route: ''},
        {id: 'fee', title: 'fee', route: ''}
      ],
      itemsAuth: [
        {id: 'root', title: 'exchange', route: ''},
        {id: 'funds', title: 'funds', route: 'funds'},
        {id: 'history', title: 'history', route: 'history'},
        {id: 'fee', title: 'fee', route: ''},
      ]
    },
  }),
  computed: {
    ...mapGetters({
      pages: 'main/getPages',
      userLanguageCode: 'user/getLanguageCode',
      authUser: 'auth/isAuthUser',
      brand: 'main/getBrand',
    }),
    switchItemsArr() {
      let code = this.brand.code;
      if (!code) {
        return [];
      }
      return Boolean(this.authUser) === true ? this[code].itemsAuth : this[code].items;
    },
  },
  methods: {
    ...mapActions({
      fetchGameUrl: 'games/fetchGameUrl',
    }),
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_LIST: 'games/SET_LIST',
    }),
    classMerger(item) {
      return {
        'active': this.pages[item.id],
      };
    },
    async callRoute(item) {
      if (window.location.pathname !== `/${item.route}`) {
        if (item.id !== 'nards') {
          this.SET_OPEN_PAGE(item.id);
          this.$router.push(`/${item.route}`);
          window.scrollTo(0, 0);
        } else {
          const gameId = 336;
          const url = await this.fetchGameUrl({gameId, homeButton: true, mobile: this.isMobile});
          if (url.length > 0) {
            window.location.href = url;
            if (this.$metrika) {
              this.$metrika.reachGoal('gameRun');
            }
          }
        }
      }

      if (item.id === 'games') {
        this.SET_LIST([]);
      }
    },

    renderLink(item) {
      if (item.title !== 'fee') {
        return this.$t('menu.'+item.title);
      }
      else {
        return this.$tc('menu.'+item.title, 0);
      }
    },
  },
};
</script>

<style lang="scss">
</style>
