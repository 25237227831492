import {clientApi} from '@/utils/clientApi';

export const doSignup = async ({commit, rootGetters}, payload) => {
    let result;
    let currentLang = rootGetters['user/getLanguage'];

    try {
        result = await clientApi('post', `user/signup?lang=${currentLang}`, {
            data: payload
        });
    } catch (error) {
        console.log('error = ', error);
    }

    if (result) {
        let {error, message} = result.data;

        if (error === 0) {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'info',
                icon: 'i',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});
            commit('auth/SET_SIGNUP_VISIBLE', false, {root: true});

            return true;
        } else {
            commit('main/SET_MODAL_MESSAGE', {
                level: 'error',
                icon: 'x',
                buttons: 'ok',
                text: message,
                accept_action_func: 'close',
            }, {root: true});

            return false;
        }
    }
};
