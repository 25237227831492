<script>
import TextInputValidationOff from './TextInputValidationOff';


export default {
  extends: TextInputValidationOff,
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/serviceComponents/text-input-val-off_sw.scss";
</style>