<template lang="pug">
  .bonus-policy-page
    DynamicContentSW
</template>

<script>
import { mapMutations } from 'vuex';

const DynamicContentSW = () => import('@/components/serviceComponents/DynamicContentSW');

export default {
  components: {
    DynamicContentSW
  },
  methods: {
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_CONTENT_PAGE_TYPE: 'main/SET_CONTENT_PAGE_TYPE',
    }),
  },
  beforeMount() {
    this.SET_CONTENT_PAGE_TYPE('payment-methods');
    this.SET_OPEN_PAGE('paymentMethods');
  },
};
</script>

<style lang="scss">
.bonus-policy-page {
  height: 100%;
  width: 100%;
}
</style>