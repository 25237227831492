/* eslint-disable no-irregular-whitespace */
export const translations = {
  data: () => ({
    locales: [
      {
        en: {
          timeFormatters: {
            today: 'Today at',
            today_res: 'Today',
            tomorrow: 'Tomorrow at',
            yesterday: 'Yesterday at',
            in: 'at',
            matchStarted: 'The match has already begun. Go to Live'
          },
        }
      },
      {
        ru: {
          timeFormatters: {
            today: 'Сегодня в',
            today_res: 'Сегодня',
            tomorrow: 'Завтра в',
            yesterday: 'Вчера в',
            in: 'в',
            matchStarted: 'Матч уже начался. Перейдите в Лайв'
          },
        }
      },
      {
        fr: {
          timeFormatters: {
            today: 'Aujourd\'hui à',
            today_res: 'Aujourd\'hui',
            tomorrow: 'Demain à',
            yesterday: 'Hier à',
            in: 'à',
            matchStarted: 'Le match a déjà commencé. Aller EN DIRECT'
          },
        }
      }
    ]
  })
};
