<template lang="pug">
  .personal-game-page
    PersonalGame(:gameId="gameId")
</template>

<script>
import { mapMutations } from 'vuex';

const PersonalGame = () => import('@/components/games/personal/PersonalGameSW');

export default {
  props: {
    gameId: {
      type: Number,
      required: true,
    },
  },
  components: {
    PersonalGame
  },
  methods: {
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
    }),
  },
  beforeMount() {
    this.SET_OPEN_PAGE('personalGame');
  },
};
</script>

<style lang="scss">
.personal-game-page {
  height: 100%;
  width: 100%;
}
</style>