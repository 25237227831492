import {clientApi} from '@/utils/clientApi';
import router from '@/routerSW';

export const fetchGameUrl = async ({commit}, payload) => {
    try {
        const {demo, gameId, homeButton, mobile} = payload;
        const backUrl = window.location.href;
        const location = window.location.origin;
        let response = await clientApi(
            'get',
            `game/game-url?gameId=${gameId}&demo=${demo ? '1' : '0'}&homeButton=${homeButton}&backUrl=${backUrl}&mobile=${mobile}&location=${location}`
        );
        if (response) {
            const {url, error, message} = response.data;

            if (typeof error !== 'undefined' && error != null) {
                if (error === 401) {
                    commit('auth/SET_SIGNIN_VISIBLE', true, {root: true});
                } else {
                    let msg = {
                        level: 'error',
                        icon: 'x',
                        buttons: 'ok',
                        text: message
                    };
                    if (error === 997) {
                        msg.accept_action_func = () => {
                            //eslint-disable-next-line
                            router.push('/personal').catch(e => {});
                            window.scrollTo(0, 0);
                            commit('main/SET_OPEN_PAGE', 'personal', {root: true});
                            commit('main/SET_MODAL_MESSAGE', null, {root: true});
                        };
                    } else {
                        msg.accept_action_func = 'close';
                    }
                    commit('main/SET_MODAL_MESSAGE', msg, {root: true});
                }
            } else if (typeof url === 'string') {
                return url;
            }
        }
    } catch (error) {
        console.log(error);
    }
    return false;
};