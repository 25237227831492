<template lang="pug">
  svg#topJackpots.icon(width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(:fill='changeFill' d='M13.6113 14.0001H0V3.31487L3.88895 6.65438L6.84794 6.10352e-05L9.72238 6.7408L13.6959 3.40129V14.0001H13.6113ZM1.52176 12.3581H12.0896V6.7408L9.38421 8.93833L6.76339 3.80253L4.05804 8.85191L1.52176 6.7408V12.3581Z')
</template>

<script>
export default {
  name: 'TopJackpotsSVG',
  props: {
    isClickedCross: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#94b8af',
    },
  },
  computed: {
    changeFill() {
      if (this.isClickedCross) {
        return '#ffffff';
      } else {
        return this.color;
      }
    }
  },
};
</script>