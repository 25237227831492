const displayCoins = (value, currency, withCurrencyCode = true, toFixed = true) => {
    if (value != null && currency != null && typeof currency === 'object') {
        let number = value / Math.pow(10, currency.is_crypto ? currency.crypto_coins_unit : currency.minor_unit);
        if (toFixed) {
            number = number.toFixed(currency.minor_unit).replace(/(\.0*|(?<=(\..*))0*)$/, '');
        }
        return number + (withCurrencyCode ? (' ' + currency.code) : '');
    } else {
        return null;
    }
};

const displayCoinsToInt = (value, currency) => {
    return parseInt(parseFloat(value) * Math.pow(10, currency.is_crypto ? currency.crypto_coins_unit : currency.minor_unit));
};

const roundFloat = (value, units) => {
    let res = value * Math.pow(10, parseInt(units));
    let rest = Math.round(res % 1 * 10);
    if (rest >= 5) {
        res = res + 1;
    }
    return Math.round(res - (res % 1)) / Math.pow(10, units);
};

const currencyFormat = (value, separator = '.', minor_unit = 2) => {
    let out = value;
    const value_pure = parseFloat(value);
    if (isNaN(value_pure) === false) {
        let value_current = value_pure * Math.pow(10, -minor_unit);
        value_current = value_current.toFixed(minor_unit);
        const value_current_parts = (value_current).toString().split('.');
        const value_int = value_current_parts[0] | 0;
        let value_real = (typeof value_current_parts[1] === 'string' ? value_current_parts[1].substr(0, minor_unit) : 0);
        for (let i = value_real.length - 1; i >= 0; i--) {
            if (value_real[i] === '0') {
                value_real = value_real.substr(0, i);
            } else {
                break;
            }
        }

        const value_formated = [value_int.toLocaleString()];
        if ((value_real | 0) > 0) {
            value_formated.push(value_real);
        }

        out = value_formated.join(separator);
    }
    return out;
};

export {displayCoins, roundFloat, displayCoinsToInt, currencyFormat};