<template lang="pug">
  .payments-wrapper
    .payments
      .pmt-repl {{ $t('profile.paymentHistory.paymentsHistoryLabel') }}
      .csoff-wrapper(@click="edCrossHandler")
        img(:src="require('@/assets/images/personal/sunduk-white/cross_starz57.svg')" alt="cross")

      .bnts-wrapper
        .dep-container
          Button(:title="$t('profile.payments.depositTab')" buttonShape="gradientOffPay"
              :isActive="isDepositActive" buttonSize="small"
              funcToBeCalled="fetchDeposit" @emitButton="handleButton")
        .with-container
          Button(:title="$t('profile.payments.withdrawalTab')" buttonShape="gradientOffPay"
              :isActive="!isDepositActive" buttonSize="small"
              funcToBeCalled="fetchWithdrawal" @emitButton="handleButton")

      PaymentsHistory
</template>

<script>
const Button = () => import('@/components/serviceComponents/ButtonSW');
const PaymentsHistory = () => import('@/components/personal/sundukWhite/PaymentsHistorySW');

import Payments from '../Payments.vue';

export default {
  extends: Payments,
  components: {
    Button,
    PaymentsHistory
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/personal/payments_sw.scss";
</style>