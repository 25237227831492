import { render, staticRenderFns } from "./FundOperationsHistorySW.vue?vue&type=template&id=22e39b48&scoped=true&lang=pug&"
import script from "./FundOperationsHistorySW.vue?vue&type=script&lang=js&"
export * from "./FundOperationsHistorySW.vue?vue&type=script&lang=js&"
import style0 from "./FundOperationsHistorySW.vue?vue&type=style&index=0&id=22e39b48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22e39b48",
  null
  
)

export default component.exports